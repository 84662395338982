export const REGISTER_INIT_ACTION = 'REGISTER_INIT'
export const FETCH_REGISTER_FAIL_ACTION = `${REGISTER_INIT_ACTION  }_ERROR`
export const FETCH_REGISTER_SUCCESS_ACTION = `${REGISTER_INIT_ACTION  }_SUCCESS`

export function register(cred) {
  return {
    type: REGISTER_INIT_ACTION,
    cred
  }
}
