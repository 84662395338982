import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar3 from "../../components/Navbar2";
import myCustomTemplate from "../common/customTemplate";
import "./style.css";
import Navbar2 from "../../components/Navbar/index";
import { styles } from "../common/styles";
import Footer from "../../components/Footer";

import t from "tcomb-form";

export interface Props {
  signups: any;
}

export interface State { }

var checkpassword = null;

const nameField = t.refinement(t.String, (value) => {
  const reg = new RegExp("^[a-zA-Z]");
  // return value.length > 1
  return reg.test(value);
});
// const emailField = t.refinement(t.String, value => {
//   const reg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
//   return reg.test(value);
// });
// const emailField = t.refinement(t.String, value => {
//   return value.length > 1;
// });
const mobField = t.refinement(t.Number, (value) => {
  return JSON.stringify(value).length === 10;
});

const pswdField = t.refinement(t.String, (value) => {
  checkpassword = value;
  return value.length >= 6;
});

const cnfrmPswdField = t.refinement(t.String, (value) => {
  return value === checkpassword;
});

const Form = t.form.Form;
const signUpFormStruct = t.struct({
  name: nameField,
  // email:emailField,
  mobileNo: mobField,
  password: pswdField,
  Cpassword: cnfrmPswdField,
});

const options = {
  fields: {
    name: {
      template: myCustomTemplate,

      config: {
        placeholder: "Name",
        errorMessage: " Invalid name",

        label: "Name",
        type: "text",
      },
    },
    //  email: {
    //   template: myCustomTemplate,

    //   config: {
    //     // placeholder: "Email",
    //     errorMessage: " Invalid email",

    //     label: "Email",
    //     type: "text"
    //   }
    // },
    mobileNo: {
      template: myCustomTemplate,

      config: {
        placeholder: "Mobile number",
        errorMessage: " Invalid mobile number",

        label: "Mobile Number",
        type: "text",
      },
    },
    password: {
      template: myCustomTemplate,

      config: {
        placeholder: "Password",
        label: "Password",
        errorMessage: " Invalid password",
        type: "password",
      },
    },
    Cpassword: {
      template: myCustomTemplate,

      config: {
        placeholder: "Confirm password",
        label: "Confirm Password",
        errorMessage: " Invalid confirm password",
        type: "password",
      },
    },
  },
};

class SignUp extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      // otpsection: false,
      // userSection: true,
      options: options,
      value: {
        name: "",
        // email:"",
        password: "",
        Cpassword: "",
        mobileNo: "",
        show: false,
      },
    };

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChange(Person1, path) {
    // this.refs.form.getValue()
    this.refs.form.getComponent(path).validate();
    this.setState({ value: Person1 });
  }

  submit(event) {
    this.setState({
      show: true,
    });
    // event.preventDefault();
    var value = this.refs.form.getValue();
    if (value) {
      let data = {
        value: {
          name: value.name,
          password: value.password,
          Cpassword: value.Cpassword,
          mobileNo: value.mobileNo,
          role: "agent",
        },
      };
      this.props.register(data);
    }

    // if (data) {

    //   this.props.register(data);

    // }
  }

  render() {
    return (
      // <div>
      //   <Navbar2></Navbar2>
      //   <div className="bgclragentRgstr container-fluid">
      //     <div>
      //       <div>
      //         <Container>
      //           <Row>

      //             <Col xs={12} md={12} sm={12} className="main_columSignup">
      //               <div className="cardDiv">
      //                 <Card
      //                   show={this.props.show}

      //                   className="cardMainsignup">
      //                   <Card.Body>

      //                     <Card.Title className="cardheadsignup">
      //                       <h3 className="cardheadsignup"> Registration</h3>
      //                     </Card.Title>
      //                     <div className="signupform">
      //                       <Form
      //                         ref="form"
      //                         type={signUpFormStruct}
      //                         options={this.state.options}
      //                         value={this.state.value}

      //                         onChange={(v, path) => this.onChange(v, path)}
      //                       />
      //                     </div>

      //                     <div className="btnAlignsignup">
      //                       <div>

      //                         <Button
      //                           type="submit"
      //                           className="btnagentSignup"
      //                           style={styles.signupBtn}
      //                           onClick={this.submit}>
      //                           Submit
      //                         </Button>

      //                       </div>

      //                     </div>

      //                     <div className="lastdiv">
      //                       <p className="lastdivLogin">
      //                         Already have an account?{' '}

      //                         <Link
      //                           className="lastdivLoginClr"
      //                           to="/login"
      //                           onClick={() => window.scrollTo(0, 0)}>
      //                           Login
      //                         </Link>
      //                       </p>

      //                       <p className="lastdivTerm">
      //                         By submitting, you agree to{' '}

      //                         <Link
      //                           className="lastdivTermTC"
      //                           to="/terms"
      //                           onClick={() => window.scrollTo(0, 0)}>
      //                           T&C{' '}
      //                         </Link>
      //                         and{' '}

      //                         <Link
      //                           className="lastdivTermTC"
      //                           to="/privacy"
      //                           onClick={() => window.scrollTo(0, 0)}>
      //                           Privacy Policy
      //                         </Link>
      //                       </p>
      //                     </div>
      //                   </Card.Body>
      //                 </Card>
      //               </div>

      //             </Col>

      //           </Row>
      //         </Container>
      //       </div>

      //     </div>
      //   </div>
      //   <div className="footer">
      //     <Footer />
      //   </div>
      // </div>

      // =================================================
      <div>
        <Navbar3></Navbar3>
        <div className="bgcolorLogin">
          <Row>
            <Col xs={12} md={7} sm={12} lg={7}>
              <img
                className="loginfrstImg"
                // src={require("../../assets/img/rydz-login-new-image.png")}
                src={require("../../assets/img/rydz-login-new-image.png")}
                alt=""
              />
            </Col>
            <Col xs={12} md={5} sm={12} lg={5}>
              <div className="signdiv">
                <div>
                  <h3 className="loginrydz">Welcome to Rydz!</h3>
                </div>
                <div>
                  <p className="docdroppera">
                    {/* Please sign-in to your account. */}
                    Please sign-up to your account and start the ride.
                  </p>
                </div>
                {/* <div>
                  <h3 className="loginheader">Registration</h3>
                </div> */}

                <div className="loginForm">
                  <Form
                    ref="form"
                    type={signUpFormStruct}
                    options={this.state.options}
                    value={this.state.value}
                    onChange={(v, path) => this.onChange(v, path)}
                  />
                </div>
                <p className="lastdivTerm">
                  By submitting, you agree to{" "}
                  <Link
                    className="lastdivTermTC"
                    to="/terms"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    T&C{" "}
                  </Link>
                  {" "} and{" "}
                  <Link
                    className="lastdivTermTC"
                    to="/privacy"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Privacy Policy
                  </Link>
                </p>
                <Button
                  type="submit"
                  className="contactpagebtn"
                  onClick={this.submit}
                >
                  Sign up
                </Button>

                <div className="loginaccount">
                  Already have an account?
                  <span className="loginaccounts">
                    <Link
                      to="/login"
                      className="loginaccounts"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Sign In 
                    </Link>
                  </span>
                </div>

              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default SignUp;