import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import t from "tcomb-form";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Navbar3 from "../../components/Navbar2";
import "./style.css";
import myCustomTemplate from "../common/customTemplate";
import { styles } from "./style.js";
import Navbar2 from "../../components/Navbar";
import Footer from "../../components/Footer";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
const Form = t.form.Form;

var checkpassword = null;

const mobileField = t.refinement(t.Number, (value) => {
  return JSON.stringify(value).length === 10;
});
const otpField = t.refinement(t.Number, (value) => {
  return JSON.stringify(value).length === 6;
});

const newPasswordField = t.refinement(t.String, (value) => {
  checkpassword = value;
  return value.length > 5;
});
const retypePasswordField = t.refinement(t.String, (value) => {
  return value === checkpassword;
});

const Person = t.struct({
  mobileNo: mobileField,
});

const otpFieldStruct = t.struct({
  otpNo: otpField,
});

const resetFieldStruct = t.struct({
  newPassword: newPasswordField,
  retypePassword: retypePasswordField,
});

const options = {
  fields: {
    mobileNo: {
      template: myCustomTemplate,

      config: {
        placeholder: "Mobile number",
        errorMessage: "Invalid mobile number",
        labelHide: true,
        type: "text",
        label: "Mobile Number",
      },
    },
  },
};

const otpoptions = {
  fields: {
    otpNo: {
      template: myCustomTemplate,

      config: {
        placeholder: "One time password",
        errorMessage: "Invalid OTP",
        labelHide: true,

        label: "Enter Your OTP",
      },
    },
  },
};

const resetoptions = {
  fields: {
    newPassword: {
      template: myCustomTemplate,

      config: {
        placeholder: "New password",
        errorMessage: "Invalid password",
        labelHide: true,
        label: "New Password",
        type: "password",
        id: "my_id",
      },
    },

    retypePassword: {
      template: myCustomTemplate,

      config: {
        placeholder: "Re-type password",
        errorMessage: "Invalid password",
        labelHide: true,
        label: "Re-type Password",
        type: "password",
        id: "my_id_1",
      },
    },
  },
};

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      isMobileShown: true,
      isOtpShown: false,
      isResetShown: false,
      shown: true,
      shownph: true,
      value: {
        mobileNo: "",
        otpNo: "",
        newPassword: "",
        retypePassword: "",
      },
    };
    // this.onChange = this.onChange.bind(this);
    // this.otpFormChange=this.otpFormChange.bind(this);
  }

  onChangeMobno(Person2, path) {
    this.refs.form.getValue();
  }

  otpFormChange(otpChng, path) {
    this.refs.form.getValue();
  }

  resetFormChange(resetChng, path) {
    this.refs.form.getComponent(path).validate();
    // this.refs.form.getValue()
  }

  submitMobno = () => {
    var mobValue = this.refs.form.getValue();

    if (mobValue) {
      this.props.forgotpassword(mobValue);
      // this.setState({ isMobileShown: false, isOtpShown: true });
    }
    window.scrollTo(0, 0);
  };

  otpsubmit = () => {
    var otpNo = this.refs.form.getValue();

    if (otpNo) {
      this.props.CheckOtp(otpNo);
      // this.setState({ isOtpShown: false, isResetShown: true })
    }
    window.scrollTo(0, 0);
  };

  resetSubmit = () => {
    var resetValue = this.refs.form.getValue();

    if (resetValue) {
      this.props.pswdChange(resetValue);
      // this.setState({ isOtpShown: false, isResetShown: true });
    }
    window.scrollTo(0, 0);
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setInitialScreen();
  }

  render() {
    return (
      // <div>
      //   <Navbar2></Navbar2>
      //   <div className="backgroundclrFrgt container-fluid">
      //     <div>
      //       <div>
      //         <Container>
      //           <Row>
      //             {this.props.screen === 'ForgotNo' && (

      //               <Col xs={12} md={12} sm={12} className="main_columForgot">
      //                 <Card className="cardMainforgot">
      //                   <div>
      //                     <Image
      //                       className="forgotimg"
      //                       src={require('../../assets/img/forgot2.png')}
      //                     />
      //                   </div>
      //                   <Card.Body className="forgotpasswordform">
      //                     <Card.Title className="cardhead">
      //                       <h3 className="forgothead">Forgot Password</h3>
      //                     </Card.Title>
      //                     <p className="forgotpara">
      //                       Enter your mobile number and we'll send an OTP to reset
      //                       your password
      //                     </p>

      //                     <div className=" hidingcntnt">
      //                       <div className="forgotFormGrid">
      //                         <Form
      //                           ref="form"
      //                           type={Person}
      //                           options={options}
      //                           value={this.state.value}
      //                           onChange={(v, path) => this.onChangeMobno(v, path)}
      //                         />
      //                       </div>
      //                       <div className="btnforgot">
      //                         <Button
      //                           type="submit"
      //                           className="forgotbutton"
      //                           style={styles.forgotBtn}
      //                           onClick={this.submitMobno}>
      //                           Next
      //                         </Button>
      //                       </div>
      //                     </div>
      //                   </Card.Body>
      //                 </Card>
      //               </Col>

      //             )}

      //             {this.props.screen === 'ForgotOtp' && (

      //               <Col xs={12} md={12} sm={12} className="main_columLgnOtp">
      //                 <Card className="cardMainLgnOtp">
      //                   <div>
      //                     <Image
      //                       className="otpimg"
      //                       src={require('../../assets/img/msg2.jpeg')}
      //                     />
      //                   </div>
      //                   <Card.Body className="forgotpasswordotp">
      //                     <Card.Title className="cardhead">
      //                       <h3 className="otphead">Enter OTP</h3>
      //                     </Card.Title>
      //                     <p className="otppara">
      //                       We have sent you the access code via SMS for mobile number
      //                       verification
      //                     </p>

      //                     {/* <div className="otphidingcntnt"> */}
      //                     <div className="otpFormGridLgn">
      //                       <Form
      //                         ref="form"
      //                         type={otpFieldStruct}
      //                         options={otpoptions}
      //                         value={this.state.value}
      //                         onChange={(v, path) => this.otpFormChange(v, path)}
      //                       />
      //                     </div>
      //                     <div className="btnAlignotp">
      //                       <Button
      //                         type="submit"
      //                         className="otpButtonFrgt"
      //                         style={styles.forgotBtn}
      //                         onClick={this.otpsubmit}>
      //                         Submit
      //                       </Button>
      //                     </div>
      //                     <h6 className="dntgetotpSection">
      //                       Didn't receive OTP?
      //                       <br />
      //                     </h6>
      //                     <div
      //                       className="resendcodeSection"
      //                       onClick={() => this.props.resendOtp()}>
      //                       Resend OTP
      //                     </div>
      //                   </Card.Body>
      //                 </Card>
      //               </Col>

      //             )}

      //             {this.props.screen === 'ForgotPswrdChng' && (

      //               <Col xs={12} md={12} sm={12} className="main_columLgnReset">
      //                 <Card className="cardMainreset">
      //                   <Card.Body>
      //                     <div>
      //                       <Image
      //                         className="resetimg"
      //                         src={require('../../assets/img/forgot2.png')}
      //                       />
      //                     </div>
      //                     <Card.Title className="cardhead">
      //                       <h3 className="resethead">Reset Password</h3>
      //                     </Card.Title>
      //                     <p className="resetpara">Enter your new password</p>

      //                     <div className="resethidingcntnt">
      //                       <div className="resetFormGrid">
      //                         <Form
      //                           ref="form"
      //                           type={resetFieldStruct}
      //                           options={resetoptions}
      //                           value={this.state.value}
      //                           onChange={(v, path) => this.resetFormChange(v, path)}
      //                         />
      //                       </div>
      //                       <div className="btnreset">

      //                         <Button
      //                           type="submit"
      //                           className="resetbutton"
      //                           style={styles.forgotBtn}
      //                           onClick={this.resetSubmit}>
      //                           Next
      //                         </Button>

      //                       </div>
      //                     </div>
      //                   </Card.Body>
      //                 </Card>
      //               </Col>

      //             )}

      //           </Row>
      //         </Container>
      //       </div>
      //     </div>

      //   </div>
      //   <div className="footer">
      //     <Footer />
      //   </div>
      // </div>

      <div>
        <Navbar3></Navbar3>
        <div className="bgcolorLogin">
          <Row>
            <Col xs={12} md={7} sm={12} lg={7}>
              <img
                className="forgotfrstImg"
                src={require("../../assets/img/forgot-password.svg")}
                alt=""
              />
            </Col>
            <Col xs={12} md={5} sm={12} lg={5}>
              {this.props.screen === "ForgotNo" && (
                <div className="forgotdiv">
                  <div>
                    <h3 className="loginrydz">
                      Forgot Password? {""}
                      <i className="fa fa-lock lockicon fa-md "></i>
                    </h3>
                  </div>
                  <div>
                    <p className="forppera">
                      Enter your mobile number and we'll send an OTP to reset
                      your password.
                    </p>
                  </div>

                  <div className="loginForm">
                    <Form
                      ref="form"
                      type={Person}
                      options={options}
                      value={this.state.value}
                      onChange={(v, path) => this.onChangeMobno(v, path)}
                    />
                  </div>
                  <Button
                    type="submit"
                    className="contactpagebtn"
                    style={styles.forgotBtn}
                    onClick={this.submitMobno}
                  >
                    Next
                  </Button>
                  <div>
                    {/* <span className="loginaccounts">Back to login</span> */}
                  </div>

                </div>

              )}

              {this.props.screen === "ForgotOtp" && (
                <div className="forgotdiv">
                  <div>
                    <h3 className="loginrydz">Enter OTP?</h3>
                  </div>
                  <div>
                    <p className="forppera">
                      We have sent you the access code via SMS for mobile number
                      verification.
                    </p>
                  </div>

                  <div className="loginForm">
                    <Form
                      ref="form"
                      type={otpFieldStruct}
                      options={otpoptions}
                      value={this.state.value}
                      onChange={(v, path) => this.otpFormChange(v, path)}
                    />
                  </div>

                  <Button
                    type="submit"
                    className="contactpagebtn"
                    style={styles.forgotBtn}
                    onClick={this.otpsubmit}
                  >
                    Submit
                  </Button>
                  <div className="loginaccount">
                    Didn't receive OTP?
                    <span
                      className="loginaccounts"
                      onClick={() => this.props.resendOtp()}
                    >
                      Resend OTP
                    </span>
                  </div>
                </div>
              )}

              {this.props.screen === "ForgotPswrdChng" && (
                <div className="forgotdiv">
                  <div>
                    <h3 className="loginrydz">Reset Password?</h3>
                  </div>
                  <div>
                    <p className="forppera">Please enter your new password.</p>
                  </div>

                  <div className="loginForm">
                    <Form
                      ref="form"
                      type={resetFieldStruct}
                      options={resetoptions}
                      value={this.state.value}
                      onChange={(v, path) => this.resetFormChange(v, path)}
                    />
                  </div>

                  <Button
                    type="submit"
                    className="contactpagebtn"
                    style={styles.forgotBtn}
                    onClick={this.resetSubmit}
                  >
                    Next
                  </Button>

                  {/* <span className="loginaccounts">Back to login</span> */}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;