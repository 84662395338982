import {
    FETCH_DASHBOARD_INIT_ACTION,
    FETCH_DASHBOARD_FAIL_ACTION,
    FETCH_DASHBOARD_SUCCESS_ACTION
} from './action'


const initialState = {
    processing: false,
    dashboardList: null
}

export default function (state: any = initialState, action: Function) {
    switch (action.type) {
        case FETCH_DASHBOARD_INIT_ACTION:
            return {
                ...state,
                processing: true,

            }
        case FETCH_DASHBOARD_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                error: false,
                dashboardList: action.payload,
            }

        case FETCH_DASHBOARD_FAIL_ACTION:
            return {
                ...state,
                processing: false,
                error: true,

            }
        default:
            return {
                ...state
            }
    }
}