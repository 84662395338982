import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import t from "tcomb-form";
import myCustomTemplate from "../common/customTemplate";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "./style.css";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import customSelect from "../common/customSelect";
import Navbar2 from "../../components/Navbar";
import Container from "react-bootstrap/Container";
import Footer from "../../components/Footer";
// import Container from "react-bootstrap/Container";
import { styles } from "../common/styles";
import Navbar3 from "../../components/Navbar2";
const Form = t.form.Form;

const stateField = t.refinement(t.String, (value) => {
  return value.length > 1;
});
const districtField = t.refinement(t.String, (value) => {
  return value.length > 3;
});
const cityField = t.refinement(t.String, (value) => {
  return value.length > 3;
});
const pincodeField = t.refinement(t.Number, (value) => {
  return JSON.stringify(value).length === 6;
});

const Person = t.struct({
  profileState: stateField,
  profileDistrict: districtField,
  profileCity: cityField,
  profilePin: pincodeField,
  // profileUpload: t.form.File,
});

const options = {
  fields: {
    profileState: {
      template: customSelect,

      config: {
        // placeholder: 'Enter state',
        errorMessage: "Invalid state",
        labelHide: true,
        label: "State",

        // type: "text"
        values: [
          "Kerala",
          "Andra Pradesh",
          "Arunachal Pradesh",
          "Assam",
          "Bihar",
          "Chhattisgarh",
          "Goa",
          "Gujarat",
          "Haryana",
          "Himachal Pradesh",
          "Jammu and Kashmir",
          "Jharkhand",
          "Karnataka",

          "Madya Pradesh",
          "Maharashtra",
          "Manipur",
          "Meghalaya",
          "Mizoram",
          "Nagaland",
          "Orissa",
          "Punjab",
          "Rajasthan",
          "Sikkim",
          "Tamil Nadu",
          "Telagana",
          "Tripura",
          "Uttaranchal",
          "Uttar Pradesh",
          "West Bengal",
        ],
      },
    },
    profileDistrict: {
      template: myCustomTemplate,

      config: {
        placeholder: "District",
        errorMessage: "Invalid district",
        labelHide: true,
        label: "District",
        type: "text",
      },
    },
    profileCity: {
      template: myCustomTemplate,

      config: {
        placeholder: "City",
        errorMessage: "Invalid city",
        labelHide: true,
        label: "City",
        type: "text",
      },
    },
    profilePin: {
      template: myCustomTemplate,

      config: {
        placeholder: "PIN code",
        errorMessage: "Invalid PIN code",
        labelHide: true,
        label: "PIN code",
        type: "text",
      },
    },
  },
};
class RegisterProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      fileImg: "",
      value: {
        profileState: "Kerala",
        profileDistrict: "",
        profileCity: "",
        profilePin: "",
        file: "",
      },
    };

    this.onChangeProfile = this.onChangeProfile.bind(this);
    this.uploadSingleFile = this.uploadSingleFile.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChangeProfile(Person2, path) {
    // this.refs.form.getValue()
    this.refs.form.getComponent(path).validate();

    this.setState({ value: Person2 });
  }

  uploadSingleFile(e) {
    this.setState({
      // file: URL.createObjectURL(e.target.files[0])
      fileImg: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
      showError: false,
    });
  }

  submit = (event) => {
    event.preventDefault();
    if (this.state.file === undefined) {
      this.setState({
        showError: true,
      });
    }
    var value = this.refs.form.getValue();

    if (value) {
      let data = {
        value: {
          profileState: value.profileState,
          profileDistrict: value.profileDistrict,
          profileCity: value.profileCity,
          profilePin: value.profilePin,
          file: this.state.file,
        },
      };
      this.props.profile(data);
      // this.props.profileImg(this.state.profileImage);
    }

    // if (value) {

    //   this.props.profile(data);
    // }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    let imgPreview;
    if (this.state.fileImg) {
      imgPreview = <img src={this.state.fileImg} alt="" />;
    }
    return (
      // <div>
      //   <Navbar2></Navbar2>

      //   <div className="bgclrRegister container-fluid">
      //     <div>
      //       <div>
      //         <Container>
      //           <Row>
      //             <Col xs={12} md={12} sm={12} className="main_columProfile">
      //               <div className="cardDivprofile">
      //                 <Card className="cardMainprofile">
      //                   <Card.Body className="profileform">
      //                     <Card.Title className="cardhead">
      //                       <h3 className="profilehead">Profile</h3>
      //                     </Card.Title>

      //                     {/* <div> */}
      //                     <div className="profileFormGrid">
      //                       <Form
      //                         ref="form"
      //                         type={Person}
      //                         options={options}

      //                         value={this.state.value}
      //                         onChange={(v, path) =>
      //                           this.onChangeProfile(v, path)
      //                         }
      //                       />
      //                     </div>

      //                     {/* <div> */}
      //                     <Row className="previewimgProfile">
      //                       <Col md={7}>
      //                         {" "}
      //                         <label className="prflImg">Profile Image</label>
      //                         <div
      //                           className="form-group imgpreview"
      //                           style={{
      //                             marginTop: "10px",

      //                             marginLeft: "10px",
      //                             marginRight: "10px",
      //                             width: "100%",

      //                             width: "100%",
      //                           }}
      //                         >
      //                           <input
      //                             className="fileInput"
      //                             type="file"
      //                             className="form-control"
      //                             onChange={this.uploadSingleFile}
      //                           />
      //                         </div>
      //                         {this.state.showError && (
      //                           <p className="fileerrormessage">
      //                             Invalid image
      //                           </p>
      //                         )}
      //                       </Col>
      //                       <Col md={1}></Col>
      //                       <Col
      //                         md={4}
      //                         className="agentprofileImg"
      //                         style={{ width: "100%" }}
      //                       >
      //                         <div
      //                           className="imgpreviewDiv"

      //                           style={{ width: "100%", height: "100%" }}
      //                         >
      //                           {imgPreview}
      //                         </div>
      //                       </Col>
      //                     </Row>

      //                     <div className="btnAlignprofile">
      //                       <Button
      //                         type="submit"
      //                         className="btnagentprofile"
      //                         style={styles.signupBtn}
      //                         onClick={this.submit}
      //                       >
      //                         Next
      //                       </Button>
      //                     </div>

      //                   </Card.Body>
      //                 </Card>
      //               </div>
      //             </Col>
      //           </Row>
      //         </Container>
      //       </div>

      //     </div>
      //   </div>
      //   <div className="footer">
      //     <Footer />
      //   </div>
      // </div>
      // ==================================================================

      <div>
        <Navbar3></Navbar3>
        <div className="bgcolorLogin">
          <Row>
            <Col xs={12} md={7} sm={12} lg={7}>
              <img
                className="loginfrstImg"
                src={require("../../assets/img/rydz-login-new-image.png")}
                alt=""
              />
            </Col>
            <Col xs={12} md={5} sm={12} lg={5}>
              <div className="signdiv">
                {/* <div>
                  <h3 className="loginrydz">Welcome to Rydz!</h3>
                </div> */}
                {/* <div>
                  <p className="docdroppera">
                  
                    Please sign-up to your account and start the ride.
                  </p>
                </div> */}
                <div>
                  <h3 className="loginheader">Profile</h3>
                </div>

                <div className="loginForm">
                  <Form
                    ref="form"
                    type={Person}
                    options={options}
                    value={this.state.value}
                    onChange={(v, path) => this.onChangeProfile(v, path)}
                  />
                </div>

                {/* <div className="fleetEditPrflImg" >
                  <Row className="previewimgProfile">
                  <Col xs={12} md={6} sm={12} lg={6}>
                      {" "}
                      <label className="prflImg">Profile Image</label> */}
                {/* <div
                        className="form-group imgpreview"
                        style={{
                          marginTop: "10px",

                          marginLeft: "20px",
                          marginRight: "10px",
                          width: "100%",

                          width: "100%",
                        }}
                      >
                        <input
                          className="fileInput"
                          type="file"
                          className="form-control"
                          onChange={this.uploadSingleFile}
                        />
                      </div> */}
                {/* <div className="fleetEditPrflfile file btn btn-lg btn-primary">
                        
                          <input
                           
                            name="file"
                            
                            
                            type="file"
                            className="form-control"
                            onChange={this.uploadSingleFile}
                          />
                        </div>
                      {this.state.showError && (
                        <p className="fileerrormessage">Invalid image</p>
                      )}
                    </Col> */}

                {/* <Col xs={12} md={6} sm={12} lg={6}
                    // className="agentprofileImg"
                    // style={{ width: "100%" }}
                    > */}
                {/* <div
                        className="imgpreviewDiv"
                        style={{ width: "100%", height: "100%" }}
                      >
                        {imgPreview}
                      </div>
                    </Col>
                  </Row>
                </div> */}

                <div className="fleetPrflImg">
                  <label className="prflImg">Profile Image</label>
                  <Row className="previewimgProfile">
                  <Col xs={12} md={4} sm={12} lg={4}>
                  <div className="fleetPrflfile file btn btn-lg btn-primary">
                    Choose Photo
                    <input
                      type="file"
                      name="file"
                      onChange={this.uploadSingleFile}
                    />
                  </div>
                  </Col>
                  {this.state.showError && (
                    <p className="fileerrormessage">Invalid image</p>
                  )}
                   <Col xs={12} md={4} sm={12} lg={4}> 
                  <div
                    // className="progimages"
                    style={{ marginTop: "-15px",marginRight: "15px",width: "50%"}}
                  >
                    {imgPreview}
                  </div>
                  </Col>
                  </Row>
                </div>

                <Button
                  type="submit"
                  className="contactpagebtn"
                  onClick={this.submit}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default RegisterProfile;
