import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { styles } from './styles.js'
import './common.css'

class CanelDetailModal extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    submit = () => {
        let value = this.props.cancelRequest
        this.props.cancelRqst(value)
        this.props.handleClose()
    }
    cancel = () => {
        this.props.handleClose()
    }

    render() {

        return (
            <div className="cancelModal">
                <Modal
                    show={this.props.show}
                    onHide={this.props.handleClose}
                    animation={false}
                    size="sm"
                    backdrop="static"
                    className="FleetCancelModal"
                    centered
                    style={{ backgroundColor: 'transparent' }}>
                    {/* <Modal.Header closeButton>
           
          </Modal.Header> */}
                    <Modal.Body>
                        <h6>Do you want to cancel the ride request?</h6>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="fleetNoBtn" style={styles.openBtnCncl} onClick={this.cancel}>
                            No
                        </Button>
                        <Button className="fleetYesBtn" style={styles.bookpgbtn} onClick={this.submit}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default CanelDetailModal