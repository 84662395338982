import * as React from 'react';
import { connect } from 'react-redux';

import SendEnquiry from '../../screens/SendEnqiry/sendEnquiry';
import { sendRequest } from './actions';

export interface Props {
  sendRequest: any;
}

export interface State { }
class SendEnquirySection extends React.Component<Props, State> {
  render() {
    return <SendEnquiry sendRequest={this.props.sendRequest} />
  }
}

function bindAction(dispatch) {
  return {
    sendRequest: values => {
      dispatch(sendRequest(values))
    }
  }
}

const mapStateToProps = state => ({})

export default connect(
  mapStateToProps,
  bindAction
)(SendEnquirySection)
