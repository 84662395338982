export const development = false;

const ipConfig = {
  all: {
    env: development ? 'development' : 'production',
  },
  development: {
    ip: 'http://139.59.78.74:9303/api/v1',
    imgServerip: 'http://139.59.78.74:9176/images'
    // ip: 'http://0.0.0.0:9303/api/v1',
    // imgServerip: 'http://0.0.0.0:9176/images'
  },
  production: {
    ip: 'https://api.rydz.in/api/v1',
    imgServerip: 'https://api.rydz.in/images'
  },
};

export const appConfig = Object.assign(
  ipConfig.all,
  ipConfig[ipConfig.all.env]
);

export const momentDateFormat = 'HH:mm ddd MM-DD-YYYY z';
