import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Card
} from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Link } from 'react-router-dom'

import Navbar2 from '../../components/Navbar'
import Footer from '../../components/Footer';
import './PartnerService.css'

import { styles } from './style.js'

class PartnerService extends Component {
  constructor() {
    super()
    this.state = {
      pType: 'agent'
    }
    this.serviceNotify = this.serviceNotify.bind(this)
    // this.businessNotify=this.businessNotify.bind(this);
  }

  serviceNotify = () => {
    toast.warn('We are working on it')
  }
  // businessNotify=()=>{
  //   toast.warn("Service Unavailable!!!");
  // }

  render() {
    return (
      <div className="partnerfullres">
        <Navbar2 />
        {/* <Container> */}
        <div className="partnerdiv" style={styles.prtnrPage}>
          <Container>
            <div>
              <h3 className="partner_head">Our Partner Services</h3>
            </div>

            {/*================== Card Implemetation based on new requirement - done by jimsa ======================= */}

            <div className="partnerpageCardDark">
              <Row>
                <Col xs={12} sm={12} md={6} lg={3}>
                  <Card
                    className="servicepartner"
                    style={{ marginTop: '20px' }}>
                    <Card.Img
                      className="cardserviceimage"
                      src={require('../../assets/img/corporate.jpeg')}
                    />
                    <Card.Body>
                      <Card.Title className="partnerfirsthd">
                        Corporate Partner
                      </Card.Title>
                      <Card.Text className="partnerfirsttext">
                        Serve taxi booking needs of your organization/customers
                        through a single platform.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={3}>
                  <Card
                    className="servicepartner"
                    style={{ marginTop: '20px' }}>
                    <Card.Img
                      className="cardserviceimage"
                      src={require('../../assets/img/service.jpg')}
                    />
                    <Card.Body>
                      <Card.Title className="partnerfirsthd">
                        Service Partner
                      </Card.Title>
                      <Card.Text className="partnerfirsttext">
                        Take your taxi service to our open taxi platform and
                        make your business more competitive.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3}>
                  <Card
                    className="servicepartner"
                    style={{ marginTop: '20px' }}>
                    <Card.Img
                      src={require('../../assets/img/channellogo.jpeg')}
                    />
                    <Card.Body>
                      <Card.Title className="partnerfirsthd">
                        Channel Partner
                      </Card.Title>
                      <Card.Text className="partnerfirsttext">
                        {/* <p className="partnerfirsttext"> */}
                        On-board and support taxi drivers, manage local user
                        campaigns and grow with Rydz.
                        {/* </p> */}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={3}>
                  <Card
                    className="servicepartner"
                    style={{ marginTop: '20px' }}>
                    <Card.Img
                      className="cardagentimage"
                      src={require('../../assets/img/travel agent.jpg')}
                    />
                    <Card.Body>
                      <Card.Title className="partnerfirsthd">
                        Global Partner
                      </Card.Title>
                      <Card.Text className="partnerfirsttext">
                        Take Rydz to global by Rydz Software as a Service (SaaS)
                        licensing model.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div>
          <Container>
            <div className="aboutopenagentservice">
              <div className="subopenagentservice">
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h3 className="openagenthead">About Corporate Partner</h3>
                    <p className="openagentpara">
                      If you are a promoter or officer responsible for transport
                      department of your company or operating a tour & travel
                      company managing multiple transportation vendors to serve
                      your large employees and/or customers, Rydz Corporate
                      Partner program is suitable for you. You can schedule or
                      book in real-time vehicles from your multiple vendors
                      which reduce idle time/expenses related to trip
                      scheduling.
                    </p>
                    <div className="businessprtnrbtn">
                      <Link to={{
                        pathname: 'http://rydzfleet.com/register'
                        , state: 'fleet'
                      }}
                        target="_blank"
                      //   onClick={() => window.scrollTo(0, 0)}
                      >
                        <Button
                          className="businessbtn"
                          size="lg"
                          // onClick={this.serviceNotify}
                          style={styles.prtnrBtn}>
                          Sign-Up as Corporate Partner
                        </Button>
                      </Link>
                    </div>
                    {/* <div className="openagentbutton">
                      <Link
                        to={{
                          pathname: `/register`,
                          state: 'agent'
                        }}
                        onClick={() => window.scrollTo(0, 0)}>
                        <Button
                          className="openagentservicebtn"
                          size="lg"
                          style={styles.prtnrBtn}>
                          Sign-Up as Agent Partner
                        </Button>
                      </Link>
                    </div> */}
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Image
                      className="openagentimg"
                      src={require('../../assets/img/servicepartnernav.jpeg')}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="servicepartner">
              <div className="subservicepartner">
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Image
                      className="servicepartnerimg"
                      src={require('../../assets/img/openagent.jpg')}
                    />
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h3 className="servicepartnerhead">
                      About Service Partner
                    </h3>
                    <p className="servicepartnerpara">
                      If you are operating a local driver's association or call
                      taxi service or driver network through chat platforms,
                      Rydz Service Partner Program best suitable for you. You
                      can use Rydz platform as a booking platform to serve your
                      existing customers without worrying about developing your
                      own expensive booking software/apps. Rydz don't charge any
                      commission for your rides. You pay subscription fee when
                      you use it on a day. You can accept any number of rides in
                      a day subscription.
                    </p>

                    <div className="servicepartnerbutton">
                      <Link to={{
                        pathname: 'http://rydzfleet.com/register'
                        , state: 'fleet'
                      }}
                        target="_blank"
                      //   onClick={() => window.scrollTo(0, 0)}
                      >
                        <Button
                          size="lg"
                          style={styles.prtnrBtn}
                          className="servicepartnerbtn"
                        // onClick={this.serviceNotify}
                        >
                          Sign-Up as Service Partner
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="aboutservice">
              <div className="subabout">
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h3 className="abouthead">About Channel Partner </h3>
                    <p className="aboutpara">
                      Rydz needs committed business men/women for implementing
                      the service across cities in India. The Rydz Channel
                      Partners shall be assigned a specific city/area and
                      responsible for finding and supporting taxi drivers and
                      manage user campaigns locally. Rydz will share revenue
                      with Rydz channel partner based on the volume of the
                      transactions.
                    </p>

                    <div className="partnerbutton">
                      {/* <Link
                        to={{
                          pathname: `/register`,
                          state: 'fleet'
                        }}
                        onClick={() => window.scrollTo(0, 0)}> */}
                      <Link to={{
                        pathname: `/signUp`,
                        state: 'agent'
                      }}
                      //  target="_blank"
                      //   onClick={() => window.scrollTo(0, 0)}
                      >
                        <Button
                          className="partnerbtn"
                          size="lg"
                          style={styles.prtnrBtn}
                        // onClick={this.serviceNotify}
                        >
                          Sign-Up as Channel Partner
                        </Button>
                      </Link>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Image
                      className="partnerimg"
                      src={require('../../assets/img/partnership-1.jpg')}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="aboutbusiness">
              <div className="subbusiness">
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Image
                      className="businessimg"
                      src={require('../../assets/img/business-partner.jpeg')}
                    />
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h3 className="businesshead">About Global Partner </h3>
                    <p className="businesspara">
                      Rydz offers you the opportunity to run a taxi and logistic
                      booking platform business by getting Rydz SaaS platform
                      licensing in other parts of the world. Please email us for
                      more details.
                    </p>
                    <div className="openagentbutton">
                      <Link
                        to={{
                          pathname: `/sendEnquiry`
                          //state: 'agent'
                        }}
                        onClick={() => window.scrollTo(0, 0)}>
                        <Button
                          className="openagentservicebtn"
                          size="lg"
                          style={styles.prtnrBtn}>
                          Sign-Up as Global Partner
                        </Button>
                      </Link>
                    </div>
                    {/* <div className="businessprtnrbtn">
                      <Button
                        className="businessbtn"
                        size="lg"
                        style={styles.prtnrBtn}
                        onClick={this.serviceNotify}>
                        Sign-Up as Corporate Partner
                      </Button>
                    </div> */}
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>

        {/* <div className="Fleetmanagement" style={styles.prtnrPage}>
          <Container>
            <h2 className="Fleetmanagement_head"> Fleet Management </h2>
            <p className="Fleetmanagementpara">
              If your organization own or operate or rent or lease lots of
              vehicles, Rydz Fleet Management program helps you monitor and
              manage trip expenses in real time. You can also schedule the trips
              and reduce idle time of your fleets without any investment on
              costly vehicle tracking hardware or software systems. You just pay
              subscription fee according to number of fleets and users you have.
            </p>

            <div className="Fleetdivpartner">
              <div className="Fleetmanagementbutton">
                <Link
                  to={{
                    pathname: `/register`,
                    state: 'fleet'
                  }}
                  onClick={() => window.scrollTo(0, 0)}>
                  <Button
                    className="Fleetmanagementbtn"
                    size="lg"
                    style={styles.prtnrBtn}>
                    Sign-Up Fleet Management
                  </Button>
                </Link>
              </div>
            </div>
          </Container>
        </div> */}

        <div className="footer">
          <Footer />
        </div>
      </div>
    )
  }
}
export default PartnerService
