export const USER_CHECK_INIT_ACTION = 'USER_CHECK_INIT'
export const USER_CHECK_FAIL_ACTION = USER_CHECK_INIT_ACTION + '_ERROR'
export const USER_CHECK_SUCCESS_ACTION = USER_CHECK_INIT_ACTION + '_SUCCESS'

export const BOOKING_LOGIN_ACTION = 'BOOKING_LOGIN_ACTION'
export const BOOKING_LOGIN_FAIL_ACTION = BOOKING_LOGIN_ACTION + '_ERROR'
export const BOOKING_LOGIN_SUCCESS_ACTION =
    BOOKING_LOGIN_ACTION + '_SUCCESS'

export const BOOKING_RESEND_OTP_ACTION = 'BOOKING_RESEND_OTP'
export const BOOKING_RESEND_OTP_FAIL_ACTION =
    BOOKING_RESEND_OTP_ACTION + '_ERROR'
export const BOOKING_RESEND_OTP_SUCCESS_ACTION =
    BOOKING_RESEND_OTP_ACTION + '_SUCCESS'

export const CHANGE_SCREEN_ACTION = 'CHANGE_SCREEN_ACTION'

export const UPDATE_PROFILE_ACTION = 'UPDATE_PROFILE_ACTION'
export const UPDATE_PROFILE_SUCCESS_ACTION = UPDATE_PROFILE_ACTION + '_SUCCESS'
export const UPDATE_PROFILE_FAIL_ACTION = UPDATE_PROFILE_ACTION + '_ERROR'

export const REGULAR_ORDER_RQST_ACTION = 'REGULAR_ORDER_RQST_ACTION'
export const REGULAR_ORDER_RQST_SUCCESS_ACTION =
    REGULAR_ORDER_RQST_ACTION + '_SUCCESS'
export const REGULAR_ORDER_RQST_ERROR_ACTION = REGULAR_ORDER_RQST_ACTION + '_ERROR'

export const FETCH_SPECIAL_PKG_INIT_ACTION = "FETCH_SPECIAL_PKG_INIT_ACTION"
export const FETCH_SPECIAL_PACKAGE_SUCCESS_ACTION = FETCH_SPECIAL_PKG_INIT_ACTION + '_SUCCESS'
export const FETCH_SPECIAL_PACKAGE_FAIL_ACTION = FETCH_SPECIAL_PKG_INIT_ACTION + '_ERROR'

export const FETCH_HOURLY_PACKG_ACTION = "FETCH_HOURLY_PACKG_ACTION"
export const FETCH_HOURLY_PACKAGE_SUCCESS_ACTION = FETCH_HOURLY_PACKG_ACTION + '_SUCCESS'
export const FETCH_HOURLY_PACKAGE_FAIL_ACTION = FETCH_HOURLY_PACKG_ACTION + '_ERROR'

export const PACKAGE_NEARBY_DRIVER_ACTION = 'PACKAGE_NEARBY_DRIVER_ACTION'
export const PACKAGE_NEARBY_DRIVER_FAIL_ACTION =
    PACKAGE_NEARBY_DRIVER_ACTION + '_ERROR'
export const PACKAGE_NEARBY_DRIVER_SUCCESS_ACTION =
    PACKAGE_NEARBY_DRIVER_ACTION + '_SUCCESS'

export const PACKAGE_ORDER_INIT_ACTION = "PACKAGE_ORDER_INIT_ACTION"
export const PACKAGE_ORDER_SUCCESS_ACTION = PACKAGE_ORDER_INIT_ACTION + '_SUCCESS'
export const PACKAGE_ORDER_FAIL_ACTION = PACKAGE_ORDER_INIT_ACTION + '_ERROR'

export const QUICK_RIDE_ACTION = 'QUICK_RIDE_RQST'
export const QUICK_RIDE_FAIL_ACTION = QUICK_RIDE_ACTION + '_ERROR'
export const QUICK_RIDE_SUCCESS_ACTION =
    QUICK_RIDE_ACTION + '_SUCCESS'

export const BOOK_LOGOUT_ACTION = 'BOOK_LOGOUT_ACTION'

export function userCheck(value) {
    return {
        type: USER_CHECK_INIT_ACTION,
        payload: value
    }
}

export function login(value) {
    return {
        type: BOOKING_LOGIN_ACTION,
        payload: value
    }
}

export function resendOtp() {
    return {
        type: BOOKING_RESEND_OTP_ACTION,
    }
}

export function changeScreen(value) {
    return {
        type: CHANGE_SCREEN_ACTION,
        payload: value
    }
}

export function profileUpade(value) {
    return {
        type: UPDATE_PROFILE_ACTION,
        payload: value
    }
}

export function regularRqst(value) {
    return {
        type: REGULAR_ORDER_RQST_ACTION,
        payload: value
    }
}

export function fetchSpecialPkg(value) {
    return {
        type: FETCH_SPECIAL_PKG_INIT_ACTION,
        payload: value
    }
}

export function fetchHourlyPkg(value) {
    return {
        type: FETCH_HOURLY_PACKG_ACTION,
        payload: value
    }
}

export function pkgNearByDriver(value) {
    return {
        type: PACKAGE_NEARBY_DRIVER_ACTION,
        payload: value
    }
}

export function pckgOrder(value) {
    return {
        type: PACKAGE_ORDER_INIT_ACTION,
        payload: value
    }
}

export function quickRideRqst(value) {
    return {
        type: QUICK_RIDE_ACTION,
        payload: value
    }
}

export function logoutAction() {
    return {
        type: BOOK_LOGOUT_ACTION,
    }
}
