import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { BrowserRouter as Router, Link } from 'react-router-dom'

import { styles } from './style.js'
import './styles.css'

class Footer extends Component {
  render() {
    return (
      <div className="footer123" style={styles.nav}>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={4} lg={4}></Col>
            <Col xs={12} sm={12} md={4} lg={4}></Col>
            <Col xs={12} sm={12} md={4} lg={4}></Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={4} lg={4}></Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <div className="iconftr">
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <a
                      onClick={() => window.scrollTo(0, 0)}
                      href="https://www.youtube.com/channel/UCVUqGRjvWZoT6SftNPqJR2Q"
                      className="youtube social">
                      <i className="fa fa-youtube-play fa-lg mt-4"></i>
                      {/* <FontAwesomeIcon icon={faYoutube} size="2x" /> */}
                    </a>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <a
                      onClick={() => window.scrollTo(0, 0)}
                      href=" https://www.facebook.com/taxirydz"
                      className="facebook social">
                      <i className="fa fa-facebook fa-lg mt-4"></i>
                      {/* <FontAwesomeIcon icon={faFacebook} size="2x" /> */}
                    </a>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <a
                      onClick={() => window.scrollTo(0, 0)}
                      href=" https://instagram.com/rydz_app"
                      className="instagram social">
                      <i className="fa fa-instagram fa-lg mt-4"></i>
                      {/* <FontAwesomeIcon icon={faInstagram} size="2x" /> */}
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}></Col>
          </Row>
          <div className="ftrpartnerlink">
            <Row>
              <Col xs={12} sm={12} md={2} lg={2}></Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <p className="ftrtermscondton">
                  {/* <a className="ftrtermscondton" href="terms.html">
                    Terms & Conditions-Rider/Driver{' '}
                  </a> */}
                  <Link
                    to="/terms"
                    className="ftrtermscondton"
                    onClick={() => window.scrollTo(0, 0)}>
                    Terms & Conditions-Rider/Driver
                  </Link>{' '}
                  |{' '}
                  <Link
                    to="/terms"
                    className="ftrtermscondton"
                    onClick={() => window.scrollTo(0, 0)}>
                    Terms & Conditions-Partner
                  </Link>{' '}
                  |{' '}
                  <Link
                    to="/privacy"
                    className="ftrtermscondton"
                    onClick={() => window.scrollTo(0, 0)}>
                    Privacy Policy
                  </Link>{' '}
                  |{' '}
                  <Link
                    to="/faq"
                    className="ftrtermscondton"
                    onClick={() => window.scrollTo(0, 0)}>
                    FAQ
                  </Link>
                </p>
              </Col>
              <Col xs={12} sm={12} md={2} lg={2}></Col>
            </Row>
          </div>

          <div className="">
            <Row>
              <Col xs={12} sm={12} md={2} lg={2}></Col>
              <Col xs={12} sm={12} md={10} lg={8}>
                <p className="ftrtermsrights">
                  © All Rights Reserved | Design by
                  <a
                    className="ftrtermsfrugal"
                    // target="_blank"
                    href="https://www.frugalscientific.com/">
                    Frugal Scientific Pvt Ltd
                  </a>
                </p>
              </Col>
              <Col xs={12} sm={12} md={2} lg={2}></Col>
            </Row>
          </div>
        </Container>
      </div>
    )
  }
}
export default Footer
