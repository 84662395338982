import * as React from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import './common.css'
function customSelect(locals: Object) {

  return (
    <Form.Group controlId="formGridState">
      <Form.Label style={{ fontSize: 14 }}>{locals.config.label}</Form.Label>
      <Form.Control
        as="select"
        placeholder={locals.config.placeholder}
        onChange={value => {
          locals.onChange(value.target.value)
        }}
        style={{ backgroundColor: '#d2d2d2', Color: 'black' }}>
        {locals.config.values.map((item, index) => (
          <option key={index} >{item}</option>
        ))}
        {/* className={locals.hasError && "dangerClass"} */}
      </Form.Control>

      {locals.hasError && (
        <p className="errormessage">{locals.config.errorMessage}</p>
      )}
    </Form.Group>
  )
}
export default customSelect
