import { put, takeEvery } from 'redux-saga/effects'
import { createBrowserHistory } from 'history'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import {
    REQUEST_INIT_ACTION,
    REQUEST_FAIL_ACTION,
    REQUEST_SUCCESS_ACTION,
    CONTACT_INIT_ACTION,
    CONTACT_FAIL_ACTION,
    CONTACT_SUCCESS_ACTION
} from './actions'
import { appConfig } from '../../config'
import 'react-toastify/dist/ReactToastify.css'

let history = createBrowserHistory()

function* sendRequest(action) {
    try {
        const res = yield fetch(`${appConfig.ip}/web/sendEnquiry/enquiry`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(action.payload)
        })

        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            toast.success('Your enquiry has been sent successfully  ', {
                autoClose: 2000
            })
            const resJSON = yield res.json()
            yield put({
                type: REQUEST_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: REQUEST_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}

function* contact(action) {
    const enquiryValue = {
        name: action.payload.name,
        mobileNo: `+91${action.payload.mobileNo}`,
        email: action.payload.email,
        message: action.payload.message,
        type: action.payload.type
    }

    try {
        const res = yield fetch(`${appConfig.ip}/web/sendEnquiry`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(enquiryValue)
        })

        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            toast.success('Your enquiry has been sent successfully  ', {
                autoClose: 2000
            })

            const resJSON = yield res.json()

            yield put({
                type: CONTACT_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: CONTACT_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}

export function* sendEnquiryWatcher() {
    yield takeEvery(REQUEST_INIT_ACTION, sendRequest)
    yield takeEvery(CONTACT_INIT_ACTION, contact)
}