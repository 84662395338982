import React from "react";
import "./BookingModal.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Row, Col, Nav, Form, Container, Image } from "react-bootstrap";
import { timeFromInt } from "time-number";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-bootstrap-time-picker";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import Request from "./Request";
import { appConfig } from "../../config";
import { styles } from "../common/styles";

Date.prototype.addDays = function (days) {
  this.setDate(this.getDate() + parseInt(days));
  return this;
};

class BookingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelRequest: "",
      CancelShow: false,
      showvType: true,
      showPickup: false,
      showPickupPdtn: true,
      showDropofPdtn: true,
      showrideCat: true,
      showreqest: false,
      showWhen: false,
      showdropOf: false,
      showPackage: false,
      showPackageDrivers: false,
      showSchedule: false,
      showNearby: false,
      showHourlyPackage: false,
      showSpecialPackage: false,
      pickupLength: 0,
      dropOfLength: 0,
      showError: false,
      requestView: false,
      value: {
        rideCat: "Select your ride type",
        pickup: "",
        dropOf: "",
        when: "When",
        startDate: new Date(),
        package: "Select Package",
        schedule: null,
        maxDate: new Date().addDays(7),
        vType: "car",
        time: "12:00 AM",
        hourlyPackage: "Select Your Hourly Package",
        specialPackage: "Select Your Special Package",
      },
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeVtype = this.changeVtype.bind(this);
    this.nearbyRequest = this.nearbyRequest.bind(this);
    this.packageRequest = this.packageRequest.bind(this);
  }
  changeVtype(value) {
    if (value === "car") {
      this.setState({
        value: {
          ...this.state.value,
          pickup: null,
          dropOf: null,
          vType: value,
          package: "Select Package",
          rideCat: "Select your ride type",
          when: "When",
        },
        showvType: true,
        showrideCat: true,
        showPickup: false,
        showWhen: false,
        showdropOf: false,
        showPackage: false,
        showSchedule: false,
        showNearby: false,
        showreqest: false,
        showHourlyPackage: false,
        showSpecialPackage: false,
        showPackageDrivers: false,
        showError: false,
        requestView: false,
      });
    } else if (value === "auto") {
      this.setState({
        value: {
          ...this.state.value,
          pickup: "",
          dropOf: "",
          vType: value,
          package: "Select Package",
          rideCat: "Select your ride type",
          when: "When",
        },
        showvType: true,
        showrideCat: false,
        showreqest: false,
        showPickup: true,
        showWhen: false,
        showdropOf: false,
        showPackage: false,
        showSchedule: false,
        showNearby: false,
        showHourlyPackage: false,
        showSpecialPackage: false,
        showPackageDrivers: false,
        showError: false,
        requestView: false,
      });
    } else if (value === "Requests") {
      this.setState({
        showvType: true,
        showrideCat: false,
        showreqest: true,
        showPickup: false,
        showWhen: false,
        showdropOf: false,
        showPackage: false,
        showSchedule: false,
        showNearby: false,
        showHourlyPackage: false,
        showSpecialPackage: false,
        showPackageDrivers: false,
        showError: false,
        requestView: true,
      });
    }
  }
  onChange(values) {
    const { value, name } = values.target;
    this.setState({ value: { ...this.state.value, [name]: value } });
    if (name === "pickup") {
      this.setState({ value: { ...this.state.value, pickup: value } });
      if (value.length >= 4 && this.state.pickupLength < value.length) {
        this.setState({
          value: { ...this.state.value, pickup: value },
          showPickupPdtn: true,
          pickupLength: value.length,
          // error: { ...this.state.error, pickup: false }
        });
        this.props.locationFetch({ value: value });
      } else {
        this.setState({
          pickupLength: value.length,
          showPickupPdtn: true,
        });
      }
      if (value.length === 0) {
        this.setState({
          showPickupPdtn: false,
        });
      }
    } else if (name === "rideCat") {
      if (value === "Package") {
        this.setState({
          showvType: true,
          showrideCat: true,
          showPickup: true,
          showWhen: true,
          showdropOf: false,
          showPackage: true,
          showSchedule: false,
          showNearby: false,
          showHourlyPackage: false,
          showSpecialPackage: false,
          showPackageDrivers: false,
          showError: false,
        });
      } else if (value === "Regular") {
        this.setState({
          showvType: true,
          showrideCat: true,
          showPickup: true,
          showWhen: false,
          showdropOf: true,
          showPackage: false,
          showSchedule: false,
          showNearby: false,
          showHourlyPackage: false,
          showSpecialPackage: false,
          showPackageDrivers: false,
          showError: false,
        });
      } else if (value === "Nearby") {
        this.setState({
          showvType: true,
          showrideCat: true,
          showPickup: true,
          showWhen: false,
          showdropOf: false,
          showPackage: false,
          showSchedule: false,
          showNearby: false,
          showHourlyPackage: false,
          showSpecialPackage: false,
          showPackageDrivers: false,
          showError: false,
        });
      } else {
        this.setState({
          showvType: true,
          showrideCat: true,
          showPickup: false,
          showWhen: false,
          showdropOf: false,
          showPackage: false,
          showSchedule: false,
          showNearby: false,
          showHourlyPackage: false,
          showSpecialPackage: false,
          showPackageDrivers: false,
        });
      }
    } else if (name === "dropOf") {
      this.setState({ value: { ...this.state.value, dropOf: value } });
      if (value.length >= 4 && this.state.dropOfLength < value.length) {
        this.setState({
          value: { ...this.state.value, dropOf: value },
          showDropofPdtn: true,
          dropOfLength: value.length,
        });

        this.props.locationFetch({ value: value });
      } else {
        this.setState({
          showDropofPdtn: true,
          dropOfLength: value.length,
        });
      }
      if (value.length === 0) {
        this.setState({
          showDropofPdtn: false,
        });
      }
    } else if (name === "when") {
      if (value === "Schedule for later") {
        if (this.state.value.package === "Select Package") {
          this.setState({
            showHourlyPackage: false,
            showSpecialPackage: false,
          });
        }
        this.setState({
          showvType: true,
          showrideCat: true,
          showPickup: true,
          showWhen: true,
          showdropOf: false,
          showPackage: true,
          showSchedule: true,
          // error: { ...this.state.error, when: false }
        });
      }
      if (value === "Now") {
        if (this.state.value.package === "Hourly Package") {
          this.setState({
            showHourlyPackage: true,
            showSpecialPackage: false,
          });
        } else if (this.state.value.package === "Special Package") {
          this.setState({
            showHourlyPackage: false,
            showSpecialPackage: true,
          });
        }
        this.setState({
          showvType: true,
          showrideCat: true,
          showPickup: true,
          showWhen: true,
          showdropOf: false,
          showPackage: true,
          showSchedule: false,
          // error: { ...this.state.error, when: false }
        });
      }
    } else if (name === "package") {
      this.setState({ value: { ...this.state.value, package: value } });

      if (value) {
        if (value === "Hourly Package") {
          this.setState({ showHourlyPackage: true, showSpecialPackage: false });
        } else if (value === "Special Package") {
          this.setState({ showSpecialPackage: true, showHourlyPackage: false });
        }
        this.setState({
          // error: { ...this.state.error, package: false }
        });
      }
    } else if (name === "specialPackage") {
      this.setState({ value: { ...this.state.value, specialPackage: value } });
    } else if (name === "hourlyPackage") {
      this.setState({ value: { ...this.state.value, hourlyPackage: value } });
    }
  }
  async handleSubmit(event) {
    event.preventDefault();
    // let value = this.state.value
    if (this.state.value.rideCat === "Regular") {
      // console.log("Regular", this.state.value)
      if (
        this.state.value.pickup !== "" &&
        this.state.value.pickup !== null &&
        this.state.value.dropOf !== "" &&
        this.state.value.dropOf !== null
      ) {
        this.props.regularRqst(this.state.value);
        this.setState({
          value: {
            ...this.state.value,
            pickup: "",
            dropOf: "",
            rideCat: "Select your ride type",
            package: "Select Package",
            when: "When",
            startDate: new Date(),
            time: "12:00 AM",
            maxDate: new Date().addDays(7),
          },
          showrideCat: true,
          showPickup: false,
          showdropOf: false,
          showSpecialPackage: false,
          showHourlyPackage: false,
          showError: false,
          showSchedule: false,
          showNearby: false,
        });
      } else {
        // console.log("NOT work", this.state.value)
        this.setState({
          showError: true,
        });
      }
    } else if (this.state.value.rideCat === "Package") {
      if (
        this.state.value.pickup !== "" &&
        this.state.value.package !== "Select Package" &&
        this.state.value.when !== "When" &&
        (this.state.value.hourlyPackage !== "Select Your Hourly Package" ||
          this.state.value.specialPackage !== "Select Your Special Package")
      ) {
        this.props.pkgNearByDriver(this.state.value);
        this.setState({
          value: {
            ...this.state.value,
            pickup: "",
            dropOf: "",
            rideCat: "Select your ride type",
            package: "Select Package",
            hourlyPackage: "Select Your Hourly Package",
            specialPackage: "Select Your Special Package",
            when: "When",
            startDate: new Date(),
            time: "12:00 AM",
            maxDate: new Date().addDays(7),
          },
          showrideCat: false,
          showPackageDrivers: true,
          showSpecialPackage: false,
          showHourlyPackage: false,
          showPickup: false,
          showWhen: false,
          showPackage: false,
          showSchedule: false,
          showNearby: false,
        });
      } else {
        this.setState({
          showError: true,
        });
      }
    }
  }

  quickRideRqst = () => {
    this.setState({
      showvType: true,
      showrideCat: false,
      showreqest: false,
      showPickup: false,
      showWhen: false,
      showdropOf: false,
      showPackage: false,
      showSchedule: false,
      showNearby: false,
      showSpecialPackage: false,
      showHourlyPackage: false,
      showPackageDrivers: false,
    });
  };
  onChangeTime = (value) => {
    var time = timeFromInt(value, { format: 12 });
    this.setState({
      time: timeFromInt(value, { format: 12 }),
      value: { ...this.state.value, time: time },
    });
  };
  handleChangedate = (date) => {
    this.setState({
      startDate: date,
      value: { ...this.state.value, startDate: date },
    });
  };

  handleShowCancel = (data) => {
    this.setState({ CancelShow: true, cancelRequest: data });
  };
  handleCloseCancel = () => {
    this.setState({ CancelShow: false });
  };
  nearbyRequest = (data) => {
    this.quickRideRqst();
    this.props.quickRideRqst({
      data: data,
      pickup: this.state.value.pickup,
    });
    this.setState({
      value: {
        ...this.state.value,
        pickup: "",
      },
      showNearby: false,
    });
    if (this.state.value.vType === "car") {
      this.setState({
        showrideCat: true,
        value: {
          ...this.state.value,
          pickup: "",

          rideCat: "Select your ride type",
        },
      });
    } else if (this.state.value.vType === "auto") {
      this.setState({
        showPickup: true,
        value: {
          ...this.state.value,
          pickup: "",

          rideCat: "Select your ride type",
        },
      });
    }
  };

  packageRequest = (data) => {
    this.props.pckgOrder(data);
    this.setState({
      showPackageDrivers: false,
      showrideCat: true,
      value: {
        ...this.state.value,
        pickup: "",

        rideCat: "Select your ride type",
      },
    });
  };

  componentDidMount() {
    this.props.fetchHourlyPkg();
  }

  render() {
    let value = this.state.value;
    return (
      <div className="booktripregular">
        <Container>
          <ToastContainer />
          <div className="autotaxinearbyfrom">
            <form className="container bkngFrom" onSubmit={this.handleSubmit}>
              {this.state.showvType && (
                <div className="icon_box">
                  {/* <p className="taxiautoqs">Are you looking for?</p> */}

                  <div className="taxibookingmainlook">
                    <Container>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Nav variant="pills" defaultActiveKey="first">
                            <Row>
                              <Col xs={12} sm={12} md={12} lg={4}>
                                <Nav.Item>
                                  <Nav.Link
                                    className={value.vType === "car"}
                                    eventKey="first"
                                    onClick={() => this.changeVtype("car")}
                                  >
                                    <a className="taxibookinglooktaxi">Taxi</a>
                                  </Nav.Link>
                                </Nav.Item>
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={4}>
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="second"
                                    className={value.vType === "auto"}
                                    onClick={() => this.changeVtype("auto")}
                                  >
                                    <a className="taxibookinglookauto">Auto</a>
                                  </Nav.Link>
                                </Nav.Item>
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={4}>
                                <Nav.Item>
                                  <Nav.Link
                                    className={value.vType === "Requests"}
                                    eventKey="third"
                                    onClick={() => this.changeVtype("Requests")}
                                  >
                                    <div>
                                      <a className="taxibookinglookrequests">
                                        Requests
                                      </a>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              </Col>
                            </Row>
                          </Nav>
                        </Col>
                      </Row>
                    </Container>
                    {/* <hr /> */}
                  </div>
                </div>
              )}

              {this.state.showrideCat && (
                <Form.Group controlId="pickupat">
                  <Form.Control
                    className="inputClass"
                    name="rideCat"
                    as="select"
                    value={value.rideCat}
                    onChange={this.onChange}
                  >
                    <option disabled>Select your ride type </option>
                    <option>Regular</option>
                    <option>Package</option>
                    <option>Nearby</option>
                  </Form.Control>
                </Form.Group>
              )}

              {this.state.showPickup && (
                <div>
                  <Form.Group controlId="pickupat">
                    <Form.Control
                      className="inputClass"
                      type="text"
                      name="pickup"
                      placeholder={
                        this.state.value.rideCat === "Nearby" ||
                          this.state.value.vType === "auto"
                          ? " Search by location"
                          : "Pickup location"
                      }
                      value={value.pickup || ""}
                      onChange={this.onChange}
                      autoComplete="off"
                    />
                    <Button
                      onClick={() =>
                        this.setState({
                          value: { ...this.state.value, pickup: "" },
                        })
                      }
                      variant="light"
                      className="closeIcon"
                    >
                      <i className="fa fa-times fa-1x "></i>
                    </Button>
                    {/* {console.log("pickuperror", error.pickup)} */}
                    {/* {error.pickup && (
                      <p className="text-danger">Pickup location is required</p>
                    )} */}

                    {this.state.showError === true &&
                      (this.state.value.pickup === "" ||
                        this.state.value.pickup == null) && (
                        <p className="text-danger">
                          Pickup location is required
                        </p>
                      )}
                  </Form.Group>
                  {this.props.locPrediction &&
                    this.state.showPickupPdtn &&
                    value.pickup &&
                    value.pickup.length >= 4 && (
                      <div className="regularlocationbtn prdtBox">
                        {this.props.locPrediction &&
                          this.state.showPickupPdtn &&
                          value.pickup &&
                          value.pickup.length >= 4 &&
                          this.props.locPrediction.map((key, index) => (
                            <Button
                              key={index}
                              variant="link"
                              onClick={() => {
                                if (this.state.value.vType === "auto") {
                                  this.setState({
                                    pickup: key.description,
                                    showPickupPdtn: false,
                                    showNearby: true,
                                    value: {
                                      ...value,
                                      pickup: key.description,
                                    },
                                  });
                                  let data = {
                                    place: key.description,
                                    rideCat: "auto",
                                    page: 1
                                  };
                                  this.props.fetchNearByDriver(data);
                                } else if (
                                  this.state.value.rideCat === "Nearby"
                                ) {
                                  this.setState({
                                    pickup: key.description,
                                    showPickupPdtn: false,
                                    showNearby: true,
                                    value: {
                                      ...value,
                                      pickup: key.description,
                                    },
                                  });
                                  let data = {
                                    place: key.description,
                                    rideCat: "car",
                                    page: 1
                                  };
                                  this.props.fetchNearByDriver(data);
                                } else if (
                                  this.state.value.rideCat === "Package"
                                ) {
                                  this.props.fetchSpecialPkg(key.description);
                                }
                                this.setState({
                                  pickup: key.description,
                                  showPickupPdtn: false,
                                  // showNearby: true,
                                  value: {
                                    ...value,
                                    pickup: key.description,
                                  },
                                });
                              }}
                            >
                              {key.description}
                            </Button>
                          ))}
                      </div>
                    )}
                </div>
              )}
              {this.state.showdropOf && (
                <div>
                  <Form.Group controlId="pickupat">
                    <Form.Control
                      className="inputClass"
                      name="dropOf"
                      type="text"
                      placeholder="Drop off location"
                      value={value.dropOf || ""}
                      onChange={this.onChange}
                      autoComplete="off"
                    />
                    <Button
                      onClick={() =>
                        this.setState({
                          value: { ...this.state.value, dropOf: "" },
                        })
                      }
                      variant="light"
                      className="closeIcon"
                    >
                      <i
                        className="fa fa-times fa-1x "
                        style={{ paddingtop: "15px" }}
                      ></i>
                    </Button>
                    {/* {error.dropOf && (
                      <p className="text-danger">
                        Drop off location is required
                      </p>
                    )} */}

                    {this.state.showError === true &&
                      (this.state.value.dropOf === "" ||
                        this.state.value.dropOf === null) && (
                        <p className="text-danger">
                          Drop off location is required
                        </p>
                      )}
                  </Form.Group>
                  {this.props.locPrediction &&
                    this.state.showDropofPdtn &&
                    value.dropOf &&
                    value.dropOf.length >= 4 && (
                      <div className="regularlocationbtn prdtBox">
                        {this.props.locPrediction &&
                          this.state.showDropofPdtn &&
                          value.dropOf &&
                          value.dropOf.length >= 4 &&
                          this.props.locPrediction.map((key, index) => (
                            <Button
                              key={index}
                              variant="link"
                              onClick={() =>
                                this.setState({
                                  dropOf: key.description,
                                  showDropofPdtn: false,
                                  value: {
                                    ...value,
                                    dropOf: key.description,
                                  },
                                })
                              }
                            >
                              {key.description}
                            </Button>
                          ))}
                      </div>
                    )}
                </div>
              )}
              {this.state.showPackage && (
                <Form.Group controlId="pickupat">
                  <Form.Control
                    className="inputClass"
                    name="package"
                    as="select"
                    placeholder="Pickup location"
                    value={value.package}
                    onChange={this.onChange}
                  >
                    <option disabled>Select Package</option>
                    {/* <option>Day Rental</option> */}
                    <option>Special Package</option>
                    <option>Hourly Package</option>
                  </Form.Control>
                  {/* {error.package && (
                    <p className="text-danger">Please select a package</p>
                  )} */}

                  {this.state.showError === true &&
                    value.rideCat === "Package" &&
                    value.package === "Select Package" && (
                      <p className="text-danger">Please select a package</p>
                    )}
                </Form.Group>
              )}

              {this.state.showHourlyPackage && (
                <Form.Group controlId="pickupat">
                  <Form.Control
                    className="inputClass"
                    name="hourlyPackage"
                    as="select"
                    placeholder="Pickup location"
                    value={value.hourlyPackage}
                    onChange={this.onChange}
                  >
                    <option disabled>Select Your Hourly Package</option>
                    {this.props.hourlyPkgList &&
                      this.props.hourlyPkgList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.pkgName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              )}
              {this.state.showError === true &&
                value.rideCat === "Package" &&
                value.package === "Hourly Package" &&
                value.hourlyPackage === "Select Your Hourly Package" && (
                  <p className="text-danger">Please select a hourly package</p>
                )}

              {this.state.showSpecialPackage && (
                <div>
                  {/* {this.state.specailPkgList === undefined && (<p className="text-danger">Please Enter the Location</p>)} */}
                  {this.state.value.pickup === null && (
                    <p className="text-danger">Please Enter the Location</p>
                  )}
                  {this.props.specailPkgList &&
                    this.props.specailPkgList.length === 0 && (
                      <p className="text-danger">
                        There is no Special Package for Requested Location{" "}
                      </p>
                    )}
                  <Form.Group controlId="pickupat">
                    <Form.Control
                      className="inputClass"
                      name="specialPackage"
                      as="select"
                      placeholder="Pickup location"
                      value={value.specialPackage}
                      onChange={this.onChange}
                    >
                      <option disabled>Select Your Special Package</option>

                      {this.props.specailPkgList &&
                        this.props.specailPkgList.map((special, index) => (
                          <option
                            className="spclPckgList"
                            key={index}
                            value={special.id}
                          >
                            {special.pkgName}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </div>
              )}
              {this.state.showError === true &&
                value.rideCat === "Package" &&
                value.package === "Special Package" &&
                value.specialPackage === "Select Your Special Package" && (
                  <p className="text-danger">Please select a special package</p>
                )}

              {this.state.showWhen && (
                <Form.Group controlId="pickupat">
                  <Form.Control
                    className="inputClass"
                    name="when"
                    as="select"
                    placeholder="Pickup location"
                    value={value.when}
                    onChange={this.onChange}
                  >
                    <option disabled>When</option>
                    <option>Now</option>
                    <option>Schedule for later</option>
                  </Form.Control>
                  {/* {error.when && (
                    <p className="text-danger">Please select shedule option</p>
                  )} */}

                  {this.state.showError === true &&
                    value.rideCat === "Package" &&
                    value.when === "When" && (
                      <p className="text-danger">
                        Please select shedule option
                      </p>
                    )}
                </Form.Group>
              )}
              {this.state.showSchedule && (
                // <Container>
                <Row className="taxiBookingPicker">
                  {/* <Col sm={1} md={12} lg={1}></Col> */}
                  <Col xs={6} sm={6} md={2} lg={2}></Col>
                  <Col xs={6} sm={6} md={3} lg={3}>
                    <div className="taxipackagedatepicker">
                      <Form.Label>
                        <i
                          className="far fa-calendar-alt"
                          style={{
                            color: "gray",
                            fontWeight: "600",
                          }}
                        ></i>{" "}
                        Date
                      </Form.Label>
                      <DatePicker
                        className="datepickerpackage"
                        minDate={new Date()}
                        selected={value.startDate}
                        maxDate={this.state.value.maxDate}
                        onChange={this.handleChangedate}
                        dateFormat="dd-MM-yyyy"
                      ></DatePicker>
                      {this.state.showError === true &&
                        value.startDate === null && (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            This field is required.
                          </p>
                        )}
                    </div>
                  </Col>
                  {/* <Col xs={12} sm={12} md={12} lg={2}></Col> */}

                  <Col xs={6} sm={6} md={4} lg={4}>
                    <div className="taxipackagetimepicker">
                      <Form.Label>
                        <i
                          className="far fa-clock"
                          style={{
                            color: "gray",
                            fontWeight: "600",
                          }}
                        ></i>
                        Time
                      </Form.Label>

                      <TimePicker
                        step={5}
                        onChange={this.onChangeTime}
                        value={value.time}
                        format={12}
                      />
                    </div>
                  </Col>
                  <Col xs={6} sm={6} md={2} lg={2}></Col>
                  {/* <Col xs={12} sm={12} md={1} lg={1}></Col> */}

                  {/* {error.time ||
                      (error.startDate && (
                        <p className="text-danger">this field is required</p>
                      ))} */}
                </Row>
                // </Container>
              )}

              {/* ************************Start Package section*******************/}
              <div className="packageDrivermainList">
                {this.state.showPackageDrivers &&
                  this.props.pkgDriverList &&
                  this.props.pkgDriverList.length !== 0 ? (
                  <div className="packageDrivr">
                    {this.props.pkgDriverList.map((driver, index) => (
                      <div key={index} className="cardbody">
                        <div className="col-md-12">
                          <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <div className="pckgDriverImgDiv">
                                <Image
                                  className="packageDriverImage"
                                  src={
                                    driver.vprofs !== null && driver.vprofs[0].vImg !== undefined
                                      ? appConfig.imgServerip +
                                      "/getVehiclePic?imgName=" +
                                      driver.vprofs[0].vImg
                                      : require("../../assets/img/car.png")
                                  }
                                />
                              </div>
                              <div
                                className="taxibookPckgdata"
                                numberoflines={1}
                                ellipsizemode={"tail"}
                              >
                                {driver.package && driver.package.pkgName}
                              </div>
                              <Row className="pckgRow">
                                <Col xs={12} sm={6} md={12} lg={4}>
                                  <div className="taxibookPackg">
                                    <div
                                      className="taxibookPckgname"
                                      numberoflines={1}
                                      ellipsizemode={"tail"}
                                    >
                                      {driver.name}
                                    </div>
                                    <div className="taxibookPckgMobile">
                                      {driver.mobileNo}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} sm={6} md={12} lg={6}>
                                  <div className="taxibookPckgsecpara">
                                    <div className="taxibookPckgPrice">
                                      Price: ₹
                                      {driver.package && driver.package.price}
                                    </div>

                                    <div className="taxibookPckgRate">
                                      Additional Rate/Km: ₹
                                      {driver.package &&
                                        driver.package.addtlRatePerKm}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={2}>
                                  <div className="taxibookPckgBtnCol">
                                    <Button
                                      className="taxibookPckgBtn"
                                      style={styles.bookpgbtn}
                                      onClick={() =>
                                        this.packageRequest(driver)
                                      }
                                    >
                                      <i className="fa fa-arrow-right fa-2x pckgBookIcon"></i>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  this.state.showPackageDrivers &&
                  this.props.pkgDriverList &&
                  this.props.pkgDriverList.length === 0 && (
                    <div>
                      <div className="taxiNoDataImgDiv">
                        <Image
                          className="taxiNoDataImg"
                          src={require("../../assets/img/nodata1.png")}
                        />
                      </div>

                      <div className="taxiNoDataTextDiv">No Driver Found</div>
                    </div>
                  )
                )}
              </div>

              {/* ***************************End package section*********************** */}

              {/* ******************************Nearby section****************************** */}

              <div className="mainnearbylist">
                {this.state.showNearby &&
                  this.props.nearbyDrvrList &&
                  this.props.nearbyDrvrList.length !== 0 ? (
                  <div className="nearbybox">
                    {this.props.nearbyDrvrList.map(
                      (data, index) =>
                        value.vType === data.rideCat && (
                          <div key={index} className="cardbody">
                            <div className="col-md-12">
                              <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                  <Row>
                                    <Col xs={12} sm={3} md={12} lg={3}>
                                      <Image
                                        className="taxibooknearvechimg"
                                        src={
                                          data.vehicle !== null && data.vehicle.vImg !== undefined
                                            ? appConfig.imgServerip +
                                            "/getVehiclePic?imgName=" +
                                            data.vehicle.vImg
                                            : require("../../assets/img/defaultPic.jpg")
                                          //require("../../assets/img/rydz user icon300.jpg")
                                        }
                                      />
                                    </Col>
                                    <Col xs={6} sm={3} md={6} lg={3}>
                                      <div className="taxibooknearvechmainpera">
                                        <div
                                          className="taxibooknearvechname"
                                          numberoflines={1}
                                          ellipsizemode={"tail"}
                                        >
                                          {data.name}
                                        </div>

                                        <div
                                          className="taxibooknearvechdata"
                                          numberoflines={1}
                                          ellipsizemode={"tail"}
                                        >
                                          {data.vehicle && data.vehicle.make}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col xs={6} sm={4} md={6} lg={4}>
                                      <div className="taxibooknearvechsecpera">
                                        <div className="taxibooknearvechbaseFare">
                                          Rs{" "}
                                          {data.driver && data.driver.baseFare}{" "}
                                          for{" "}
                                          {data.driver && data.driver.baseKm} KM
                                        </div>

                                        <div className="taxibooknearvechmodel">
                                          {" "}
                                          {data.vehicle && data.vehicle.model}
                                        </div>
                                        <div className="taxibooknearvechratePerKm">
                                          Rate/KM:{" "}
                                          {data.driver && data.driver.ratePerKm}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col xs={12} sm={2} md={12} lg={2}>
                                      <Button
                                        className="taxibooknearvechmainbtn"
                                        style={styles.bookpgbtn}
                                        onClick={() => this.nearbyRequest(data)}
                                      >
                                        <i className="fa fa-arrow-right fa-2x"></i>
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                ) : (
                  this.state.showNearby &&
                  this.props.nearbyDrvrList &&
                  this.props.nearbyDrvrList.length === 0 && (
                    <div>
                      <div className="taxiNoDataImgDiv">
                        <Image
                          className="taxiNoDataImg"
                          src={require("../../assets/img/nodata1.png")}
                        />
                      </div>

                      <div className="taxiNoDataTextDiv">No Data Found</div>
                    </div>
                  )
                )}
              </div>

              {/* ************************************End nearby section****************************** */}

              {/* ********************************************Request section*************************              */}
              {this.state.requestView && (
                <Request
                  fetchActiveRequest={this.props.fetchActiveRequest}
                  activeRqst={this.props.activeRqst}
                  fetchRqstHistory={this.props.fetchRqstHistory}
                  rqstHistory={this.props.rqstHistory}
                  cancelRqst={this.props.cancelRqst}
                />
              )}

              {/* **********************************End request secton*********************************               */}

              {!this.state.showNearby &&
                this.state.showrideCat &&
                value.rideCat !== "Select your ride type" &&
                value.rideCat !== "Nearby" && (
                  <div className="booktripbtnDiv">
                    <Button
                      type="submit"
                      className="taxiBooktripbtn"
                      style={styles.bookpgbtn}
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </Button>
                    <ToastsContainer
                      store={ToastsStore}
                      position={ToastsContainerPosition.TOP_RIGHT}
                    />
                  </div>
                )}
            </form>
          </div>
        </Container>
      </div>
    );
  }
}
export default BookingSection;
