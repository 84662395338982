import * as React from 'react'

import ProfileUpdate from '../../screens/ProfileUpdate'
import { connect } from 'react-redux'

import { editProfile } from '../ProfileContainer/actions'


class ProfileUpdateContainer extends React.Component<Props, State> {

    render() {
        return <ProfileUpdate profile={this.props.profile} />
    }
}

function bindAction(dispatch) {
    return {
        profile: (value) => {
            dispatch(editProfile(value));
        }
    }
}

const mapStateToProps = state => ({})

export default connect(
    mapStateToProps,
    bindAction
)(ProfileUpdateContainer)
