import * as React from 'react'
import { connect } from 'react-redux'

import SignUp from '../../screens/SignUp'
import { register } from './actions'

// export interface Props {
//   register: any;
// }
// export interface State { }
class SignUpContainer extends React.Component<Props, State> {
  render() {
    return (
      <SignUp register={this.props.register} location={this.props.location} />
    )
  }
}
function bindAction(dispatch) {
  return {
    register: value => {
      dispatch(register(value))
    }
  }
}
function mapStateToProps(state) {
  return {}
}
export default connect(
  mapStateToProps,
  bindAction
)(SignUpContainer)
