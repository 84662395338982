import React from "react";
import { Button, Row, Col, Image, Tabs, Tab } from "react-bootstrap";

import { styles } from "../common/styles";
import CancelModal from "../common/cancelModel";
import "./BookingModal.css";
import "./style.css";
var moment = require("moment");

class Request extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "request",
      cancelShow: false,
      cancelRqst: null,
    };
  }

  componentDidMount() {
    this.props.fetchActiveRequest({ page: 1, status: "created,accepted,enroute,waiting,ontrip", search: '' });
  }

  handleSelect = (k) => {
    this.setState({ ...this.state, key: k });
    if (k === "request") {
      this.props.fetchActiveRequest({ page: 1, status: "created,accepted,enroute,waiting,ontrip", search: '' });
    } else if (k === "history") {
      this.props.fetchRqstHistory({ page: 1, status: 'completed,cancelled', search: '' });
    }
  };

  handleCloseView = () => {
    this.setState({
      cancelShow: false,
    });
  };

  render() {
    return (
      <div className="requestMainList">
        <Tabs activeKey={this.state.key} onSelect={this.handleSelect}>
          <Tab className="orderTab" eventKey="request" title="Active Request">
            {this.props.activeRqst && this.props.activeRqst.length !== 0 ? (
              <div>
                <div className="refreshBtn">
                  <Button
                    variant="light"
                    style={{
                      border: "none",
                      background: "none",
                    }}
                    onClick={() => this.props.fetchActiveRequest({ page: 1, status: "created,accepted,enroute,waiting,ontrip", search: '' })}
                  >
                    <i className="fas fa-sync-alt"></i>
                    <br />
                    Refresh
                  </Button>
                </div>
                <div className="minpagrequest">
                  {this.props.activeRqst &&
                    this.props.activeRqst.map((data, index) => (
                      <div className="cardbody" key={index}>
                        <div className="col-md-12">
                          <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <Row className="rqstMainRow">
                                <Col xs={12} sm={6} md={12} lg={3}>
                                  <div className="rqstFirstCol">
                                    <div className="rqstacceptbtn">
                                      {data.status}
                                    </div>
                                    <div className="cancelimgBoxicon">
                                      <Image
                                        className="canceldriverimg"
                                        src={
                                          data.rideCat === "car"
                                            ? require("../../assets/img/car.png")
                                            : require("../../assets/img/auto.png")
                                        }
                                      />
                                    </div>
                                    <div className="reqstmainonline">
                                      {data.rideType}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} sm={6} md={12} lg={6}>
                                  <div className="rqstSecndCol">
                                    <div
                                      className="drivername"
                                      numberoflines={1}
                                      ellipsizemode={"tail"}
                                    >
                                      {data.placeFrom}
                                    </div>
                                    {data.placeTo && (
                                      <div
                                        className="text_elipse"
                                        numberoflines={1}
                                        ellipsizemode={"tail"}
                                      >
                                        {data.placeTo}
                                      </div>
                                    )}
                                    {/* <div className="bookinrestname">
                                      Order ID:
                                      {data.id}
                                    </div> */}
                                    <div className="bookinrestname">
                                      {moment(data.reqDate).format(
                                        "DD MMMM YYYY, h:mm a"
                                      )}
                                    </div>
                                    {data.dName && (
                                      <div className="bookinrestname">
                                        Operator: {data.dName}
                                      </div>
                                    )}
                                    {data.dPhone && (
                                      <div className="bookinrestphone">
                                        {data.dPhone}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                                <Col xs={12} sm={6} md={12} lg={3}>
                                  {data.status !== "ontrip" && (
                                    <div className="rqstBtnDiv">
                                      <Button
                                        className="minpagrequestbtn"
                                        style={styles.BtnCncl}
                                        onClick={() => {
                                          this.setState({
                                            cancelShow: true,
                                            cancelRqst: data,
                                          });
                                          //this.handleShowCancel(data)
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      {/* <BookingCancel
                                                                                show={this.state.CancelShow}
                                                                                handleClose={this.handleCloseCancel}
                                                                                cancelRequest={this.state.cancelRequest}
                                                                                cancelReqst={this.props.cancelReqst}
                                                                            /> */}
                                      <CancelModal
                                        show={this.state.cancelShow}
                                        handleClose={this.handleCloseView}
                                        cancelRequest={this.state.cancelRqst}
                                        cancelRqst={this.props.cancelRqst}
                                      />
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              this.props.activeRqst &&
              this.props.activeRqst.length === 0 && (
                <div>
                  <div className="taxiNoDataImgDiv">
                    <Image
                      className="taxiNoDataImg"
                      src={require("../../assets/img/nodata1.png")}
                    />
                  </div>
                  <div className="taxiNoDataTextDiv">No Data Found</div>
                </div>
              )
            )}
          </Tab>
          <Tab className="orderTab" eventKey="history" title="History">
            {this.props.rqstHistory && this.props.rqstHistory.length > 0 ? (
              <div>
                <div className="refreshBtn">
                  <Button
                    variant="light"
                    style={{
                      border: "none",
                      background: "none",
                    }}
                    onClick={() => this.props.fetchRqstHistory({ page: 1, status: 'completed,cancelled', search: '' })}
                  >
                    <i className="fas fa-sync-alt"></i>
                    <br />
                    Refresh
                  </Button>
                </div>
                <div className="minpagrequest">
                  {this.props.rqstHistory &&
                    this.props.rqstHistory.map((data, index) => (
                      <div className="cardbody" key={index}>
                        <div className="col-md-12">
                          <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <Row className="rqstMainRow">
                                <Col xs={12} sm={6} md={12} lg={3}>
                                  <div className="rqstFirstCol">
                                    <div className="rqstacceptbtn">
                                      {data.status}
                                    </div>
                                    <div className="cancelimgBoxicon">
                                      <Image
                                        className="canceldriverimg"
                                        src={
                                          data.rideCat === "car"
                                            ? require("../../assets/img/car.png")
                                            : require("../../assets/img/auto.png")
                                        }
                                      />
                                    </div>
                                    <div className="reqstmainonline">
                                      {data.rideType}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={9}>
                                  <div className="rqstSecndCol">
                                    <div
                                      className="drivername2"
                                      numberoflines={1}
                                    // ellipsizemode={"tail"}
                                    >
                                      Pick up: {data.placeFrom}
                                    </div>
                                    {data.placeTo && (
                                      <div
                                        className="text_elipse2"
                                        numberoflines={1}
                                      // ellipsizemode={"tail"}
                                      >
                                        Drop off:{data.placeTo}
                                      </div>
                                    )}
                                    {/* <div className="bookinrestname2">
                                      Order ID:
                                      {data.id}
                                    </div> */}
                                    <div className="bookinrestname2">
                                      Date:{moment(data.reqDate).format(
                                        "DD MMMM YYYY, h:mm a"
                                      )}
                                    </div>
                                    {data.dName && (
                                      <div className="bookinrestname2">
                                        Operator: {data.dName}
                                      </div>
                                    )}
                                    {data.dPhone && (
                                      <div className="bookinrestphone">
                                        {data.dPhone}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              this.props.rqstHistory &&
              this.props.rqstHistory.length === 0 && (
                <div>
                  <div className="taxiNoDataImgDiv">
                    <Image
                      className="taxiNoDataImg"
                      src={require("../../assets/img/nodata1.png")}
                    />
                  </div>

                  <div className="taxiNoDataTextDiv">No Data Found</div>
                </div>
              )
            )}
          </Tab>
        </Tabs>
      </div>
    );
  }
}
export default Request;
