import { clr } from '../common/clr'
export const styles = {
  asm: {
    backgroundColor: clr.mainBg
  },
  abutpag: {
    backgroundColor: clr.aboutbg
  },
  mainpgBtn: {
    backgroundColor: clr.btnColor
  },
  mainpgcolor: {
    backgroundColor: clr.maincolor
  }
}
