export const FETCH_ATTACHED_CAR_DRIVER_INIT_ACTION = "FETCH_ATTACHED_DRIVER_INIT_ACTION"
export const FETCH_ATTACHED_CAR_DRIVER_SUCCESS_ACTION = FETCH_ATTACHED_CAR_DRIVER_INIT_ACTION + '_SUCCESS'
export const FETCH_ATTACHED_CAR_DRIVER_FAIL_ACTION = FETCH_ATTACHED_CAR_DRIVER_INIT_ACTION + '_ERROR'

export const LOC_AUTOCOMPLETE_INIT_ACTION = "LOC_AUTOCOMPLETE_INIT_ACTION"
export const LOC_AUTOCOMPLETE_SUCCESS_ACTION = LOC_AUTOCOMPLETE_INIT_ACTION + '_SUCCESS'
export const LOC_AUTOCOMPLETE_FAIL_ACTION = LOC_AUTOCOMPLETE_INIT_ACTION + '_ERROR'

export const FETCH_NEARBY_DRIVER_ACTION =
    'FETCH_NEARBY_DRIVER_RQST'
export const FETCH_NEARBY_DRIVER_FAIL_ACTION =
    FETCH_NEARBY_DRIVER_ACTION + '_ERROR'
export const FETCH_NEARBY_DRIVER_SUCCESS_ACTION =
    FETCH_NEARBY_DRIVER_ACTION + '_SUCCESS'

export const SEARCH_USER_INIT_ACTION = 'SEARCH_USER_INIT_ACTION'
export const SEARCH_USER_FAIL_ACTION = SEARCH_USER_INIT_ACTION + '_ERROR'
export const SEARCH_USER_SUCCESS_ACTION = SEARCH_USER_INIT_ACTION + '_SUCCESS'

export const FETCH_DRIVER_INIT_ACTION = 'FETCH_DRIVER_INIT_ACTION'
export const FETCH_DRIVER_FAIL_ACTION = FETCH_DRIVER_INIT_ACTION + '_ERROR'
export const FETCH_DRIVER_SUCCESS_ACTION = FETCH_DRIVER_INIT_ACTION + '_SUCCESS'

export const REGULAR_RQST_INIT_ACTION = 'REGULAR_RQST_INIT_ACTION'
export const REGULAR_RQST_FAIL_ACTION = REGULAR_RQST_INIT_ACTION + '_ERROR'
export const REGULAR_RQST_SUCCESS_ACTION = REGULAR_RQST_INIT_ACTION + '_SUCCESS'

export const FETCH_SPECIAL_PACKAGE_INIT_ACTION = "FETCH_SPECIAL_PACKAGE_INIT_ACTION"
export const FETCH_SPECIAL_PACKAGE_SUCCESS_ACTION = FETCH_SPECIAL_PACKAGE_INIT_ACTION + '_SUCCESS'
export const FETCH_SPECIAL_PACKAGE_FAIL_ACTION = FETCH_SPECIAL_PACKAGE_INIT_ACTION + '_ERROR'

export const FETCH_HOURLY_PACKAGE_INIT_ACTION = "FETCH_HOURLY_PACKAGE_INIT_ACTION"
export const FETCH_HOURLY_PACKAGE_SUCCESS_ACTION = FETCH_HOURLY_PACKAGE_INIT_ACTION + '_SUCCESS'
export const FETCH_HOURLY_PACKAGE_FAIL_ACTION = FETCH_HOURLY_PACKAGE_INIT_ACTION + '_ERROR'

export const PACKAGE_REQUEST_INIT_ACTION = "PACKAGE_REQUEST_INIT_ACTION"
export const PACKAGE_REQUEST_SUCCESS_ACTION = PACKAGE_REQUEST_INIT_ACTION + '_SUCCESS'
export const PACKAGE_REQUEST_FAIL_ACTION = PACKAGE_REQUEST_INIT_ACTION + '_ERROR'

export function fetchCarDrivers() {
    return {
        type: FETCH_ATTACHED_CAR_DRIVER_INIT_ACTION
    }
}

export function locAutocompleteFetch(value) {
    return {
        type: LOC_AUTOCOMPLETE_INIT_ACTION,
        payload: value
    }
}

export function filterbyLoc(value) {
    return {
        type: FETCH_NEARBY_DRIVER_ACTION,
        payload: value
    }
}

export function userSearch(value) {
    return {
        type: SEARCH_USER_INIT_ACTION,
        payload: value
    }
}

export function fetchDriver(value) {
    return {
        type: FETCH_DRIVER_INIT_ACTION,
        payload: value
    }
}

export function regularRqst(value) {
    return {
        type: REGULAR_RQST_INIT_ACTION,
        payload: value
    }
}

export function fetchSpecialPackage(value) {
    return {
        type: FETCH_SPECIAL_PACKAGE_INIT_ACTION,
        payload: value
    }
}

export function fetchHourlyPackage(value) {
    return {
        type: FETCH_HOURLY_PACKAGE_INIT_ACTION,
        payload: value
    }
}

export function packageRqst(value) {
    return {
        type: PACKAGE_REQUEST_INIT_ACTION,
        payload: value
    }
}
