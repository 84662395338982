import {
    // FETCH_AUTO_DRIVER_INIT_ACTION,
    // FETCH_AUTO_DRIVER_SUCCESS_ACTION,
    // FETCH_AUTO_DRIVER_FAIL_ACTION,
    FETCH_NEARBYAUTO_DRIVER_SUCCESS_ACTION,
    SEARCH_AUTOUSER_SUCCESS_ACTION
} from './action'

const initialState = {
    processing: false,
    error: false,
    autoDrvr: [],
    locPrediction: [],
    nearbyDrvrList: [],
    searchUser: []
}

export default function (state: any = initialState, action: Function) {
    switch (action.type) {
        // case FETCH_AUTO_DRIVER_INIT_ACTION:
        //     return {
        //         ...state,
        //         processing: true
        //     }
        // case FETCH_AUTO_DRIVER_SUCCESS_ACTION:
        //     return {
        //         ...state,
        //         processing: false,
        //         error: false,
        //         autoDrvr: action.payload.rows,
        //         count: action.payload.count
        //     }
        // case FETCH_AUTO_DRIVER_FAIL_ACTION:
        //     return {
        //         ...state,
        //         processing: false,
        //         isLogin: false,
        //         error: true
        //     }
        case FETCH_NEARBYAUTO_DRIVER_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                nearbyDrvrList: action.payload.rows,
                error: true
            }
        case SEARCH_AUTOUSER_SUCCESS_ACTION:
            return {
                ...state,
                searchUser: action.payload
            }
        default:
            return {
                ...state
            }
    }
}