import * as React from 'react'

import Home from '../../screens/Home'
import { connect } from 'react-redux'

import {
    userCheck,
    login,
    resendOtp,
    changeScreen,
    profileUpade,
    regularRqst,
    fetchSpecialPkg,
    fetchHourlyPkg,
    pkgNearByDriver,
    pckgOrder,
    quickRideRqst,
    logoutAction
} from './action'
import { fetchActiveRequest, fetchRqstHistory, cancelRqst } from '../RequestContainer/actions'
import { locAutocompleteFetch, filterbyLoc } from '../CabsContainer/actions'


class HomeContainer extends React.Component<Props, State> {

    render() {
        return <Home
            screen={this.props.screen}
            userCheck={this.props.userCheck}
            login={this.props.login}
            resendOtp={this.props.resendOtp}
            screenChange={this.props.changeScreen}
            profileUpade={this.props.profileUpade}
            fetchActiveRequest={this.props.fetchActiveRequest}
            activeRqst={this.props.activeRqst}
            fetchRqstHistory={this.props.fetchRqstHistory}
            rqstHistory={this.props.rqstHistory}
            cancelRqst={this.props.cancelRqst}
            locationFetch={this.props.locationFetch}
            locPrediction={this.props.locPrediction}
            regularRqst={this.props.regularRqst}
            fetchSpecialPkg={this.props.fetchSpecialPkg}
            specailPkgList={this.props.specailPkgList}
            fetchHourlyPkg={this.props.fetchHourlyPkg}
            hourlyPkgList={this.props.hourlyPkgList}
            pkgNearByDriver={this.props.pkgNearByDriver}
            pkgDriverList={this.props.pkgDriverList}
            pckgOrder={this.props.pckgOrder}
            fetchNearByDriver={this.props.fetchNearByDriver}
            nearbyDrvrList={this.props.nearbyDrvrList}
            quickRideRqst={this.props.quickRideRqst}
            showLogout={this.props.showLogout}
            logoutAction={this.props.logoutAction} />
    }
}

function bindAction(dispatch) {
    return {
        userCheck: (value) => {
            dispatch(userCheck(value))
        },
        login: (value) => {
            dispatch(login(value))
        },
        resendOtp: (value) => {
            dispatch(resendOtp(value))
        },
        changeScreen: (value) => {
            dispatch(changeScreen(value))
        },
        profileUpade: (value) => {
            dispatch(profileUpade(value))
        },
        fetchActiveRequest: (value) => {
            dispatch(fetchActiveRequest(value))
        },
        fetchRqstHistory: (value) => {
            dispatch(fetchRqstHistory(value))
        },
        cancelRqst: (data) => {
            dispatch(cancelRqst(data));
        },
        locationFetch: (value) => {
            dispatch(locAutocompleteFetch(value));
        },
        regularRqst: (value) => {
            dispatch(regularRqst(value))
        },
        fetchSpecialPkg: value => {
            dispatch(fetchSpecialPkg(value));
        },
        fetchHourlyPkg: value => {
            dispatch(fetchHourlyPkg(value))
        },
        pkgNearByDriver: (value) => {
            dispatch(pkgNearByDriver(value))
        },
        pckgOrder: (value) => {
            dispatch(pckgOrder(value))
        },
        fetchNearByDriver: (value) => {
            dispatch(filterbyLoc(value))
        },
        quickRideRqst: (value) => {
            dispatch(quickRideRqst(value))
        },
        logoutAction: () => {
            dispatch(logoutAction())
        }
    }
}

const mapStateToProps = state => ({
    screen: state.homeReducer.screen,
    activeRqst: state.requestReducer.activeRqst,
    rqstHistory: state.requestReducer.rqstHistory,
    locPrediction: state.cabsReducer.locPrediction,
    specailPkgList: state.homeReducer.specailPkgList,
    hourlyPkgList: state.homeReducer.hourlyPkgList,
    pkgDriverList: state.homeReducer.pkgDriverList,
    nearbyDrvrList: state.cabsReducer.nearbyDrvrList,
    showLogout: state.homeReducer.cred !== null,
})

export default connect(
    mapStateToProps,
    bindAction
)(HomeContainer)
