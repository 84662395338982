
import { put, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { push } from 'connected-react-router'

import {
    // FETCH_AUTO_DRIVER_INIT_ACTION,
    // FETCH_AUTO_DRIVER_SUCCESS_ACTION,
    // FETCH_AUTO_DRIVER_FAIL_ACTION,
    FETCH_NEARBYAUTO_DRIVER_ACTION,
    FETCH_NEARBYAUTO_DRIVER_FAIL_ACTION,
    FETCH_NEARBYAUTO_DRIVER_SUCCESS_ACTION,
    SEARCH_AUTOUSER_INIT_ACTION,
    SEARCH_AUTOUSER_SUCCESS_ACTION,
    SEARCH_AUTOUSER_FAIL_ACTION,
    FETCH_AUTO_QUICKRQST_ACTION,
    FETCH_AUTO_QUICKRQST_FAIL_ACTION,
    FETCH_AUTO_QUICKRQST_SUCCESS_ACTION
} from './action'
import 'react-toastify/dist/ReactToastify.css'
import { appConfig } from '../../config'


// function* fetchAutoDrivers() {
//     try {
//         const token = localStorage.getItem('userdata')
//         const Autores = yield fetch(
//             `${appConfig.ip}/web/geoLocs/getNearestAttachedDrivers?rideCat=auto`,
//             {
//                 method: 'GET',
//                 headers: {
//                     Accept: 'application/json',
//                     'Content-Type': 'application/json',
//                     Authorization: `Bearer ${token}`
//                 }
//             }
//         )
//         if (!Autores.ok) {
//             let errJSON = {}
//             try {
//                 errJSON = yield Autores.json()
//             } catch { }
//             throw Object.assign(Autores, errJSON)
//         } else {
//             const resJSON = yield Autores.json()
//             yield put({
//                 type: FETCH_AUTO_DRIVER_SUCCESS_ACTION,
//                 payload: resJSON
//             })
//         }
//     } catch (err) {
//         if (err.ok === false) {
//             yield put({ type: FETCH_AUTO_DRIVER_FAIL_ACTION, error: err })
//         } else {
//         }
//     }
// }

function* fetchNearByDriver(action) {
    // console.log("========autocontainerrrrrr")
    //const placevalue = value.value
    try {
        const token = localStorage.getItem('userdata')
        const res = yield fetch(
            `${appConfig.ip}/web/geoLocs/getNearestDriversOfPlace?place=${action.payload}&dist=5000&riderCat=auto`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()

            yield put({
                type: FETCH_NEARBYAUTO_DRIVER_SUCCESS_ACTION,
                payload: resJSON
            })

        }
    } catch (err) {

        if (err.ok === false) {
            yield put({
                type: FETCH_NEARBYAUTO_DRIVER_FAIL_ACTION,
                error: err
            })
        } else {
            // yield put({type: CHECK_INTERNET_ACTION, que: true, payload: action});
        }
    }
}

function* serachUser(action) {
    try {
        const token = localStorage.getItem('userdata')
        const res = yield fetch(
            `${appConfig.ip}/web/users/searchAutoDriver?ser=${action.payload}`,
            // `${appConfig.ip}/web/users/searchAttachedAutoDriver?ser=${action.payload}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()
            yield put({
                type: SEARCH_AUTOUSER_SUCCESS_ACTION,
                payload: resJSON
            })
        }

    } catch (err) {
        if (err.ok === false) {
            yield put({ type: SEARCH_AUTOUSER_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* autoQuickRide(action) {
    const autoValue = {
        rName: action.payload.name,
        rPhone: `+91${action.payload.mobileno}`,
        rideCat: action.payload.vType,
        accptdBy: action.payload.accptdBy,
        placeFrom: action.payload.placeFrom,
        // placeTo: action.payload.placeTo,
        reqType: 'agentInit'
    }
    // console.log("=====fleetautoValue========", autoValue)
    try {
        const token = localStorage.getItem('userdata')

        const res = yield fetch(`${appConfig.ip}/web/orders/agentRideRqst/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(autoValue)
        })

        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            // toast.success("Booking  successfully");
            yield toast.success('Successfully booked', {
                autoClose: 2000
            })
            const resJSON = yield res.json()
            yield put({
                type: FETCH_AUTO_QUICKRQST_SUCCESS_ACTION,
                payload: resJSON
            })
            yield put(push("/request"))
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: FETCH_AUTO_QUICKRQST_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}


export function* auto() {
    // yield takeEvery(FETCH_AUTO_DRIVER_INIT_ACTION, fetchAutoDrivers)
    yield takeEvery(FETCH_NEARBYAUTO_DRIVER_ACTION, fetchNearByDriver)
    yield takeEvery(SEARCH_AUTOUSER_INIT_ACTION, serachUser)
    yield takeEvery(FETCH_AUTO_QUICKRQST_ACTION, autoQuickRide)
}