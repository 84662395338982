import React from "react";
import "./style.css";
import {
  Modal,
  Button,
  Row,
  Col,
  Dropdown,
  Image,
  Container,
} from "react-bootstrap";
import t from "tcomb-form";
import ReCAPTCHA from "react-google-recaptcha";

import BookingSection from "./BookingModal";
import myCustomTemplate from "../common/customTemplate";
import { styles } from "../common/styles";

const Form = t.form.Form;

const nameField = t.refinement(t.String, (value) => {
  return value.length > 2;
});
const mobField = t.refinement(t.Number, (value) => {
  return JSON.stringify(value).length === 10;
});
const otpField = t.refinement(t.Number, (value) => {
  return JSON.stringify(value).length === 6;
});

const user = t.struct({
  mobileNo: mobField,
});
const username = t.struct({
  name: nameField,
});

const otpFieldStruct = t.struct({
  otpNo: otpField,
});

const options = {
  fields: {
    name: {
      template: myCustomTemplate,
      config: {
        placeholder: "Name",
        errorMessage: " This field is required",

        label: "Name",
        type: "text",
      },
    },
    mobileNo: {
      template: myCustomTemplate,
      config: {
        placeholder: "Mobile number",
        errorMessage: "Invalid mobile number",

        label: "Mobile Number",
        type: "text",
      },
    },
  },
};
const nameOptions = {
  fields: {
    name: {
      template: myCustomTemplate,
      config: {
        placeholder: "Name",
        errorMessage: "Invalid name",

        label: "Name",
        type: "text",
      },
    },
  },
};

const otpoptions = {
  fields: {
    otpNo: {
      template: myCustomTemplate,
      config: {
        placeholder: "Enter OTP",
        errorMessage: "Invalid OTP",
        labelHide: true,
        label: "Please enter OTP below to verify your mobile number",
        type: "number",
      },
    },
  },
};

class TaxiBooking extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      vType: "car",
      isMobileShown: true,
      isOtpShown: false,
      regular: false,
      options: options,
      otpoptions: otpoptions,
      nameOptions: nameOptions,
      OrderStatusPage: true,
      newSection: false,

      value: {
        otpNo: "",
        mobileNo: "",
        name: "",
        reqloc: "",
        pickupat: "",
        droploc: "",
      },
      startDate: new Date(),
    };
    this.submit = this.submit.bind(this);
    this.otpsubmit = this.otpsubmit.bind(this);
    this.profilesubmit = this.profilesubmit.bind(this);
  }

  submit = (event) => {
    var value = this.refs.form.getValue();
    if (value) {
      this.props.userCheck(value);
      event.preventDefault();
    }
  };

  otpsubmit = () => {
    var otpvalue = this.refs.form.getValue();
    if (otpvalue) {
      this.props.login(otpvalue.otpNo);
      this.setState({ value: { ...this.state.value, otpNo: null } });
    }
  };

  mobFormChange(mob, path) {
    this.setState({ value: mob });
    this.refs.form.getValue();
  }

  otpChange(otpvalue, path) {
    this.refs.form.getValue();
    this.setState({ value: { ...this.state.value, otpNo: otpvalue.otpNo } });
  }

  profileupdate = (name, path) => {
    this.refs.form.getValue();
    this.setState({ value: { ...this.state.value, name: name.name } });
  };

  captchaChange(value) { }

  regularSubmit = () => {
    this.setState({
      regular: false,
      OrderStatusPage: true,
    });
  };

  profilesubmit = () => {
    var profile = this.refs.form.getValue();
    this.props.profileUpade(profile);
  };

  componentDidMount() {
    // this.props.fetchOrderStatus()
    // let token = localStorage.getItem('userdata')
    // if (token) {
    //     this.props.screenChange('selectVtype')
    // }
  }

  componentWillUnmount() { }

  render() {
    return (
      <div className="taxibooking">
        <Modal
          show={this.props.show}
          onHide={this.props.handleClose}
          animation={false}
          size="xl"
          className="taxiModal taxibooking"
          backdrop="static"
        >
          <Modal.Body>
            <Container>
              <Row>
              <Col xs={12} sm={12} md={3} lg={3}
                  className="taxibookmodelcol"
                  style={styles.tableHead}
                >
                  <Row></Row>

                  <div className="taxibookingselecticon"></div>
                  <h4 className="taxibookingselecticonhead">Fast</h4>
                  <p className="taxibookingselecticonpera">
                    Provides the fastest way to book a cab from anywhere in
                    India providing best deal.
                  </p>

                  <div className="taxibookingselecticon"></div>
                  <h4 className="taxibookingselecticonhead">Easy</h4>
                  <p className="taxibookingselecticonpera">
                    Travel to your destination picked up and they were there
                    right on time with ease and style.
                  </p>

                  <div className="taxibookingselecticon"></div>
                  <h4 className="taxibookingselecticonhead">Flexible</h4>
                  <p className="taxibookingselecticonpera">
                    With flexible pricing you pay for what you use to provide
                    you the travel experience.
                  </p>
                  <div className="taxibookingselecticon"></div>
                  <h4 className="taxibookingselecticonhead">Save</h4>
                  <p className="taxibookingselecticonpera">
                    Save your time and money by booking in advance, save your
                    travel cost.
                  </p>
                </Col>
                <Col xs={12} sm={12} md={9} lg={9}>
                  <div>
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className="onlineTaximobile"
                      >
                        {this.props.screen === "signup" ||
                          this.props.screen === "otp" ? (
                          <Row>
                            <Col xs={12} sm={12} md={4} lg={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4}></Col>
                            <Col xs={12} sm={12} md={4} lg={4}>
                              <i
                                className="fa fa-times fa-1x"
                                style={{
                                  float: "right",
                                  paddingRight: "20px",
                                  paddingTop: "15px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.props.handleClose();
                                  this.setState({
                                    value: { mobileNo: "", otpNo: "" },
                                  });
                                }}
                              ></i>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <div className="btn_action">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="light"
                                  id="dropdown-basic"
                                >
                                  <i className="fa fa-times fa-1x"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => this.props.handleClose()}
                                  >
                                    Close
                                  </Dropdown.Item>
                                  {this.props.showLogout && (
                                    <Dropdown.Item
                                      onClick={() => {
                                        localStorage.removeItem("userdata");
                                        localStorage.removeItem("userId");
                                        this.props.logoutAction();
                                        this.setState({
                                          value: { mobileNo: "" },
                                        });
                                      }}
                                    >
                                      {" "}
                                      Log out
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </Row>
                        )}
                        <h4
                          className="findnearbymainpage"
                        // style={{ textAlign: "center" }}
                        >
                          {" "}
                          {/* <i className="fas fa-taxi"></i> */}
                          Find Taxi/Auto
                        </h4>

                        <hr />
                      </Col>
                    </Row>
                  </div>

                  {this.props.screen === "signup" && (
                    <div className="taxiform">
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Container>
                            <Form
                              ref="form"
                              type={user}
                              options={this.state.options}
                              value={this.state.value}
                              onChange={(v, path) =>
                                this.mobFormChange(v, path)
                              }
                            />
                            <div>
                              <div className="mainbookcaptcha">
                                <ReCAPTCHA
                                  //   sitekey="6LeAFKkUAAAAAEVC_G0tNV7KgVX-gT2xFdUM0Dso"
                                  sitekey="6LeEKeAcAAAAALe_761sWVmJ4c4O6TX0uZmm4r20"
                                />
                              </div>
                            </div>
                            <div className="mainproceedbtn">
                              <Button
                                className="mainproceedfirstbtn"
                                style={styles.bookpgbtn}
                                type="submit"
                                onClick={this.submit}
                              >
                                Proceed
                              </Button>
                            </div>
                          </Container>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {this.props.screen === "otp" && (
                    <div className="taxiformsec">
                      <div className="taxibookingImgDiv">
                        <Image
                          className="taxibookingImg"
                          src={require("../../assets/img/rydzotpphoneimg.png")}
                        />
                      </div>
                      <Form
                        ref="form"
                        type={otpFieldStruct}
                        options={this.state.otpoptions}
                        value={this.state.value}
                        onChange={(v, path) => this.otpChange(v, path)}
                      />

                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Row>
                            <Col xs={12} sm={12} md={12} lg={6}>
                              <Button
                                variant="light"
                                className="chngNmbrpara"
                                onClick={() => {
                                  this.props.screenChange("signup");
                                  this.setState({
                                    value: { ...this.state.value, otpNo: null },
                                  });
                                }}
                              >
                                Change Number?
                              </Button>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                              <Button
                                variant="light"
                                className="rsndotppara"
                                onClick={() => {
                                  this.props.resendOtp();
                                  this.setState({
                                    value: { ...this.state.value, otpNo: null },
                                  });
                                }}
                              >
                                Resend OTP
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <div className="Taxiotpbuttn">
                        <Button
                          className="Taxiotpbuttnsub"
                          type="submit"
                          onClick={this.otpsubmit}
                          style={styles.bookpgbtn}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}

                  {this.props.screen === "selectVtype" && (
                    <div>
                      <BookingSection
                        fetchActiveRequest={this.props.fetchActiveRequest}
                        activeRqst={this.props.activeRqst}
                        fetchRqstHistory={this.props.fetchRqstHistory}
                        rqstHistory={this.props.rqstHistory}
                        cancelRqst={this.props.cancelRqst}
                        locationFetch={this.props.locationFetch}
                        locPrediction={this.props.locPrediction}
                        regularRqst={this.props.regularRqst}
                        fetchSpecialPkg={this.props.fetchSpecialPkg}
                        specailPkgList={this.props.specailPkgList}
                        fetchHourlyPkg={this.props.fetchHourlyPkg}
                        hourlyPkgList={this.props.hourlyPkgList}
                        pkgNearByDriver={this.props.pkgNearByDriver}
                        pkgDriverList={this.props.pkgDriverList}
                        pckgOrder={this.props.pckgOrder}
                        fetchNearByDriver={this.props.fetchNearByDriver}
                        nearbyDrvrList={this.props.nearbyDrvrList}
                        quickRideRqst={this.props.quickRideRqst}
                        //locationPrediction={this.props.locationPrediction}
                        // DropOfflocationPrediction={
                        //    this.props.DropOfflocationPrediction
                        // }
                        driverlist={this.props.driverlist}
                        scheduldPickupPrediction={
                          this.props.scheduldPickupPrediction
                        }
                        //fetchNearByDriver={this.props.fetchNearByDriver}
                        // orderRqst={this.props.orderRqst}

                        //  fetchOrderStatus={this.props.fetchOrderStatus}
                        // weborderStatus={this.props.weborderStatus}
                        //cancelReqst={this.props.cancelReqst}
                        screen={this.props.screen}
                      //  fetchHourlyPackage={this.props.fetchHourlyPackage}
                      // hourlypackageList={this.props.hourlypackageList}
                      // fetchSpecialPackage={this.props.fetchSpecialPackage}
                      //  specialPackageList={this.props.specialPackageList}
                      //packageRqst={this.props.packageRqst}
                      // packageDriversList={this.props.packageDriversList}

                      //requestHistory={this.props.requestHistory}
                      //orderHistory={this.props.orderHistory}
                      />
                    </div>
                  )}
                  {this.props.screen === "profile" && (
                    <div className="profileUpdate">
                      <Form
                        ref="form"
                        type={username}
                        options={this.state.nameOptions}
                        value={this.state.value}
                        onChange={(v, path) => this.profileupdate(v, path)}
                      />
                      <div className="profileupdatebutton">
                        <Button
                          style={styles.bookpgbtn}
                          type="submit"
                          className="proflesubmit"
                          onClick={this.profilesubmit}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default TaxiBooking;
