import * as React from 'react'
import { connect } from 'react-redux'
import RegisterOTP from '../../screens/RegisterOTP'
import { checkotp, resendOtpAction } from './actions'

export interface Props {
  checkotp: any;
}
export interface State { }
class RegistrationpageSection extends React.Component<Props, State> {
  render() {
    return (
      <RegisterOTP
        checkotp={this.props.checkotp}
        resendOtp={this.props.resendOtp}
      />
    )
  }
}
function bindAction(dispatch) {
  return {
    checkotp: otpvalue => {
      dispatch(checkotp(otpvalue))
    },
    resendOtp: () => {
      dispatch(resendOtpAction())
    }
  }
}
function mapStateToProps(state) {
  return {}
}
export default connect(
  mapStateToProps,
  bindAction
)(RegistrationpageSection)
