export const CHECK_OTP_INIT_ACTION = 'CHECK_OTP_INIT'
export const FETCH_CHECK_OTP_FAIL_ACTION = `${CHECK_OTP_INIT_ACTION  }_ERROR`
export const FETCH_CHECK_OTP_SUCCESS_ACTION = `${CHECK_OTP_INIT_ACTION  }_SUCCESS`

export const SIGNUP_RESEND_OTP_ACTION = 'FETCH_SIGNUP_RESENDOTP_RQST'
export const SIGNUP_RESEND_OTP_FAIL_ACTION = `${SIGNUP_RESEND_OTP_ACTION  }_ERROR`
export const SIGNUP_RESEND_OTP_SUCCESS_ACTION =
  `${SIGNUP_RESEND_OTP_ACTION  }_SUCCESS`

export function checkotp(otp) {
  return {
    type: CHECK_OTP_INIT_ACTION,
    otp
  }
}

export function resendOtpAction() {
  return {
    type: SIGNUP_RESEND_OTP_ACTION,
    meta: {
      retry: true
    }
  }
}
