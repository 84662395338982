import {
    PROFILE_INIT_ACTION,
    PROFILE_SUCCESS_ACTION,
    PROFILE_FAIL_ACTION
} from './actions'


const initialState = {
    processing: false,
    error: false,
    profileData: null
}

export default function (state: any = initialState, action: Function) {
    switch (action.type) {
        case PROFILE_INIT_ACTION:
            return {
                ...state,
                processing: true,
                error: false
            }
        case PROFILE_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                error: false,
                profileData: action.payload
            }
        case PROFILE_FAIL_ACTION:
            return {
                ...state,
                ...state,
                processing: false,
                error: true,
            }
        default:
            return {
                ...state
            }
    }
}