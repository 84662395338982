
export const PROFILE_INIT_ACTION = "PROFILE_INIT_ACTION"
export const PROFILE_SUCCESS_ACTION = PROFILE_INIT_ACTION + '_SUCCESS'
export const PROFILE_FAIL_ACTION = PROFILE_INIT_ACTION + '_ERROR'

export const EDITPROFILE_INIT_ACTION = "EDITPROFILE_INIT_ACTION"
export const EDITPROFILE_SUCCESS_ACTION = EDITPROFILE_INIT_ACTION + '_SUCCESS'
export const EDITPROFILE_FAIL_ACTION = EDITPROFILE_INIT_ACTION + '_ERROR'

export function fetchProfile() {
    return {
        type: PROFILE_INIT_ACTION
    }
}

export function editProfile(value) {
    return {
        type: EDITPROFILE_INIT_ACTION,
        payload: value
    }
}