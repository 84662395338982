
export const REQUEST_INIT_ACTION = 'REQUEST_INIT_ACTION'
export const REQUEST_FAIL_ACTION = REQUEST_INIT_ACTION + '_ERROR'
export const REQUEST_SUCCESS_ACTION = REQUEST_INIT_ACTION + '_SUCCESS'

export const CONTACT_INIT_ACTION = 'CONTACT_INIT_ACTION'
export const CONTACT_FAIL_ACTION = CONTACT_INIT_ACTION + '_ERROR'
export const CONTACT_SUCCESS_ACTION = CONTACT_INIT_ACTION + '_SUCCESS'

export function sendRequest(values) {
  return {
    type: REQUEST_INIT_ACTION,
    payload: values
  }
}

export function contact(values) {
  return {
    type: CONTACT_INIT_ACTION,
    payload: values
  }
}
