import * as React from 'react'
import Form from 'react-bootstrap/Form'
import './common.css'
function myCustomTemplate(locals: Object) {


  return (
    <Form.Group
    >
      <Form.Label style={{ fontSize: 16 }}>{locals.config.label}</Form.Label>

      <Form.Control
        type={locals.config.type === 'password' ? 'password' : 'text'}
        placeholder={locals.config.placeholder}
        onChange={value => locals.onChange(value.target.value)}
        className={locals.hasError && 'dangerClass'}
        value={locals.value}
      // className="formcntrl"
      />

      {locals.hasError && (
        <p className="errormessage">{locals.config.errorMessage}</p>
      )}
    </Form.Group>
  )
}
export default myCustomTemplate
