import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import Home from '../containers/HomeContainer';
import DefaultLayout from '../containers/DafaultLayoutContainer';
import Login from '../containers/LoginContainer';
import SignUp from '../containers/SignUpContainer';
import ForgotPassword from '../containers/ForgotPswdContainer';
import TermsCondition from '../screens/Terms/Terms';
import Privacy from '../screens/Privacy/Privacy';
import Faq from '../screens/Faq/Faq'
import PartnerService from '../screens/PartnerService/PartnerService'
import Contact from '../containers/ContactContainer';
import SendEnquiry from '../containers/SendEnquiryContainer';
import RegisterOTP from '../containers/RegisterOTPContainer';
import ProfileUpdate from '../containers/ProfileUpdateContainer';

// var history = createBrowserHistory();

// const PrivateRoute = ({ component: Component, ...rest }) =>
//   <Route
//     {...rest}
//     render={props =>
//       localStorage.getItem('userdata')
//         ? <Component {...props} />
//         : <Redirect to="/home" />}
//   />;

function AppNavigator() {
  return (
    <Switch>
      <Route path="/home" name="Home" component={Home} />
      <Route path="/login" name="Login" component={Login} />
      <Route path="/signUp" name="SignUp" component={SignUp} />
      <Route path="/terms" name="Terms" component={TermsCondition} />
      <Route path="/privacy" name="Privacy" component={Privacy} />
      <Route path="/faq" name="Faq" component={Faq} />
      <Route path="/partnerservice" name="PartnerService" component={PartnerService} />
      <Route path="/contact" name="Contact" component={Contact} />
      <Route path="/sendEnquiry" name="SendEnquiry" component={SendEnquiry} />
      <Route path="/forgotpassword" name="ForgotPassword" component={ForgotPassword} />
      <Route path="/registerOTP" name="RegisterOTP" component={RegisterOTP} />
      <Route path="/profileUpdate" name="ProfileUpdate" component={ProfileUpdate} />
      <Route path="/dasboard" name="Dashboard" component={DefaultLayout} />
      <Route path="/" name="Dashboard" component={DefaultLayout} />
    </Switch>
  );
}
export default AppNavigator;
