import { clr } from '../common/clr'
export const styles = {
    tableHead: {
        backgroundColor: clr.aboutbg
    },
    openBtn: {
        backgroundColor: clr.btnColor
    },
    openBtnCncl: {
        backgroundColor: clr.cancelBtn,
        borderColor: clr.cancelBtn
    },
    bookpgbtn: {
        backgroundColor: clr.btnColor,
        borderColor: clr.btnColor
    },

    // BtnCncl: {
    //     backgroundColor: clr.cancelBtn
    // }
}