import React from 'react'
import { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Navbar2 from '../../components/Navbar'
import './Privacy.css'

class Privacy extends Component {

  render() {
    return (
      <div>
        <Navbar2></Navbar2>

        <div className="privacyContainer">
          <Container>
            <div className="privacymainhd">Privacy Policy</div>
            <br />
            <br />
            <p>
              By accepting the Terms & Conditions, You agree to the terms of
              this Privacy Policy.
            </p>
            <p></p>
            <br />
            <div className="privacyHead">
              <b className="privacySubHead">1. THIS PRIVACY POLICY APPLIES TO:</b>
              <p></p>
              <p>
                <b className="subprivacyHead">1.1.</b> Individual{' '}
                <b className="privacysubQuotes">(“Driver”, “you” or “User”)</b>{' '}
                registered through and having an Account on the Platform to
                provide the transportation services independently to the Riders
                and owns the taxi, car, auto rickshaw{' '}
                <b className="privacysubQuotes">(“Vehicle”)</b> and has the
                driving License all the necessary and applicable transport
                vehicle permits and licenses to provide transportation
                services; and/or
              </p>
              <p></p>
              <p>
                <b className="subprivacyHead">1.2.</b> Individual{' '}
                <b className="privacysubQuotes">
                  (“Passenger”, “Rider”, “Consumer”, “Customer”, “you” or
                  “User”)
                </b>{' '}
                registered through and having an Account on the Platform to make
                transportation service request to the Driver; and/or
              </p>
              <p></p>
              <p>
                <b className="subprivacyHead">1.3.</b> Otherwise a user of the
                Website or Mobile App
                <b className="privacysubQuotes">(“you” or “User”)</b>.
              </p>
              <p></p>
              <br />

              <b className="privacySubHead">2. DEFINITIONS:</b>
              <p></p>
              <p>
                In this Privacy Policy, unless the context is inconsistent
                therewith, the terms used herein shall have the same meanings
                assigned to them in the Terms & Conditions of Rydz.
              </p>
              <p></p>
              <br />

              <b className="privacySubHead">3. INFORMATION:</b>
              <p></p>
              <p>
                <b className="subprivacyHead">3.1.</b> Information provided to Rydz
                - Information provided to Rydz shall mean such Information
                provided and/or disclosed at the time of creating or updating
                Your Account, including but not limited to, Your name, email,
                phone number, login name and password, address, government
                identification numbers, driver’s license or passport if required
                by law, birth date, photo and signature.
              </p>
              <p></p>
              <p>
                <b className="subprivacyHead">3.2.</b> Information collected by Rydz
                - Information collected by Rydz shall mean such Information
                including but not limited to, Your location, usage information,
                device information, information from other sources and cookies,
                etc.
              </p>
              <p>
                <b className="subprivacyHead">3.3.</b> Information obtained by You -
                This Privacy Policy shall not cover the usage of any information
                about you which is obtained by the Driver, while providing you a
                Transportation Service on a Vehicle booked using the Platform,
                or otherwise, which is not provided by Rydz. Similarly, this
                Privacy Policy shall also not cover the usage of any information
                about the Driver which is obtained by the Rider, while availing
                a Transportation Service on a Vehicle booked using the Platform,
                or otherwise, which is not provided by Rydz.
              </p>
              <p></p>
              <br />
              <p>
                <b>(</b>Hereinafter collectively referred to as{' '}
                <b className="privacysubQuotes">“Information”)</b>
              </p>
              <br />
              <b className="privacySubHead">4. USE OF INFORMATION:</b>
              <p></p>
              <p>
                <b className="subprivacyHead">4.1.</b> Rydz uses the Information to
                provide, personalize, maintain and improve Rydz Service.
              </p>
              <p></p>
              <p>
                <b className="subprivacyHead">4.2.</b> Rydz uses the Information for
                testing, research, analysis and Rydz Service development.
              </p>
              <p></p>
              <p>
                <b className="subprivacyHead">4.3.</b> Rydz uses the Information to
                enable communications between Users.
              </p>
              <p></p>
              <p>
                <b className="subprivacyHead">4.4.</b> Rydz may use the Information
                to communicate with Users about products, services, promotions,
                advertisements, studies, surveys, news, updates and events.
              </p>
              <p></p>
              <br />

              <b className="privacySubHead">5. THIRD PARTIES:</b>
              <p></p>
              <p>
                <b className="subprivacyHead">5.1.</b> Rydz may disclose the
                Information to third parties under any legal obligations.
              </p>
              <p></p>
              <p>
                <b className="subprivacyHead">5.2.</b> Rydz may disclose the
                Information to third parties when third party companies and
                individuals Rydz may use to facilitate Rydz Service, provide or
                perform certain aspects of the Rydz Service, contribute to
                facilitating Rydz Service.
              </p>
              <p></p>
              <p>
                <b className="subprivacyHead">5.3.</b> Rydz may disclose the
                Information to third parties when You agree to receive
                information from third parties.
              </p>
              <p></p>
              <p>
                <b className="subprivacyHead">5.4.</b> Rydz may disclose the
                Information to third parties if business of Rydz undergoes
                transfer, merger, acquisition or otherwise.
              </p>
              <p></p>
              <br />

              <b className="privacySubHead">6. SECURITY:</b>
              <p></p>
              <p>
                The information collected by Rydz is securely stored In Rydz
                database. Rydz uses standard, industry-wide, commercially
                reasonable security practices for protecting such information.
                However, Rydz cannot guarantee the security of databases, nor
                can Rydz guarantee that information provided by the User won't
                be intercepted while being transmitted to Rydz over the Internet
                or wireless communication. Any information transmitted to Rydz
                by the User shall be at the User’s risk. Rydz recommends its
                Users that they not disclose their password to anyone.
              </p>
              <p></p>
              <br />

              <b className="privacySubHead">7. CHANGES TO THE PRIVACY POLICY:</b>
              <p></p>
              <p>
                Rydz may update this Privacy Policy from time to time. Any
                changes will be effective immediately upon the posting of the
                revised Privacy Policy. Users shall review this page from time
                to time for the latest information Rydz’s privacy practices.
              </p>
              <p></p>
              <br />

              <b className="privacySubHead">8. DISCLAIMER:</b>
              <p></p>
              <p>
                Rydz has endeavored to ensure that the information on the
                Platform is correct and up to date, however, Rydz does not
                provide any warranty as to the completeness or accuracy of the
                information and, subject to applicable laws, does not accept any
                liability for damages of any kind resulting from the access or
                use of the Platform and its contents.
              </p>
              <p></p>
              <p>
                Rydz expressly disclaims any, warranties, express or implied,
                regarding the correctness, accuracy, completeness, timeliness,
                and reliability of the text, graphics, links to other sites and
                any other items accessed from or via its Platform or the
                Internet, or that the services will be uninterrupted, error-free
                or free of viruses or other harmful components.
              </p>
              <p></p>
              <br />

              <p>
                THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND
                DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES. BY CLICKING
                ON THE “I ACCEPT” BUTTON ON THIS ELECTRONIC CONTRACT, YOU ARE
                CONSENTING TO BE BOUND BY THIS PRIVACY POLICY.
              </p>
              <br />
              <br />
              <br />
            </div>
          </Container>
        </div>
      </div>
    )
  }
}
export default Privacy
