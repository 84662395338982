import React from 'react';

//const ForgotPassword = React.lazy(() => import('./containers/ForgotPasswordContainer'))
const SignUp = React.lazy(() => import('./containers/SignUpContainer'))
const Login = React.lazy(() => import("./containers/LoginContainer"));
const Dashboard = React.lazy(() => import("./containers/DashboardContainer"));
const Profile = React.lazy(() => import('./containers/ProfileContainer'))
const EditProfile = React.lazy(() => import('./containers/EditProfileContainer'))
const ResetPswd = React.lazy(() => import('./containers/ResetPswdContainer'))
const Request = React.lazy(() => import('./containers/RequestContainer'))
const Cabs = React.lazy(() => import('./containers/CabsContainer'))
const Auto = React.lazy(() => import('./containers/AutoContainer'))

const routes = [
  {
    path: '/login',
    component: Login,
  },
  // {
  //   path: '/forgotpassword',
  //   component: ForgotPassword,
  // },
  {
    path: '/signUp',
    component: SignUp,
  },
  {
    path: '/dashboard',
    component: Dashboard,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/editProfile',
    component: EditProfile,
  },
  {
    path: '/resetPswd',
    component: ResetPswd,
  },
  {
    path: '/cabs',
    component: Cabs,
  },
  {
    path: '/auto',
    component: Auto,
  },
  {
    path: '/request',
    component: Request,
  },

];

export default routes;
