import * as React from 'react'

import Contact from '../../screens/Contact'
import { connect } from 'react-redux'

import { contact } from '../SendEnquiryContainer/actions'


class ContactContainer extends React.Component<Props, State> {

    render() {
        return <Contact contact={this.props.contact} />
    }
}

function bindAction(dispatch) {
    return {
        contact: (value) => {
            dispatch(contact(value));
        }
    }
}

const mapStateToProps = state => ({})

export default connect(
    mapStateToProps,
    bindAction
)(ContactContainer)