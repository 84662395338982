import {
    LOGIN_INIT_ACTION,
    FETCH_LOGIN_FAIL_ACTION,
    FETCH_LOGIN_SUCCESS_ACTION,
    LOGOUT_ACTION,
} from './actions'

//import { FETCH_SIGNUP_SUCCESS_ACTION } from '../SignUpContainer/actions'
const initialState = {
    processing: false,
    isLogin: false,
    error: false,
    token: null
}

export default function (state: any = initialState, action: Function) {
    switch (action.type) {
        case LOGIN_INIT_ACTION:
            return {
                ...state,
                processing: true
            }
        // case FETCH_SIGNUP_SUCCESS_ACTION:
        //     return {
        //         ...state,
        //         processing: false,
        //         // isLogin: true,
        //         error: false
        //     }
        case FETCH_LOGIN_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                isLogin: true,
                error: false,
                role: action.payload.user.role
                // loginCred:action.payload
            }

        case FETCH_LOGIN_FAIL_ACTION:
            return {
                ...state,
                processing: false,
                isLogin: false,
                error: true
            }
        case LOGOUT_ACTION:
            return {
                ...state,
                processing: false,
                isLogin: false,
                error: false,
                token: null
            }

        default:
            return {
                ...state
            }
    }
}