import React, { Component } from "react";
import t from "tcomb-form";
import myCustomTemplate from "../common/customTemplate";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./styles.css";

import Navbar3 from "../../components/Navbar2";
import Footer from "../../components/Footer";
// import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
import { styles } from "./style.js";

export interface Props {
  logins: any;
}

export interface State { }

const PasswordMinLength = t.refinement(t.String, (value) => {
  return value.length > 5;
});

const mobileField = t.refinement(t.Number, (value) => {
  return JSON.stringify(value).length === 10;
});
const Form = t.form.Form;
const Person = t.struct({
  mobileNo: mobileField,
  password: PasswordMinLength,
});
const options = {
  fields: {
    mobileNo: {
      template: myCustomTemplate,

      config: {
        placeholder: "Mobile number",
        errorMessage: "Invalid mobile number",
        labelHide: true,
        label: "Mobile Number",
        type: "text",
      },
    },
    password: {
      template: myCustomTemplate,

      config: {
        placeholder: "Password",
        label: "Password",
        errorMessage: "Invalid password",
        type: "password",
      },
    },
  },
};

class Login extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
      options: options,
      value: {
        password: "",
        mobileNo: "",
        address: "",
      },
    };
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChange(Person1, path) {
    // this.refs.form.getValue()
    this.refs.form.getComponent(path).validate();
  }
  submit(event) {
    var value = this.refs.form.getValue();

    event.preventDefault();
    if (value) {
    }
    this.props.login(value);
  }

  render() {
    return (
      <div>
        <Navbar3></Navbar3>
        <div className="bgcolorLogin">
          <Row>
            <Col xs={12} md={7} sm={12} lg={7}>
              <img
                className="loginfrstImg"
                src={require("../../assets/img/rydz-login-new-image.png")}
                alt=""
              />
            </Col>
            <Col xs={12} md={5} sm={12} lg={5}>
              <div className="logindiv">
                <div>
                  <h3 className="loginrydz">Welcome to Rydz!</h3>
                </div>
                <div>
                  <p className="docdroppera">
                    {/* Please sign-in to your account. */}
                    Please sign-in to your account and start the ride.
                  </p>
                </div>
                {/* <div>
                    <h3 className="loginheader">Login</h3>
                  </div> */}

                <div className="loginForm">
                  <Form
                    ref="form"
                    type={Person}
                    options={this.state.options}
                    value={this.state.value}
                    onChange={(v, path) => this.onChange(v, path)}
                  />
                </div>
                <div >
                  <Link
                    to="/forgotpassword"
                    className="loginforgot"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Forgot your Password?
                  </Link>
                </div>

                <Button
                  type="submit"
                  className="contactpagebtn"
                  onClick={this.submit}
                >
                  Sign In
                </Button>

                <div className="loginaccount">
                  Don't have an account?
                  <span className="loginaccounts">
                    <Link
                      to="/signUp"
                      className="loginaccounts"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Create an account
                    </Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Login;
