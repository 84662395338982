import './_nav.css';

export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'Cabs',
      url: '/cabs',
      icon: 'fa fa-car',
    },
    {
      name: 'Auto',
      url: '/auto',
      icon: 'fa fa-truck',
    },
    {
      name: 'Request',
      url: '/request',
      icon: 'fa fa-circle-o-notch',
    },
  ],
};
