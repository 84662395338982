import * as React from 'react'

import DefaultLayout from '../../screens/DefaultLayout'
import { connect } from 'react-redux'

import { logOut } from '../LoginContainer/actions'


class DefaultLayoutContainer extends React.Component<Props, State> {

    render() {
        return <DefaultLayout logout={this.props.logout} history={this.props.history} />
    }
}

function bindAction(dispatch) {
    return {
        logout: () => {
            dispatch(logOut());
        }
    }
}

const mapStateToProps = state => ({})

export default connect(
    mapStateToProps,
    bindAction
)(DefaultLayoutContainer)
