import { put, takeEvery, select } from 'redux-saga/effects'
import { createBrowserHistory } from 'history'
import {
  CHECK_OTP_INIT_ACTION,
  FETCH_CHECK_OTP_FAIL_ACTION,
  FETCH_CHECK_OTP_SUCCESS_ACTION,
  SIGNUP_RESEND_OTP_ACTION,
  SIGNUP_RESEND_OTP_FAIL_ACTION,
  SIGNUP_RESEND_OTP_SUCCESS_ACTION
} from './actions'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { appConfig } from '../../config'
var btoa = require('Base64').btoa
var history = createBrowserHistory()

function* checkotp(otp) {

  const OtpValue = {
    pin: otp.otp.agentOTP
  }



  try {
    let token = localStorage.getItem('userdata')


    let res = yield fetch(appConfig.ip + '/phoneVerify/checkpin/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(OtpValue)
    })

    if (!res.ok) {
      let errJSON = {}
      try {
        errJSON = yield res.json()
      } catch { }
      throw Object.assign(res, errJSON)
    } else {
      let resJSON = yield res.json()
      if (!resJSON.ok) {
        throw resJSON
      }
      yield put({
        type: FETCH_CHECK_OTP_SUCCESS_ACTION,
        payload: resJSON
      })

      yield toast.success('OTP verified successfully', {
        autoClose: 3000
      })

      yield put(push("/profileUpdate"))

    }
  } catch (err) {
    if (err.ok === false) {
      yield toast.error('Invalid OTP', {
        autoClose: 3000
      })

      yield put({
        type: FETCH_CHECK_OTP_FAIL_ACTION,
        error: err
      })
    } else {
    }
  }
}

function* resendotpAction(otp) {


  try {

    let token = localStorage.getItem('userdata')

    let res = yield fetch(appConfig.ip + '/phoneVerify/resendotp', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }

    })

    if (!res.ok) {
      let errJSON = {}
      try {
        errJSON = yield res.json()
      } catch { }
      throw Object.assign(res, errJSON)
    } else {
      let resJSON = yield res.json()
      yield put({
        type: SIGNUP_RESEND_OTP_SUCCESS_ACTION
      })

      yield toast.success('OTP has been resent successfully', {
        autoClose: 3000
      })


    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: SIGNUP_RESEND_OTP_FAIL_ACTION,
        error: err
      })
    } else {
    }
  }
}

export function* registerOTP() {
  yield takeEvery(CHECK_OTP_INIT_ACTION, checkotp)
  yield takeEvery(SIGNUP_RESEND_OTP_ACTION, resendotpAction)
}
