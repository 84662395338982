import { put, takeEvery, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
    USER_CHECK_INIT_ACTION,
    USER_CHECK_FAIL_ACTION,
    USER_CHECK_SUCCESS_ACTION,
    BOOKING_LOGIN_ACTION,
    BOOKING_LOGIN_SUCCESS_ACTION,
    BOOKING_RESEND_OTP_ACTION,
    BOOKING_RESEND_OTP_SUCCESS_ACTION,
    BOOKING_RESEND_OTP_FAIL_ACTION,
    UPDATE_PROFILE_ACTION,
    UPDATE_PROFILE_SUCCESS_ACTION,
    UPDATE_PROFILE_FAIL_ACTION,
    REGULAR_ORDER_RQST_ACTION,
    REGULAR_ORDER_RQST_SUCCESS_ACTION,
    REGULAR_ORDER_RQST_ERROR_ACTION,
    FETCH_SPECIAL_PKG_INIT_ACTION,
    FETCH_SPECIAL_PACKAGE_SUCCESS_ACTION,
    FETCH_SPECIAL_PACKAGE_FAIL_ACTION,
    FETCH_HOURLY_PACKG_ACTION,
    FETCH_HOURLY_PACKAGE_SUCCESS_ACTION,
    FETCH_HOURLY_PACKAGE_FAIL_ACTION,
    PACKAGE_NEARBY_DRIVER_FAIL_ACTION,
    PACKAGE_NEARBY_DRIVER_ACTION,
    PACKAGE_NEARBY_DRIVER_SUCCESS_ACTION,
    PACKAGE_ORDER_INIT_ACTION,
    PACKAGE_ORDER_SUCCESS_ACTION,
    PACKAGE_ORDER_FAIL_ACTION,
    QUICK_RIDE_ACTION,
    QUICK_RIDE_FAIL_ACTION,
    QUICK_RIDE_SUCCESS_ACTION
} from './action'
import { appConfig } from '../../config'
let { btoa } = require('Base64')

function* userCheck(action) {
    const userData = {
        mobileNo: `+91${action.payload.mobileNo}`
    }
    try {
        const res = yield fetch(`${appConfig.ip}/web/users/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData)
        })
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()
            yield put({
                type: USER_CHECK_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: USER_CHECK_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}

function* login(action) {
    const state = yield select()
    const mobile = state.homeReducer.userExist.mobileNo.substring(3)
    try {
        const res = yield fetch(`${appConfig.ip}/web/auth`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Basic ${btoa(mobile + '@web.rydz.in' + ':' + action.payload)}`
            },
            body: JSON.stringify({ role: 'user' })
        })
        if (!res.ok) {
            toast.error('Invalid OTP. Please try again')
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            toast.success('OTP is validated successfully', {
                autoClose: 3000
            })
            const resJSON = yield res.json()
            yield put({
                type: BOOKING_LOGIN_SUCCESS_ACTION,
                payload: resJSON
            })

            localStorage.setItem('userdata', resJSON.token)
            localStorage.setItem('userId', resJSON.user.id)
        }
    } catch (err) {
        if (err.status === 401) {
            // toast('OTP is validated successfully');
        }
        // if (err.ok === false) {

        //   yield put({
        //     type: TAXIBOOKING_LOGIN_FAIL_ACTION,
        //     error: err
        //   });
        // } else {
        // }
    }
}
function* resendOtp() {
    const state = yield select()
    const mobNo = state.homeReducer.userExist.mobileNo
    const userData = {
        mobileNo: mobNo
    }
    try {
        const res = yield fetch(`${appConfig.ip}/web/users/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData)
        })
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            toast.success('OTP has been resent successfully', {
                autoClose: 3000
            })
            const resJSON = yield res.json()
            yield put({
                type: BOOKING_RESEND_OTP_SUCCESS_ACTION
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: BOOKING_RESEND_OTP_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}

function* profileUpdate(action) {
    const updateData = {
        name: action.payload.name
    }
    const state = yield select()
    const token = yield state.homeReducer.cred.token
    try {
        const res = yield fetch(`${appConfig.ip}/web/users/update`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(updateData)
        })
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()
            yield put({
                type: UPDATE_PROFILE_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: UPDATE_PROFILE_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}

function* orderRqstAction(action) {
    try {
        const values = action.payload
        const rideType = values.rideCat
        if (rideType === 'Regular') {
            const data = {
                placeFrom: values.pickup,
                placeTo: values.dropOf,
                rideType: 'normal',
                rideCat: values.vType
            }
            const state = yield select()
            const token = yield state.homeReducer.cred.token
            const res = yield fetch(`${appConfig.ip}/web/orders/quickNormalRideRqst/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(data)
            })
            if (!res.ok) {
                let errJSON = {}
                try {
                    errJSON = yield res.json()
                } catch { }
                throw Object.assign(res, errJSON)
            } else {
                const resJSON = yield res.json()
                toast.success(`Thank for connecting with us. RYDZ operator will connect you soon.`, {
                    autoClose: 3000
                })
                yield put({
                    type: REGULAR_ORDER_RQST_SUCCESS_ACTION,
                    payload: resJSON
                })
            }
        }
    } catch (err) {
        yield put({
            type: REGULAR_ORDER_RQST_ERROR_ACTION,
            payload: err
        })
    }
}

function* fetchHourlyPkg() {
    try {
        const state = yield select()
        const token = yield state.homeReducer.cred.token
        const res = yield fetch(`${appConfig.ip}/web/ridePkg?isHrlyPkg=true`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()

            yield put({
                type: FETCH_HOURLY_PACKAGE_SUCCESS_ACTION,
                payload: resJSON.rows
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({ type: FETCH_HOURLY_PACKAGE_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* fetchSpecialPkg(action) {
    const placeName = action.payload

    try {
        const state = yield select()
        const token = yield state.homeReducer.cred.token
        const res = yield fetch(
            `${appConfig.ip
            }/web/ridePkg/spclPkg?isHrlyPkg=false&place=${placeName
            }&dist=5000`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()

            yield put({
                type: FETCH_SPECIAL_PACKAGE_SUCCESS_ACTION,
                payload: resJSON.rows
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({ type: FETCH_SPECIAL_PACKAGE_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* pkgNearByDriver(action) {
    const place = action.payload.pickup
    const ridecat = action.payload.vType
    try {
        const state = yield select()
        const token = yield state.homeReducer.cred.token
        if (action.payload.package === 'Special Package') {
            const packageId = action.payload.specialPackage
            const res = yield fetch(
                `${appConfig.ip
                }/web/geoLocs/getNearestDriversOfPackage/${packageId
                }?rideCat=${ridecat
                }&place=${place}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            if (!res.ok) {
                let errJSON = {}
                try {
                    errJSON = yield res.json()
                } catch { }
                throw Object.assign(res, errJSON)
            } else {
                const resJSON = yield res.json()
                yield put({
                    type: PACKAGE_NEARBY_DRIVER_SUCCESS_ACTION,
                    payload: resJSON
                })
            }
        } else if (action.payload.package === 'Hourly Package') {
            const packageId = action.payload.hourlyPackage
            const res = yield fetch(
                `${appConfig.ip
                }/web/geoLocs/getNearestDriversOfPackage/${packageId
                }/?rideCat=${ridecat
                }&place=${place}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            if (!res.ok) {
                let errJSON = {}
                try {
                    errJSON = yield res.json()
                } catch { }
                throw Object.assign(res, errJSON)
            } else {
                const resJSON = yield res.json()

                yield put({
                    type: PACKAGE_NEARBY_DRIVER_SUCCESS_ACTION,
                    payload: resJSON
                })
            }
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({ type: PACKAGE_NEARBY_DRIVER_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* pckgOrder(action) {
    const state = yield select()
    const pickUp = state.homeReducer.pckgPickup.pickup
    const orderdata = {
        accptdBy: action.payload.userId,
        placeFrom: pickUp,
        dProfpkgId: action.payload.package._id,
        reqType: 'rInit',
        reqDate:
            state.homeReducer.pckgPickup.when === 'Schedule for later'
                ? new Date(
                    `${state.homeReducer.pckgPickup.startDate
                        .toISOString()
                        .slice(0, 10)
                    } ${state.homeReducer.pckgPickup.time}`
                )
                : new Date()
    }
    try {
        const state = yield select()
        const token = yield state.homeReducer.cred.token
        const res = yield fetch(`${appConfig.ip}/web/orders/packageRideRqst/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(orderdata)
        })
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()
            toast.success(`Thank for connecting with us.RYDZ operator will connect you soon.`, {
                autoClose: 3000
            })
            yield put({
                type: PACKAGE_ORDER_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: PACKAGE_ORDER_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}

function* quickRideRqst(action) {
    try {
        const driverId = action.payload.data.driver.userId
        const state = yield select()
        const token = yield state.homeReducer.cred.token
        const res = yield fetch(`${appConfig.ip}/web/orders/quickRideRqst/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                placeFrom: action.payload.pickup,
                accptdBy: driverId,
                reqType: 'rInit'
            })
        })
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()
            // eslint-disable-next-line no-underscore-dangle
            toast.success(`Thank for connecting with us. RYDZ operator will connect you soon.`, {
                autoClose: 3000
            })
            yield put({
                type: QUICK_RIDE_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: QUICK_RIDE_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}


export function* home() {
    yield takeEvery(USER_CHECK_INIT_ACTION, userCheck)
    yield takeEvery(BOOKING_LOGIN_ACTION, login)
    yield takeEvery(BOOKING_RESEND_OTP_ACTION, resendOtp)
    yield takeEvery(UPDATE_PROFILE_ACTION, profileUpdate)
    yield takeEvery(REGULAR_ORDER_RQST_ACTION, orderRqstAction)
    yield takeEvery(FETCH_SPECIAL_PKG_INIT_ACTION, fetchSpecialPkg)
    yield takeEvery(FETCH_HOURLY_PACKG_ACTION, fetchHourlyPkg)
    yield takeEvery(PACKAGE_NEARBY_DRIVER_ACTION, pkgNearByDriver)
    yield takeEvery(PACKAGE_ORDER_INIT_ACTION, pckgOrder)
    yield takeEvery(QUICK_RIDE_ACTION, quickRideRqst)
}