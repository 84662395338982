import { call, all } from 'redux-saga/effects';

import { loginActionWatcher } from '../containers/LoginContainer/saga';

import { dashboard } from '../containers/DashboardContainer/saga';
import { profile } from '../containers/ProfileContainer/saga';
import { request } from '../containers/RequestContainer/saga';
import { cabs } from '../containers/CabsContainer/saga';
import { auto } from '../containers/AutoContainer/saga';
import { home } from '../containers/HomeContainer/saga';
import { forgotPswdAction } from '../containers/ForgotPswdContainer/saga';
import { registerOTP } from '../containers/RegisterOTPContainer/saga';
import { sendEnquiryWatcher } from '../containers/SendEnquiryContainer/saga';
import { signUp } from '../containers/SignUpContainer/saga'

function* rootSaga() {
  yield all([
    call(loginActionWatcher),
    call(dashboard),
    call(profile),
    call(request),
    call(cabs),
    call(auto),
    call(home),
    call(forgotPswdAction),
    call(registerOTP),
    call(sendEnquiryWatcher),
    call(signUp)
  ]);
}

export default rootSaga;
