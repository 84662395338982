
import { put, takeEvery } from 'redux-saga/effects'
// import { createBrowserHistory } from 'history'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import {
    FETCH_ATTACHED_CAR_DRIVER_SUCCESS_ACTION,
    FETCH_ATTACHED_CAR_DRIVER_FAIL_ACTION,
    FETCH_ATTACHED_CAR_DRIVER_INIT_ACTION,
    LOC_AUTOCOMPLETE_INIT_ACTION,
    LOC_AUTOCOMPLETE_SUCCESS_ACTION,
    FETCH_NEARBY_DRIVER_ACTION,
    FETCH_NEARBY_DRIVER_FAIL_ACTION,
    FETCH_NEARBY_DRIVER_SUCCESS_ACTION,
    SEARCH_USER_INIT_ACTION,
    SEARCH_USER_SUCCESS_ACTION,
    SEARCH_USER_FAIL_ACTION,
    FETCH_DRIVER_INIT_ACTION,
    FETCH_DRIVER_FAIL_ACTION,
    FETCH_DRIVER_SUCCESS_ACTION,
    REGULAR_RQST_INIT_ACTION,
    REGULAR_RQST_FAIL_ACTION,
    REGULAR_RQST_SUCCESS_ACTION,
    FETCH_SPECIAL_PACKAGE_INIT_ACTION,
    FETCH_HOURLY_PACKAGE_INIT_ACTION,
    FETCH_HOURLY_PACKAGE_SUCCESS_ACTION,
    FETCH_HOURLY_PACKAGE_FAIL_ACTION,
    FETCH_SPECIAL_PACKAGE_FAIL_ACTION,
    FETCH_SPECIAL_PACKAGE_SUCCESS_ACTION,
    PACKAGE_REQUEST_INIT_ACTION,
    PACKAGE_REQUEST_SUCCESS_ACTION,
    PACKAGE_REQUEST_FAIL_ACTION,
} from './actions'

import 'react-toastify/dist/ReactToastify.css'
import { appConfig } from '../../config'

// var btoa = require('Base64').btoa
// var history = createBrowserHistory()

function* fetchAttachedDrivers() {
    try {
        const token = localStorage.getItem('userdata')
        const res = yield fetch(
            `${appConfig.ip}/web/geoLocs/getNearestAttachedDrivers?rideCat=car`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()

            yield put({
                type: FETCH_ATTACHED_CAR_DRIVER_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({ type: FETCH_ATTACHED_CAR_DRIVER_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* locAutoCompete(action) {
    try {
        const token = localStorage.getItem('userdata')
        if (action.payload.value.length >= 4) {
            const res = yield fetch(
                `${appConfig.ip}/web/placeSuggest/${action.payload.value}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                }
            )

            if (!res.ok) {
                let errJSON = {}
                try {
                    errJSON = yield res.json()
                } catch { }
                throw Object.assign(res, errJSON)
            } else {
                const resJSON = yield res.json()
                yield put({
                    type: LOC_AUTOCOMPLETE_SUCCESS_ACTION,
                    payload: resJSON.predictions
                })
                if (action.payload.type === 'carPickup') {
                    // yield put({
                    //     type: FETCH_GOOGLE_AUTOCOMPLETE_FLEET_CAR_PICKUP_SUCCESS_ACTION,
                    //     payload: resJSON.predictions
                    // })
                }
                if (action.payload.type === 'carDropOf') {
                    // yield put({
                    //     type: FETCH_GOOGLE_AUTOCOMPLETE_FLEET_CAR_DROPOF_SUCCESS_ACTION,
                    //     payload: resJSON.predictions
                    // })
                }
                if (action.payload.type === 'nearbyDriverfleetAutocomplete') {
                    yield put({
                        type: LOC_AUTOCOMPLETE_SUCCESS_ACTION,
                        payload: resJSON.predictions
                    })
                }
                if (action.payload.type === 'Autopickup') {
                    // yield put({
                    //     type: FETCH_GOOGLE_AUTOCOMPLETE_FLEET_AUTO_PICKUP_SUCCESS_ACTION,
                    //     payload: resJSON.predictions
                    // })
                }
            }
        }
    } catch (err) { }
}

function* fetchNearByDriver(action) {
    //   const placevalue = value.value
    try {
        const token = localStorage.getItem('userdata')
        const res = yield fetch(
            // `${appConfig.ip}/web/geoLocs/getNearestAttachedDriversOfPlace?place=${placevalue}&&dist=5000&rideCat=car`,
            `${appConfig.ip}/web/geoLocs/getNearestDriversOfPlace?place=${action.payload.place}&&dist=5000&&rideCat=${action.payload.rideCat}&limit=10&page=${action.payload.page}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()
            yield put({
                type: FETCH_NEARBY_DRIVER_SUCCESS_ACTION,
                payload: resJSON
            })

        }
    } catch (err) {

        if (err.ok === false) {
            yield put({
                type: FETCH_NEARBY_DRIVER_FAIL_ACTION,
                error: err
            })
        } else {
            // yield put({type: CHECK_INTERNET_ACTION, que: true, payload: action});
        }
    }
}

function* serachUser(action) {
    const { user } = action.payload
    // const { vType } = value.value
    try {
        const token = localStorage.getItem('userdata')
        const res = yield fetch(
            // `${appConfig.ip}/web/users/searchAttachedCarDriver?ser=${user}`,
            `${appConfig.ip}/web/users/searchCarDriver?ser=${user}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()

            yield put({
                type: SEARCH_USER_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({ type: SEARCH_USER_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* fetchDriver(action) {
    try {
        const userId = action.payload
        const token = localStorage.getItem('userdata')

        const res = yield fetch(
            `${appConfig.ip}/web/geoLocs/getDriverbyId/${userId}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()

            yield put({
                type: FETCH_DRIVER_SUCCESS_ACTION,
                payload: resJSON
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({ type: FETCH_DRIVER_FAIL_ACTION, error: err })
        } else {
        }
    }
}

function* regularRqst(action) {
    // const taxiValue = {
    //     rName: tValue.tValue.name,
    //     rPhone: `+91${tValue.tValue.mobileno}`,
    //     rideType: tValue.tValue.rideCat === 'Regular' ? 'normal' : 'quick',
    //     placeFrom: tValue.tValue.pickup,
    //     placeTo: tValue.tValue.dropOf,
    //     rideCat: tValue.tValue.vType,
    //     accptdBy: tValue.tValue.accptdBy,
    //     reqType: 'agentInit'
    //   }

    //   try {
    //     const token = localStorage.getItem('userdata')

    //     const res = yield fetch(`${appConfig.ip}/web/orders/agentRideRqst/`, {
    //       method: 'POST',
    //       headers: {
    //         Accept: 'application/json',
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${token}`
    //       },
    //       body: JSON.stringify(taxiValue)
    //     })

    const taxiValue = {
        rName: action.payload.name,
        rPhone: `+91${action.payload.mobileno}`,
        rideType: action.payload.rideCat === 'Regular' ? 'normal' : 'quick',
        reqType: 'agentInit',
        placeFrom: action.payload.pickup,
        placeTo: action.payload.dropOf,
        rideCat: action.payload.vType,
        accptdBy: action.payload.accptdBy
        // reqLoc: [11.5574563, 75.7605859],
        // reqFrom: [11.5574563, 75.7605859],
        // reqTo: [11.5704, 75.7536]
    }

    try {
        const token = localStorage.getItem('userdata')

        const res = yield fetch(`${appConfig.ip}/web/orders/agentRideRqst/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(taxiValue)
        })

        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            // toast.success("Booking  successfully");
            yield toast.success('Successfully booked', {
                autoClose: 2000
            })
            const resJSON = yield res.json()
            yield put({
                type: REGULAR_RQST_SUCCESS_ACTION,
                payload: resJSON
            })
            yield put(push("/request"))
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: REGULAR_RQST_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}

function* fetchHourlyPackage(action) {
    try {
        const driverId = action.payload
        const token = localStorage.getItem('userdata')

        const res = yield fetch(
            `${appConfig.ip}/web/dProfPkg/drvGet/${driverId}?isHrlyPkg=true`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )

        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()


            yield put({
                type: FETCH_HOURLY_PACKAGE_SUCCESS_ACTION,
                payload: resJSON.rows
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: FETCH_HOURLY_PACKAGE_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}
function* fetchSpecialPackage(action) {
    try {
        const driverId = action.payload
        const token = localStorage.getItem('userdata')


        const res = yield fetch(
            `${appConfig.ip}/web/dProfPkg/drvGet/${driverId}?isHrlyPkg=false`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )

        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch { }
            throw Object.assign(res, errJSON)
        } else {
            const resJSON = yield res.json()


            yield put({
                type: FETCH_SPECIAL_PACKAGE_SUCCESS_ACTION,
                payload: resJSON.rows
            })
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: FETCH_SPECIAL_PACKAGE_FAIL_ACTION,
                error: err
            })
        } else {
        }
    }
}
function* packageRqst(action) {

    try {
        const token = localStorage.getItem('userdata')
        const res = yield fetch(`${appConfig.ip}/web/orders/fleetPackageRideRqst`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(action.payload)
        })

        if (!res.ok) {
            let errJSON = {}
            try {
                errJSON = yield res.json()
            } catch {
                throw Object.assign(res, errJSON)
            }
        } else {
            yield toast.success('Successfully booked', {
                autoClose: 2000
            })
            const resJSON = yield res.json()
            yield put({
                type: PACKAGE_REQUEST_SUCCESS_ACTION,
                payload: resJSON
            })
            yield put(push("/request"))
        }
    } catch (err) {
        if (err.ok === false) {
            yield put({
                type: PACKAGE_REQUEST_FAIL_ACTION,
                error: err
            })
        }
    }
}


export function* cabs() {
    yield takeEvery(FETCH_ATTACHED_CAR_DRIVER_INIT_ACTION, fetchAttachedDrivers)
    yield takeEvery(LOC_AUTOCOMPLETE_INIT_ACTION, locAutoCompete)
    yield takeEvery(FETCH_NEARBY_DRIVER_ACTION, fetchNearByDriver)
    yield takeEvery(SEARCH_USER_INIT_ACTION, serachUser)
    yield takeEvery(FETCH_DRIVER_INIT_ACTION, fetchDriver)
    yield takeEvery(REGULAR_RQST_INIT_ACTION, regularRqst)
    yield takeEvery(FETCH_HOURLY_PACKAGE_INIT_ACTION, fetchHourlyPackage)
    yield takeEvery(FETCH_SPECIAL_PACKAGE_INIT_ACTION, fetchSpecialPackage)
    yield takeEvery(PACKAGE_REQUEST_INIT_ACTION, packageRqst)
}
