/* eslint-disable class-methods-use-this */
import React from 'react'
import { Component } from 'react'
import Navbar2 from '../../components/Navbar'
import { styles } from './style.js'
import {
  Card,
  Button,
  CardDeck
} from 'react-bootstrap'
import {
  Row,
  Col,
} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import './styles.css'
import Footer from '../../components/Footer'

import TaxiBooking from '../BookNow'
// import { Link, Route } from 'react-router-dom'

class Home extends Component {
  constructor() {
    super()
    this.state = {
      show: false,
      setShow: false,
      var_Driver: 1,
      var_rider: 1,
      active: 0,
      sections: []
    }
    this.scrollDiv = React.createRef()
    this.scrollDiv2 = React.createRef()
    this.handleClose = this.handleClose.bind(this)
    this.handleShow = this.handleShow.bind(this)
  }
  componentDidMount() {
    this.ids = setInterval(
      function () {
        this.submit()
      }.bind(this),
      3000
    )
    this.idstwo = setInterval(
      function () {
        this.submitrider()
      }.bind(this),
      3000
    )
  }
  componentWillUnmount() {
    clearInterval(this.ids)
    clearInterval(this.idstwo)
  }
  submit = () => {
    for (let i = 0; i <= 5; i++) {
      let value = this.state.var_Driver === 5 ? 1 : this.state.var_Driver + 1
      this.setState({
        var_Driver: value
      })
    }
  }

  handleClose() {
    this.setState({
      show: false
    })
  }

  handleShow() {
    this.setState({
      show: true
    })
  }
  submitrider = () => {
    for (let i = 0; i <= 4; i++) {
      let value = this.state.var_rider === 4 ? 1 : this.state.var_rider + 1
      this.setState({
        var_rider: value
      })
    }
  }

  render() {
    return (
      <div className="maoinpagefullres">
        <Navbar2 />

        <div className="main_img">
          <Container>
            <Row className="mainpageres">
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="">
                  {this.state.var_rider === 1 && (
                    <div>
                      <div className="">
                        <Image
                          className="finddrivermainpageimage"
                          src={require('../../assets/img/riderhomeneardvr.png')}
                          alt="First slide"
                        />
                      </div>

                      <div className="">
                        <h2 className="mainpagehomefrsthdng">
                          Find nearby drivers
                        </h2>
                        <p className="mainpagehomefrsthpara">
                          Search to find nearby drivers in your locality
                        </p>
                      </div>
                    </div>
                  )}
                  {this.state.var_rider === 2 && (
                    <div>
                      <div className="">
                        <Image
                          className="finddrivermainpageimage"
                          src={require('../../assets/img/rydzhomerequestrdr.png')}
                          alt="second slide"
                        />
                      </div>

                      <div className="">
                        <h2 className="mainpagehomefrsthdng">
                          Request to a ride
                        </h2>
                        <p className="mainpagehomefrsthpara">
                          Send your request to list of drivers available in the
                          area.
                        </p>
                      </div>
                    </div>
                  )}
                  {this.state.var_rider === 3 && (
                    <div>
                      <div className="">
                        <Image
                          className="finddrivermainpageimage"
                          src={require('../../assets/img/rydzhomeontriprdr.png')}
                          alt="Third slide"
                        />
                      </div>

                      <div className="">
                        <h2 className="mainpagehomefrsthdng">
                          Start your trip
                        </h2>
                        <p className="mainpagehomefrsthpara">
                          Start your trip and travel to the destination.
                        </p>
                      </div>
                    </div>
                  )}
                  {this.state.var_rider === 4 && (
                    <div>
                      <div className="">
                        <Image
                          className="finddrivermainpageimage"
                          src={require('../../assets/img/rydzhomeridrcmpltrd.png')}
                          alt="Four slide"
                        />
                      </div>

                      <div className="">
                        <h2 className="mainpagehomefrsthdng">Make payment</h2>
                        <p className="mainpagehomefrsthpara">
                          Make payment for the travel done by you.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} className="mainalignhddriver">
                <h3 className="mainpagehomedesc">
                  Open Platform For Connecting <br />
                  Drivers Any Time, Any Where <br />
                </h3>
                <p className="mainriderpara">Book a taxi/auto online</p>
                <Button
                  type="submit"
                  className="booknowbtn"
                  onClick={this.handleShow}>
                  Book Now
                </Button>
                <TaxiBooking
                  show={this.state.show}
                  handleClose={this.handleClose}
                  screen={this.props.screen}
                  userCheck={this.props.userCheck}
                  login={this.props.login}
                  resendOtp={this.props.resendOtp}
                  screenChange={this.props.screenChange}
                  profileUpade={this.props.profileUpade}
                  fetchActiveRequest={this.props.fetchActiveRequest}
                  activeRqst={this.props.activeRqst}
                  fetchRqstHistory={this.props.fetchRqstHistory}
                  rqstHistory={this.props.rqstHistory}
                  cancelRqst={this.props.cancelRqst}
                  locationFetch={this.props.locationFetch}
                  locPrediction={this.props.locPrediction}
                  regularRqst={this.props.regularRqst}
                  fetchSpecialPkg={this.props.fetchSpecialPkg}
                  specailPkgList={this.props.specailPkgList}
                  fetchHourlyPkg={this.props.fetchHourlyPkg}
                  hourlyPkgList={this.props.hourlyPkgList}
                  pkgNearByDriver={this.props.pkgNearByDriver}
                  pkgDriverList={this.props.pkgDriverList}
                  pckgOrder={this.props.pckgOrder}
                  fetchNearByDriver={this.props.fetchNearByDriver}
                  nearbyDrvrList={this.props.nearbyDrvrList}
                  quickRideRqst={this.props.quickRideRqst}
                  showLogout={this.props.showLogout}
                  logoutAction={this.props.logoutAction}



                  userExist={this.props.userExist}
                  checkMobileNoSection={this.props.checkMobileNoSection}
                  otpSection={this.props.otpSection}
                  selectVtypeSection={this.props.selectVtypeSection}
                  regularButtonAction={this.props.regularButtonAction}




                  packageReqst={this.props.packageReqst}
                  driverlist={this.props.driverlist}

                  userdetail={this.props.userdetail}
                  // locationFetch={this.props.locationFetch}
                  // locationPrediction={this.props.locationPrediction}
                  // DropOfflocationPrediction={
                  //   this.props.DropOfflocationPrediction
                  // }
                  // locationFetchDrop={this.props.locationFetchDrop}
                  scheduldPickupPrediction={this.props.scheduldPickupPrediction}
                  scheduldDropPrediction={this.props.scheduldDropPrediction}
                  packagePickupPrediction={this.props.packagePickupPrediction}
                  packageDropPrediction={this.props.packageDropPrediction}
                  regularBooking={this.props.regularBooking}
                  regulrSchedlueRqst={this.props.regulrSchedlueRqst}


                // orderRqst={this.props.orderRqst}
                // fetchOrderStatus={this.props.fetchOrderStatus}
                //weborderStatus={this.props.weborderStatus}
                // cancelReqst={this.props.cancelReqst}

                //fetchHourlyPackage={this.props.fetchHourlyPackage}
                // hourlypackageList={this.props.hourlypackageList}
                // fetchSpecialPackage={this.props.fetchSpecialPackage}
                // specialPackageList={this.props.specialPackageList}
                //packageRqst={this.props.packageRqst}
                // packageDriversList={this.props.packageDriversList}
                // pckgOrder={this.props.pckgOrder}
                //requestHistory={this.props.requestHistory}
                // orderHistory={this.props.orderHistory}
                />
                <div className="rydzfrntpagesection">
                  <Row>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <Image
                        className="rydzfrntpagelogo"
                        src={require('../../assets/img/rydzfrntpagelogo.png')}
                      />
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <Image
                        className="rydzqrcodefirst"
                        src={require('../../assets/img/rydzridernewqrcode.png')}
                      />
                      <div>
                        <a
                          onClick={() => window.scrollTo(0, 0)}
                          href="https://play.google.com/store/apps/details?id=com.frugal.rydzrider">
                          {' '}
                          <Image
                            className="downloadplayimage"
                            src={require('../../assets/img/rydzplaystoreimage.png')}
                          />
                        </a>
                      </div>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4}>
                      <Image
                        className="rydzqrcodefirst"
                        src={require('../../assets/img/rydzridernewqrcode.png')}
                      />
                      <div>
                        <a
                          onClick={() => window.scrollTo(0, 0)}
                          // href="https://play.google.com/store/apps/details?id=com.frugal.rydzrider">
                          href="https://apps.apple.com/us/app/rydz-taxi/id1515691114">
                          {' '}
                          <Image
                            className="downloadappsimage"
                            src={require('../../assets/img/rydzapplestoreimage.png')}
                          />
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ========================================================================================= */}
        <div className="howitworkimage" id="howitworks">
          <div className="howitworkhd">
            <h3 className="howitworkheading">How it works</h3>
          </div>
          <Container>
            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div>
                  <div className="bookaridesection">
                    <div className="bookaridesectionicon">
                      <i className="fa fa-car"></i>
                    </div>
                  </div>
                  <h3 className="bookaridesectionhd">Book a ride</h3>
                  <p className="bookaridesectionpera">
                    Simplest way of booking a ride at single click of a button.
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div>
                  <div className="bookaridesection">
                    <div className="bookaridesectionicon">
                      <i className="fa fa-user"></i>
                    </div>
                  </div>
                  <h3 className="bookaridesectionhd">Find a Driver</h3>
                  <p className="bookaridesectionpera">
                    Rydz platform will works for you to find a Driver near to
                    you.
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div>
                  <div className="bookaridesection">
                    <div className="bookaridesectionicon">
                      <i className="fa fa-paper-plane"></i>
                    </div>
                  </div>
                  <h3 className="bookaridesectionhd">Track your Cab</h3>
                  <p className="bookaridesectionpera">
                    Track the arrival of your Cab.
                  </p>
                </div>
              </Col>
            </Row>

            {/* ================================================================================= */}
            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div>
                  <div className="bookaridesection">
                    <div className="bookaridesectionicon">
                      <i className="fa fa-grav"></i>
                    </div>
                  </div>
                  <h3 className="bookaridesectionhd">Have a Safe Ride</h3>
                  <p className="bookaridesectionpera">
                    Reach your destination with a comfortable ride .
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div>
                  <div className="bookaridesection">
                    <div className="bookaridesectionicon">
                      <i className="fa fa-money"></i>
                    </div>
                  </div>
                  <h3 className="bookaridesectionhd">Make payment</h3>
                  <p className="bookaridesectionpera">
                    Make payment to the Driver according to the Tariff.
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div>
                  <div className="bookaridesection">
                    <div className="bookaridesectionicon">
                      <i className="fa fa-comment"></i>
                    </div>
                  </div>
                  <h3 className="bookaridesectionhd">Rate your Driver</h3>
                  <p className="bookaridesectionpera">
                    Have a 5 star rating for your best Driver.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ========================================================================================= */}

        <div>
          <div className="technologymain" style={styles.mainpgcolor}>
            <div>
              <h2 className="technology_head">
                Technology solution for the future{' '}
              </h2>
              <p className="technology_pera">
                'AI' based API driven mobile technology platform
              </p>
            </div>

            <div className="tech_platform">
              <Container>
                <Row>
                  <Col xs={12} sm={12} md={4} lg={4}>
                    <h4 className="techno">Realtime Booking</h4>
                    <p className="mobiletech">
                      Riders can either directly request for a ride from nearby
                      driver or place the request with destination and wait for
                      a driver acceptance. Driver will call you to confirm the
                      order or you can call the driver.
                    </p>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4}>
                    <h4 className="techno">Virtual Taxi Stand</h4>
                    <p className="mobiletech">
                      Riders see nearby virtual taxi and auto stand in real time
                      with rate card. You choose best suitable vehicle you like.
                    </p>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={4}>
                    <h4 className="techno">Open Platform</h4>
                    <p className="mobiletech">
                      Riders see rate card and trip estimate once a driver
                      accepted an order. Rydz drivers offers regular price which
                      is regulated by local city/municipality rules. There will
                      be no surge pricing. Rydz charge no commission. All taxi
                      fare goes to drivers.
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>

        <div>
          <div className="finddriver" style={styles.mainpgcolor}>
            <div className="finddrivercard">
              <Container>
                <CardDeck>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={4}>
                          <Card className="mainpagedrvrcard">
                            <Card.Img
                              variant="top"
                              src={require('../../assets/img/rydz_nearnew.jpg')}
                            />
                            <Card.Body className="maincardbody">
                              <Card.Title className="maincardtitle">
                                <h2 className="mainpagecardhd">
                                  Find a driver near to you and book a ride
                                </h2>
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4}>
                          <Card className="mainpagedrvrcard">
                            <Card.Img
                              variant="top"
                              src={require('../../assets/img/seatbeltsafedrive new.jpg')}
                            />
                            <Card.Body className="maincardbody">
                              <Card.Title className="maincardtitle">
                                <h2 className="mainpagecardhd">
                                  Get picked up at a nearby corner and have a
                                  safe ride
                                </h2>
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4}>
                          <Card className="mainpagedrvrcard">
                            <Card.Img
                              variant="top"
                              src={require('../../assets/img/rydzpayment new.jpg')}
                            />
                            <Card.Body className="maincardbody">
                              <Card.Title className="maincardtitle">
                                <h2 className="mainpagecardhd">
                                  Make payment and rate your driver
                                </h2>
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardDeck>
              </Container>
            </div>
          </div>
        </div>

        <div ref={this.scrollDiv} id="driver">
          <div className="mainpagedrivermain">
            <Container>
              <div>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={7}>
                    <Row>
                      <Col xs={12} sm={12} md={10} lg={10}>
                        <div className="">
                          {this.state.var_Driver === 1 && (
                            <div>
                              <Row>
                                <Col xs={12} sm={12} md={12} lg={7}>
                                  <div className="mainpagedriverfirst">
                                    <h2 className="mainpagedriverhd">
                                      Accept ride request
                                    </h2>
                                    <p className="mainpagedriverpera">
                                      Accept the ride request rings on your
                                      mobile
                                    </p>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={5}>
                                  <div className="">
                                    <Image
                                      className="mainpagedriverimage"
                                      src={require('../../assets/img/rydzhomeacceptrdr.png')}
                                      alt="First slide"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                          {this.state.var_Driver === 2 && (
                            <div>
                              <Row>
                                <Col xs={12} sm={12} md={12} lg={7}>
                                  <div className="mainpagedriverfirst">
                                    <h2 className="mainpagedriverhd">
                                      Pick up your rider
                                    </h2>
                                    <p className="mainpagedriverpera">
                                      Start pickup when you receive the ride
                                      request.
                                    </p>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={5}>
                                  <div className="">
                                    <Image
                                      className="mainpagedriverimage"
                                      src={require('../../assets/img/rydzhomepickuprdr.png')}
                                      alt="second slide"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                          {this.state.var_Driver === 3 && (
                            <div>
                              <Row>
                                <Col xs={12} sm={12} md={12} lg={7}>
                                  <div className="mainpagedriverfirst">
                                    <h2 className="mainpagedriverhd">
                                      Start your trip
                                    </h2>
                                    <p className="mainpagedriverpera">
                                      Start your trip and travel to the
                                      destination .
                                    </p>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={5}>
                                  <div className="">
                                    <Image
                                      className="mainpagedriverimage"
                                      src={require('../../assets/img/rydzhomestarttrip.png')}
                                      alt="Third slide"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                          {this.state.var_Driver === 4 && (
                            <div>
                              <Row>
                                <Col xs={12} sm={12} md={12} lg={7}>
                                  <div className="mainpagedriverfirst">
                                    <h2 className="mainpagedriverhd">
                                      Complete your trip
                                    </h2>
                                    <p className="mainpagedriverpera">
                                      End the trip and reach the destination on
                                      time.
                                    </p>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={5}>
                                  <div className="">
                                    <Image
                                      className="mainpagedriverimage"
                                      src={require('../../assets/img/rydzhomeendtrip.png')}
                                      alt="Four slide"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                          {this.state.var_Driver === 5 && (
                            <div>
                              <Row>
                                <Col xs={12} sm={12} md={12} lg={7}>
                                  <div className="mainpagedriverfirst">
                                    <h2 className="mainpagedriverhd">
                                      Accept payment
                                    </h2>
                                    <p className="mainpagedriverpera">
                                      Accept online and mobile payments securely
                                      after completing the trips.
                                    </p>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={5}>
                                  <div className="">
                                    <Image
                                      className="mainpagedriverimage"
                                      src={require('../../assets/img/rydzhomeridecomptdvr.png')}
                                      alt="Four slide"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={5}>
                    <div className="mainpagesechd">
                      Easy and quick connect with <br />
                      Riders near to you <br />
                      <p className="mainpagesecpara">Connecting Rides</p>
                      <div className="rydzfrntpagesection">
                        <Row>
                          <Col xs={4} sm={4} md={4} lg={4}>
                            <Image
                              className="rydzfrntpagelogo"
                              src={require('../../assets/img/rydzdriverlogofst.png')}
                            />
                          </Col>
                          <Col xs={4} sm={4} md={4} lg={4}>
                            <Image
                              className="rydzqrcodefirst"
                              src={require('../../assets/img/rydzdriverqrcodefirst.png')}
                            />
                            <div>
                              <a
                                onClick={() => window.scrollTo(0, 0)}
                                href="https://play.google.com/store/apps/details?id=com.frugal.rydzdriver">
                                {' '}
                                <Image
                                  className="downloadappsdriverimage"
                                  src={require('../../assets/img/rydzplaystoreimage.png')}
                                />
                              </a>
                            </div>
                          </Col>
                          <Col xs={4} sm={4} md={4} lg={4}>
                            <Image
                              className="rydzqrcodefirst"
                              src={require('../../assets/img/rydzdriversecondimage.png')}
                            />
                            <div>
                              <a
                                onClick={() => window.scrollTo(0, 0)}
                                // href="https://play.google.com/store/apps/details?id=com.frugal.rydzdriver">
                                href="https://apps.apple.com/us/app/rydz-driver-app/id1515691389">
                                {' '}
                                <Image
                                  className="downloadappsdriverimage"
                                  src={require('../../assets/img/rydzapplestoreimage.png')}
                                />
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="scrollToTop">
              <Button
                variant="light"
                className="scrollButton"
                onClick={() => window.scrollTo(0, 0)}>
                <i className="fa fa-angle-double-up" aria-hidden="true"></i>
              </Button>
            </div>
          </div>
        </div>

        {/* ========================================How are we different?========================================= */}
        <div style={styles.mainpgcolor}>
          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div>
                  <h3 className="cardhdmainpg">How are we different?</h3>
                </div>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={3}>
                    <div className="">
                      <i className="fa fa-user fa-2x mainpagehowicon"></i>
                      <h5 className="cardtitldrivermainpg">OPEN PLATFORM</h5>
                      <p className="cardparadrivermainpg">
                        Drivers can offer competitive pricing instead of a
                        corporate company dictating the price.
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={3}>
                    <div className="">
                      <i className="fa fa-user fa-2x mainpagehowicon"></i>
                      <h5 className="cardtitldrivermainpg">
                        NO COMMISSION MODEL
                      </h5>
                      <p className="cardparadrivermainpg">
                        Rydz don't charge commission for any trips. Drivers pay
                        a nominal subscription fee only for the usage.
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={3}>
                    <div className="">
                      <i className="fa fa-user fa-2x mainpagehowicon"></i>
                      <h5 className="cardtitldrivermainpg">
                        DIRECT PAYMENT BY RIDERS
                      </h5>
                      <p className="cardparadrivermainpg">
                        Riders pay drivers directly for the trip they made. Rydz
                        don't manage your money.
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={3}>
                    <div className="">
                      <i className="fa fa-user fa-2x mainpagehowicon"></i>
                      <h5 className="cardtitldrivermainpg">
                        SIMPLE APP TO USE
                      </h5>
                      <p className="cardparadrivermainpg">
                        Rydz follows "simple solution for complicated problems"
                        strategy with a vision to be used by common man.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ========================================rider========================================= */}

        <div className="footer">
          <Footer />
        </div>
        <div className="footer12"></div>
      </div>
    )
  }
}

export default Home
