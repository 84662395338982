import React, { Component } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Form, Row, Col, Image, Button, Carousel, Container } from 'react-bootstrap'

import './styles.css'
import Navbar2 from '../../components/Navbar'
import Footer from '../../components/Footer';


import { styles } from './style.js'



const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
)
const validateForm = errors => {
  let valid = true
  Object.values(errors).forEach(val => val.length > 0 && (valid = false))
  return valid
}
const validphoneRegex = RegExp(/^\d{10}$/)
// const validateForms = errors => {
//   let valid = true
//   Object.values(errors).forEach(val => val.length > 0 && (valid = false))
//   return valid
// }
class Contact extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // showError: false,
      value: {
        name: '',
        mobileNo: '',
        email: '',
        message: '',
        type: 'Select'
      },
      errors: {
        name: '',
        mobileNo: '',
        email: '',
        message: '',
        type: ''
      }
    }
    this.onChangeAbout = this.onChangeAbout.bind(this)
    this.submit = this.submit.bind(this)
  }
  onChangeAbout(values) {

    const { value, name } = values.target
    this.setState({
      value: { ...this.state.value, [name]: value }

      // showError: false
    })

    let errors = this.state.errors

    switch (name) {
      case 'name':
        errors.name = value.length < 3 ? 'Name must be 3 characters long' : ''
        break
      case 'mobileNo':
        errors.mobileNo = validphoneRegex.test(value)
          ? ''
          : 'Mobile number is not valid'
        break
      case 'email':
        errors.email = validEmailRegex.test(value) ? '' : 'Email is not valid'
        break
      case 'message':
        errors.message = value.length < 3 ? 'Message is required' : ''
        break
      case 'type':
        errors.type = value.length < 3 ? 'Type is required!' : ''
        break

      default:
        break
    }
    this.setState({ errors, [name]: value })
  }
  submit(event) {
    event.preventDefault()
    if (
      validateForm(this.state.errors) &&
      this.state.name !== undefined &&
      this.state.mobileNo !== undefined &&
      this.state.email !== undefined &&
      this.state.message !== undefined &&
      this.state.type !== undefined
    ) {

      this.setState({
        value: {
          name: '',

          mobileNo: '',
          email: '',
          message: '',
          type: 'Select'
        }
      })

      let data = this.state.value

      this.props.contact(data)

      this.setState({ name: undefined })
      this.setState({ mobileNo: undefined })
      this.setState({ email: undefined })
      this.setState({ message: undefined })
      this.setState({ type: undefined })
    } else {

      toast.error('Please enter valid datas', {
        autoClose: 2000
      })
    }


  }

  render() {
    const { errors } = this.state
    let value = this.state.value
    return (
      <div className="aboutpagefullres">
        <Navbar2 />
        <div className="aboutmain" style={styles.abutpag}>
          <div className="submain">
            <Container>
              <div className="subdiv">
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className="contactusdetails">
                      <h1 className="flussohd">Flusso Solutions Pvt Ltd</h1>
                      <p className="flussopara">
                        The flagship platform product ‘Rydz’ is operated by
                        Flusso Solutions Pvt Ltd, an unlisted private company,
                        incorporated on 16 August, 2018. Rydz platform is
                        designed and developed in partnership with Frugal
                        Scientific Private Limited.
                      </p>

                      <h1 className="flussohd">Registered Office</h1>
                      <p className="flussoparareg">
                        No:1006 , Plot No . 8 Sector 11 , Sanpada , NAVI MUMBAI
                        , Maharashtra - 400 705.
                      </p>
                      <h1 className="flussohd">Contact Us</h1>
                      <p className="flussopara">Support: +91 9324962887</p>
                      <p className="flussopara">Email: support@rydz.in</p>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Row>
                      <Col xs={12} sm={12} md={2} lg={2}></Col>

                      <Col
                        xs={12}
                        sm={9}
                        md={12}
                        lg={8}
                        className="contactusform"
                        style={styles.nav}>
                        <div>
                          <h1 className="contactusformhead">Quick Connect</h1>
                          <div className="contactusformlabel">
                            <form>
                              <Form.Group controlId="pickupat">
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  as="select"
                                  name="type"
                                  value={value.type}
                                  onChange={this.onChangeAbout}
                                  autoComplete="off">
                                  <option disabled>Select</option>
                                  <option>Support</option>
                                  <option>Sales</option>
                                </Form.Control>
                              </Form.Group>
                              {errors.type.length > 0 && (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  className="error">
                                  {errors.type}
                                </span>
                              )}

                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  name="name"
                                  value={value.name}
                                  onChange={this.onChangeAbout}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.name.length > 0 && (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  className="error">
                                  {errors.name}
                                </span>
                              )}

                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Phone number"
                                  value={value.mobileNo}
                                  name="mobileNo"
                                  onChange={this.onChangeAbout}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.mobileNo.length > 0 && (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  className="error">
                                  {errors.mobileNo}
                                </span>
                              )}

                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="text"
                                  name="email"
                                  placeholder="Email"
                                  value={value.email}
                                  onChange={this.onChangeAbout}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.email.length > 0 && (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  className="error">
                                  {errors.email}
                                </span>
                              )}

                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>

                                <textarea
                                  className="form-control"
                                  rows="2"
                                  id="comment"
                                  name="message"
                                  placeholder="Type a message"
                                  value={value.message}
                                  onChange={this.onChangeAbout}
                                  autoComplete="off"></textarea>
                              </Form.Group>
                              {errors.message.length > 0 && (
                                <span
                                  style={{ color: 'red', fontSize: '12px' }}
                                  className="error">
                                  {errors.message}
                                </span>
                              )}

                              <div>
                                <ReCAPTCHA
                                  className="g-recaptcha"
                                  // sitekey="6LeAFKkUAAAAAEVC_G0tNV7KgVX-gT2xFdUM0Dso"
                                  sitekey="6LeEKeAcAAAAALe_761sWVmJ4c4O6TX0uZmm4r20"
                                />
                              </div>
                            </form>
                            <div>
                              {/* <Button
                                className="contactusformbtn"
                                onClick={this.submit}>
                                Send Enquiry
                              </Button> */}
                              <Button
                                //  className="contactpagebtn"
                                // size="lg"
                                style={{
                                  width: '100%',
                                  marginTop: 10,
                                  backgroundColor: 'rgb(69, 177, 55)',
                                  borderColor: 'rgb(69, 177, 55)',
                                  height: 40
                                }}
                                onClick={this.submit}>
                                Send Enquiry
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col xs={12} sm={12} md={1} lg={1}></Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>

        <div className="teammain">
          <Container>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h1 className="appdownloadhead">
                  Download the Mobile App for free
                </h1>
                <p className="appdownloadpera">
                  We have separate mobile app for Riders and Drivers. You can
                  freely download the same on Playstore and AppStore.
                </p>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Image
                      className="appriderlogo"
                      src={require('../../assets/img/logo-rider.png')}
                    />
                    <div>
                      <a
                        onClick={() => window.scrollTo(0, 0)}
                        href="https://play.google.com/store/apps/details?id=com.frugal.rydzrider">
                        {' '}
                        <Image
                          className="appplaystoreImg"
                          src={require('../../assets/img/app1.png')}
                          alt="Third slide"
                        />
                      </a>

                    </div>
                    <div>
                      <a
                        onClick={() => window.scrollTo(0, 0)}
                        href="https://apps.apple.com/us/app/rydz-taxi/id1515691114">
                        <Image
                          className="appiosplaystoreImg"
                          src={require('../../assets/img/app.png')}
                          alt="Third slide"
                        />
                      </a>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Image
                      className="appdriverlogo"
                      src={require('../../assets/img/logo-driver.png')}
                    />
                    <div>
                      <a
                        onClick={() => window.scrollTo(0, 0)}
                        href="https://play.google.com/store/apps/details?id=com.frugal.rydzdriver">
                        <Image
                          className="appplaystoreImg"
                          src={require('../../assets/img/app1.png')}
                          alt="Third slide"
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        onClick={() => window.scrollTo(0, 0)}
                        href="https://apps.apple.com/us/app/rydz-driver-app/id1515691389">
                        <Image
                          className="appiosplaystoreImg"
                          src={require('../../assets/img/app.png')}
                          alt="Third slide"
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="contactcarou">
                  <Carousel>
                    <Carousel.Item>
                      <Image
                        className="d-block w-100"
                        src={require('../../assets/img/rydz9.jpeg')}
                      // alt="First slide"
                      />
                      {/* <Image
                        className="caontactcarousalimg"
                        src={require('../../assets/img/rydz9.jpeg')}
                      /> */}
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image
                        className="d-block w-100"
                        src={require('../../assets/img/rydz6.jpeg')}
                      // alt="First slide"
                      />
                      {/* <Image
                        className="caontactcarousalimg"
                        src={require('../../assets/img/rydz6.jpeg')}
                      /> */}

                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image
                        className="d-block w-100"
                        src={require('../../assets/img/rydz7.jpeg')}
                      // alt="First slide"
                      />
                      {/* <Image
                        className="caontactcarousalimg"
                        src={require('../../assets/img/rydz7.jpeg')}
                      /> */}

                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image
                        className="d-block w-100"
                        src={require('../../assets/img/rydz8.jpeg')}
                      // alt="First slide"
                      />
                      {/* <Image
                        className="caontactcarousalimg"
                        src={require('../../assets/img/rydz8.jpeg')}
                      /> */}
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image
                        className="d-block w-100"
                        src={require('../../assets/img/rydz5.jpeg')}
                      // alt="First slide"
                      />
                      {/* <Image
                        className="caontactcarousalimg"
                        src={require('../../assets/img/rydz5.jpeg')}
                      /> */}
                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="footer">
          <Footer />
        </div>
      </div>
    )
  }
}
export default Contact
