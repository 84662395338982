import {
    FETCH_ACTIVE_RQST_INIT_ACTION,
    FETCH_ACTIVE_RQST_FAIL_ACTION,
    FETCH_ACTIVE_RQST_SUCCESS_ACTION,
    FETCH_RQST_HISTORY_SUCCESS_ACTION,
    RQST_VIEWPCKG_SUCCESS_ACTION
} from './actions'


const initialState = {
    processing: false,
    activeRqst: [],
    rqstHistory: [],
    pkgDetail: null,
    rqstHistoryCount: 0
}

export default function (state: any = initialState, action: Function) {
    switch (action.type) {
        case FETCH_ACTIVE_RQST_INIT_ACTION:
            return {
                ...state,
                processing: true,

            }
        case FETCH_ACTIVE_RQST_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                error: false,
                activeRqst: action.payload.rows,
                activeRqstCount: action.payload.count
            }

        case FETCH_ACTIVE_RQST_FAIL_ACTION:
            return {
                ...state,
                processing: false,
                error: true,

            }
        case FETCH_RQST_HISTORY_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                error: true,
                rqstHistory: action.payload.rows,
                rqstHistoryCount: action.payload.count
            }
        case RQST_VIEWPCKG_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                error: true,
                pkgDetail: action.payload
            }
        default:
            return {
                ...state
            }
    }
}