import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import loginReducer1 from '../containers/LoginContainer/reducer'
import dashboardReducer from '../containers/DashboardContainer/reducer';
import profileReducer from '../containers/ProfileContainer/reducer';
import requestReducer from '../containers/RequestContainer/reducer';
import cabsReducer from '../containers/CabsContainer/reducer';
import autoReducer from '../containers/AutoContainer/reducer';
import homeReducer from '../containers/HomeContainer/reducer'
import forgotReducer from '../containers/ForgotPswdContainer/reducer'

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    loginReducer1,
    dashboardReducer,
    profileReducer,
    requestReducer,
    cabsReducer,
    autoReducer,
    homeReducer,
    forgotReducer
  });

export default createRootReducer;
