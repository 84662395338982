import * as React from 'react'

import Login from '../../screens/Login'
import { connect } from 'react-redux'

import { login } from './actions'


class LoginContainer extends React.Component<Props, State> {

    render() {
        // console.log("==========")
        return <Login login={this.props.login} />
    }
}

function bindAction(dispatch) {
    return {
        login: (value) => {
            dispatch(login(value))
        }
    }
}

const mapStateToProps = state => ({})

export default connect(
    mapStateToProps,
    bindAction
)(LoginContainer)
