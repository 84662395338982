import { put, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify'

import {
  REGISTER_INIT_ACTION,
  FETCH_REGISTER_FAIL_ACTION,
  FETCH_REGISTER_SUCCESS_ACTION
} from './actions'
import 'react-toastify/dist/ReactToastify.css'
import { appConfig } from '../../config'



function* register(cred) {
  const registerValue = {
    name: cred.cred.value.name,
    mobileNo: `+91${cred.cred.value.mobileNo}`,
    password: cred.cred.value.password,
    role: cred.cred.value.role
  }
  try {
    const res = yield fetch(`${appConfig.ip}/web/users/agent`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(registerValue)
    })

    if (!res.ok) {
      let errJSON = {}
      try {
        errJSON = yield res.json()
      } catch { }
      throw Object.assign(res, errJSON)
    } else {
      const resJSON = yield res.json()

      yield put({
        type: FETCH_REGISTER_SUCCESS_ACTION,
        payload: resJSON
      })

      localStorage.setItem('mobNo', resJSON.mobileNo)

      const signupToken = resJSON.token

      localStorage.setItem('userdata', resJSON.token)
      localStorage.setItem('userId', resJSON.user.id)
      const otpRes = yield fetch(`${appConfig.ip}/phoneVerify/sendotp`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${signupToken}`
        }
      })

      yield toast.success('Successfully registered', {
        autoClose: 3000
      })

      yield put(push('/registerOTP'))
    }
  } catch (err) {
    if (err.ok === false) {
      if (err.status === 409) {
        yield toast.error('Mobile number already registered', {
          autoClose: 3000
        })
      }
      yield put({
        type: FETCH_REGISTER_FAIL_ACTION,
        error: err
      })
    } else {
    }
  }
}

export function* signUp() {
  yield takeEvery(REGISTER_INIT_ACTION, register)
}
