import React from 'react'
import { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Navbar2 from '../../components/Navbar'
import './Faq.css'

class Faq extends Component {

  render() {
    return (
      <div>
        <Navbar2></Navbar2>

        <div className="faqContainer">
          <Container>
            <div className="faqmainhd">Rydz FAQ for riders</div>
            <br />
            <br />
            <p className="faqHead">1. WHAT IS RYDZ APP ABOUT?</p>
            <p className="pgraph">
              Rydz is a simple FREE One Click Immediate taxi and auto riksha
              ride booking app which connect riders to nearby individual taxi &
              auto rickshaw drivers. We offer customers immediate taxi
              availability and pick up in few minutes.
            </p>
            <br />
            <p className="faqHead">
              2. WHY SHOULD I USE RYDZ INSTEAD OF OTHER ONLINE TAXI BOOKING
              APPS?
            </p>
            <p className="pgraph">
              Rydz offers better availability & simplicity to use than any other
              existing players in the market. We are focusing to give you the
              best taxi availability when you are in need. Our One Click Ride
              booking is the simplest model you can find in the market now.
            </p>
            <br />
            <p className="faqHead">
              3. WHICH ARE THE AREAS IN WHICH RYDZ APP SERVICE IS AVAILABLE?
            </p>
            <p className="pgraph">
              As part of the beta launch, the app service is started in ‘Gods
              own country’ Kerala from June 12th, 2019 onwards. We will be
              launching Rydz in Mumbai & Goa by July 1st & other states by
              August15, 2019.
            </p>
            <br />
            <p className="faqHead">
              4. WHO IS YOUR IDEAL TYPE OF PASSANGERS /RIDERS?
            </p>
            <p className="pgraph">
              Any smart phone users who wants to immediately travel a short to
              medium distance of (2 kilometers to 15 kilometers) by taxi or auto
              ricksha and wanted a pick up from his home or office.
            </p>
            <br />
            <p className="faqHead">5. CAN I BOOK A RIDE FOR A LATER TIME?</p>
            <p className="pgraph">
              As of now, Rydz is available only for immediate booking (ride
              now). Booking a ride for later time feature will be available by
              August, 2019.
            </p>
            <br />
            <p className="faqHead">6. AS A PASSENGER, WILL RYDZ APP CHARGE ME?</p>
            <p className="pgraph">
              No, Rydz mobile app is free for passengers/riders.
            </p>
            <br />
            <p className="faqHead">7. WHAT WILL BE MY RIDE CHARGE?</p>
            <p className="pgraph">
              You pay same taxi/auto riksha charge at present in the market
              based on the meter based tariff for the segment of vehicle. No
              extra fee or discount for the passenger. Rydz only connect you
              with nearby driver.
            </p>
            <br />
            <p className="faqHead">8. HOW DO I PAY FOR MY TAXI RIDE?</p>
            <p className="pgraph">
              You pay your ride charge directly to the driver by cash or
              e-wallets or credit card. Rydz App don't handle your money.
            </p>
            <br />
            <p className="faqHead">
              9. WHAT DATA I NEED TO SUPPLY AS A RIDER FOR REGISTRATION?
            </p>
            <p className="pgraph">
              As part of registration, we ask your valid mobile number and name.
              That is it!
            </p>
            <br />
            <p className="faqHead">
              10. HOW DO I GET STARTED/SIGN UP/INSTALL THE RYDZ APP?
            </p>
            <div className="listpgraph">
              <p>It is a simple three step process</p>
              {/* <br />
              <br /> */}
              <p className="list">
                1. download the <u style={{ color: '#0000ff' }}>Rydz rider</u>{' '}
                mobile app from google Play Store
              </p>
              <p className="list">2. Register as a rider with your mobile number</p>
              <p className="list">3. Enable location access in your mobile</p>
            </div>
            <br />

            <p className="faqHead">
              11. HOW DO I BOOK AN AUTO RIKSHA OR A TAXI USING RYDZ?
            </p>
            <div className="listpgraph">
              <p> You have two ways to book ride. ie One Click or Press & Talk</p>
              {/* <br />
              <br /> */}
              <p className="list">
                1. <b>ONE CLICK:</b> Just click on either one of the “car” or
                “auto riksha” icon at the bottom of the home screen Or
              </p>
              <p className="list">
                2. <b>PRESS & TALK:</b> Just press the large mic icon at the
                middle of the home screen & say “Taxi”, “Hi Taxi”, “Gaadi”,
                “Car” or “Auto” or “Autoriksha”.
              </p>
              <p className="list">
                {' '}
                3. Wait for few minutes till a nearby driver accepts your ride
                request. That’s it!
              </p>
            </div>
            <br />
            <p className="faqHead">
              12. HOW MUCH TIME WILL IT TAKE FOR MY DRIVER TO COME FOR PICKUP?
            </p>
            <p className="pgraph">
              Realtime request for taxi or auto riksha will be matched by an
              accepting driver who is within the 1.5 km radius of your location.
              The actual ETA (estimated time of arrival) will be displayed in
              the app at the time of booking itself based on traffic estimate
              and distance. If there is no rider within your 1.5 km radius, you
              won’t have an option to book the ride. However, we are
              aggressively on boarding drivers of auto and taxis on Rydz.
            </p>
            <br />
            <p className="faqHead">13. WHAT IF I SEE NO DRIVER AVAILABLE?</p>
            <p className="pgraph">
              This means that we don’t have enough drivers in your area. Please
              inform us about your area by dropping mail at{' '}
              <u style={{ color: '#0000ff' }}> hello@rydz.in </u>or submit a
              “Report an Issue” action on the Rydz app side menu.
            </p>
            <br />
            <p className="faqHead"> 14. HOW DO I CANCEL MY BOOKING?</p>
            <p className="pgraph">
              The Rider can cancel the request anytime before any Driver
              accepting the request. To avoid losses to the drivers we request
              riders from refraining from cancelling the request after the
              driver started for pickup. Frequent or continuous cancellations of
              more than 3 times will result in temporarily suspension of your
              Rdyz account.
            </p>
            <br />
            <p className="faqHead"> 15. WHAT IF THE DRIVER DOESN’T TURN UP?</p>
            <p className="pgraph">
              We specifically instructed drivers to value customer's time. Our
              drivers will try ensure they reach the destination on time but
              still at times there are other external factors such as road
              traffic that are impossible to control or predict happens. Please
              do call the driveron the shared mobile number to confirm his
              arriving time. In case he doesn’t turn after repeated calls or is
              unreachable, kindly cancel the booking and re-book. Also please
              inform us about this incident by dropping a mail at{' '}
              <u style={{ color: '#0000ff' }}>hello@rydz.in</u> or submitting
              the issue through “Report an Issue” form in the Rydz app.
            </p>
            <br />
            <p className="faqHead">
              {' '}
              16. WHERE CAN I GIVE FEEDBACK REGARDING THE SERVICE?
            </p>
            <p className="pgraph">
              We would love to hear from you about your experience, good or bad,
              on using our system with us. You have the option of providing the
              rating and feedback after every ride. Regarding anything else just
              drop us a mail at{' '}
              <u style={{ color: '#0000ff' }}>hello@rydz.in</u> or submit your
              feedback through “report an issue” form that is part of side menu
              of the app
            </p>
            <br />
            <p className="faqHead">
              {' '}
              17. I HAVE AN ANDROID AND AN IOS MOBILE PHONES. DO BOTH PHONES
              SUPPORT RYDZ?
            </p>
            <p className="pgraph">
              As part of the initial launch we have released the popular
              platform Android. iOS package is getting onto the final shape in
              our engineering centre.
            </p>
            <br />
            <p className="faqHead">
              {' '}
              18. WHAT KIND OF VEHICLES ARE AVAILABLE ON RIDES PLATFORM?
            </p>
            <p className="pgraph">
              As of now we have two category of vehicles, Autorikshaws and Taxi
              Cars. Taxi car will comprise of different segments and an
              indicative ride cost will be available in tariff sheet
            </p>
            <br />
            <p className="faqHead">
              19. WHAT IF I HAVE A DISPUTE WITH DRIVER ON THE ROUTE AND RIDE
              COST?
            </p>
            <p className="pgraph">
              Though we specifically instruct drivers to make shortest and
              cheapest route, at times there are other factors that are
              impossible to control. If you have any complaint, drop us a mail
              at <u style={{ color: '#0000ff' }}> hello@rydz.in</u> or user
              report issue that is part of side menu of the app
            </p>
            <br />
            <p className="faqHead">
              20. I DON’T SEE A PLACE HOLDER TO ENTER DESTINATION IN THE RYDZ
              APP, HOW DO I ENSURE THE SHORTEST AND CHEAPEST ROUTE?
            </p>
            <p className="pgraph">
              Rydz is a simple virtual platform to enable our valued customer to
              find an auto rickshaw or taxi at every nook and corner of your
              locality. Since majority of our customers will be using the Rydz
              for local rides, we capitalize the drivers knowledge or third
              party apps (like google maps) to enable them. Our motto was to
              create ultra simple app that can be used by common man and find a
              driver near to you
            </p>
            <br />
            <p className="faqHead"> 21. HOW DRIVER CAN FIND MY LOCATION?</p>
            <p className="pgraph">
              With the help of our advanced designed application & support of
              Google map. Driver can reach your location with perfection
            </p>
            <br />
            <p className="faqHead"> 22. IS RYDZ SAFE?</p>
            <p className="pgraph">
              Rydz is a platform for enabling a virtual taxi stand at your
              fingertip. We as a platform provider, rely on applicable
              government machineries in ensuring the licensed vehicles to be
              ordered on the virtual stand similar to that of a taxi stand.
              However, we have simple verification process that we carryout in
              terms of identity of driver or rider as a user. All your rides or
              requests are available along with date and time if required to be
              inspected at a later point in time, along with geopoints of the
              rider and driver during the time of request.
            </p>
            <br />
            <p className="faqHead">
              {' '}
              23. IS REGISTRATION MANDATORY FOR RIDER AND DRIVERS?
            </p>
            <p className="pgraph">
              {' '}
              Yes, Its mandatory for the safety of the rider and driver.
            </p>
            <br />
            <p className="faqHead"> 24. IS THERE ANY SURCHARGE?</p>
            <p className="pgraph">
              No, we don’t charge anything extra or other than the standard per
              KM and per Min charges.
            </p>

            <div
              style={{
                paddingTop: '100px',
                color: 'black',
                fontSize: '52px',
                fontWeight: '600',
                textAlign: 'center'
              }}>
              Rydz FAQ for drivers
            </div>
            <br />
            <br />
            <p className="faqHead">1. WHAT IS RYDZ DRIVER APP ABOUT?</p>
            <p className="pgraph">
              Rydz Driver app is a simple Subscription based One Click Immediate
              taxi and auto riksha ride finding app for drivers which connect
              you to nearby riders.
            </p>
            <br />
            <p className="faqHead">
              2. WHY SHOULD I USE RYDZ DRIVER INSTEAD OF OTHER TAXI BOOKING
              APPS?
            </p>
            <p className="pgraph">
              Rydz Driver app is a simple Subscription based One Click Immediate
              taxi and auto riksha ride finding app for drivers which connect
              you to nearby riders. Ie You recharge your account for a nominal
              amount of 50Rs or more to be able to accept rider booking
              requests.
            </p>
            <br />
            <p className="faqHead">3. WHAT IS YOUR FEE/CHARGE?</p>
            <p className="pgraph">
              The Rydz Driver platform is completely free for you use till
              August 31st 2019. After August, if you happen to use Rydz for
              fulfilling a ride request, we will charge very{' '}
              <b>
                nominal charge Rs 20/ for auto and Rs 30 Taxi for that day you
                used the service.
              </b>{' '}
              This daily usage fee will be deducted from your recharged
              subscription amount like a prepaid mobile plan.
            </p>
            <br />
            <p className="faqHead">
              4. DO YOU CHARGE ME COMMISSION OR REVENUE SHARE OF THE RIDE?
            </p>
            <p className="pgraph">
              No commission or revenue sharing. If you don’t accept any request
              from our platform, there is no charge for the day. In short, Rs
              20/ for auto and Rs 30 Taxi for that day you have used the app
              service.
            </p>
            <br />
            <p className="faqHead">
              5. WILL YOU CHARGE FEE FOR ADDITIONAL RIDES IN A DAY?
            </p>
            <p className="pgraph">
              No, you can accept any number of ride requests in a day without
              paying any extra subscription fee. We charge you only for{' '}
              <b>your first ride in a day.</b>
            </p>
            <p className="faqHead">
              6. WILL YOU RETURN DAY FEE IF DRIVER OR RIDER CANCEL THE BOOKING?
            </p>
            <p className="pgraph">
              There is no cancellation fee however please talk to the rider
              before you start going to pickup the rider. Rydz will refill
              deducted day fee to your subscription balance account.
            </p>
            <br />
            <p className="faqHead">7. HOW DO I GET PAID FOR THE RIDE?</p>
            <p className="pgraph">
              Rider will pay you directly, Currently payment through cash only.
              We will deploy other payment channels such as Google Pay, Paytm,
              UPI etc soon.
            </p>
            <br />
            <p className="faqHead">
              8. WHAT DATA I NEED TO SUBMIT AS A DRIVER FOR REGISTRATION?
            </p>
            <p className="pgraph">
              As a driver holding, valid driver license, permit, you need to
              supply the vehicle information so that after requesting the ride,
              user can identify your vehicle in busy street, you license number
              and permit number. We assume your vehicle carries all relevant
              documents to run on the roads and go with ‘For Hire’ scenarios.
            </p>
            <br />
            <p className="faqHead">
              9. HOW TO I GET STARTED/SIGN UP/INSTALL RYDZ DRIVER?
            </p>
            <div className="listpgraph">
              <p> It is a simple three step process</p>
              {/* <br />
              <br /> */}
              <p className="list">
                {' '}
                1. download the <u style={{ color: '#0000ff' }}>
                  Rydz Driver
                </u>{' '}
                mobile app from Google Play Store
              </p>
              <p className="list">
                {' '}
                2. Register as a rider with your mobile number
              </p>
              <p className="list"> 3. Enable location access in your mobile</p>
            </div>
            <br />
            <p className="faqHead">
              10. HOW DO I ACCEPT AN AUTO RIKSHA OR A TAXI BOOKING USING RYDZ
              DRIVER?
            </p>
            <div className="listpgraph">
              <p>It is a simple three step process</p>
              {/* <br />
              <br /> */}
              <p className="list">
                {' '}
                1. Recharge your <u style={{ color: '#0000ff' }}>
                  Rydz Driver
                </u>{' '}
                account through BHIM or Google Pay or PayTM account (Not needed
                till Sept 1st, 2019)
              </p>
              <p className="list">
                {' '}
                2. Single Click on the search button for nearby taxi/auto
                requests.
              </p>
              <p className="list">
                {' '}
                3. Accept best ride request you would like to fulfill.
              </p>
            </div>
            <br />
            <p className="faqHead">
              11. WHAT SHOULD I DO AFTER ACCEPTING AN AUTO RIKSHA OR A TAXI
              BOOKING?
            </p>
            <div className="listpgraph">
              <p> It is a simple four steps process</p>
              {/* <br />
              <br /> */}
              <p className="list">
                {' '}
                1. Click on “Start Pickup” button to indicate you are starting
                to pick the rider from his booking location.
              </p>
              <p className="list">
                {' '}
                2. Click on the right arrow icon to show the Google Map route to
                the pickup location.
              </p>
              <p className="list">
                {' '}
                3. Click on the “Waiting” button to indicate you are arrived for
                pickup and waiting.
              </p>
              <p className="list">
                {' '}
                4. Click on “Start Trip” button when the ride start & click on
                “End Trip” button when the ride ends.
              </p>
            </div>
            <br />
            <p className="faqHead">
              12. UNABLE TO LOCATE THE RIDER AND NOT BEEN ABLE TO GET ON PHONE?
            </p>
            <p className="pgraph">
              At times there are other factors that are impossible to control,
              such as riders phone not reachable, runs out of power/battery, not
              on network etc. As a driver, your time is also valuable to us. We
              request you to cancel the request in such scenarios. The platform
              will intimate the rider accordingly.
            </p>
            <br />
            <p className="faqHead">
              13. I REACHED CUSTOMER LOCATION AND RIDER DOES NOT TURN UP?
            </p>
            <p className="pgraph">
              We minimize your loss by locating riders within 2 KM. There will
              be occasions when the rider does not turn up for the ride. We
              recommend the driver to have an initial call with rider even
              before starting pickup to the location of the rider.
            </p>
            <br />
            <p className="faqHead">
              14. YOU DON’T HAVE ENOUGH DRIVERS OR RIDERS ON YOUR PLATFORM
            </p>
            <p className="pgraph">
              We have aggressive plans to onboard drivers and riders on the
              platform. Soon you will discover we have the critical mass
            </p>
            <br />
            <p className="faqHead">
              15. WILL I GET MY SUBSCRIPTION FEE IF I DON’T USE IT OR DIDN’T GET
              ANY RIDE?
            </p>
            <p className="pgraph">
              No, the subscription fee is non-refundable. We have aggressive
              plans to onboard riders on the platform to make sure you get
              enough orders for a day.
            </p>
            <br />
            <p className="faqHead">
              16. WILL MY SUBSCRIPTION FEE EXPIRE IF I DON’T USE IT FULLY?
            </p>
            <p className="pgraph">No, the subscription fee never expire.</p>
            <br />
            <p className="faqHead">
              17. IS RYDZ DRIVER APP NEED EXPENSIVE INTERNET DATA PLAN?
            </p>
            <p className="pgraph">
              No, Rydz Driver app consume very little data. You can operate the
              app on your basic internet data plan in your smart phone
            </p>
            <br />
            <p className="faqHead">
              18. WHERE CAN I GIVE FEEDBACK REGARDING THE SERVICE?
            </p>
            <p className="pgraph">
              We would love to hear from you about your experience, good or bad,
              on using our system with us. You have the option of providing the
              rating and feedback after every ride. Regarding anything else just
              drop us a mail at{' '}
              <u style={{ color: '#0000ff' }}>support@rydz.in </u>or submit your
              feedback through “report an issue” form that is part of side menu
              of the app
            </p>
            <br />
            <p className="faqHead">
              19. WILL RYDZ DRIVER APP WORK IN BACKGROUND OR IN MINIMIZED MODE?
            </p>
            <p className="pgraph">
              Yes, Rydz will keep running search for new orders if it is in
              "Online" status and notify you with ring tone.
            </p>
            <br />
            <p className="faqHead">
              20. WHAT ARE ONLINE AND OFFLINE STATUS IN RYDZ DRIVER APP?
            </p>
            <p className="pgraph">
              The Rydz Driver will keep search for new orders in "Online"
              status. The app will not keep searching in "Offline" mode and you
              can manually search for orders in "Offline" status.
            </p>
            <br />
            <p className="faqHead">21. WHAT IS THE CUSTOMER PRICING SET BY RYDZ?</p>
            <p className="pgraph">
              Rydz doesn't control customer pricing. When a driver signup and
              mention the vehicle details, the Rydz platform set government rate
              depends on the number of seats of the vehicle. Drivers have the
              ability to edit this default government price depends on your
              vehicle model and features but not more than government set rate
            </p>
            <br />
            <p className="faqHead">
              22. HOW CAN I EDIT MY TAXI BASE PRICE, PER KILOMETER & WAITING
              CHARGES?{' '}
            </p>
            <p className="pgraph">
              Login into the Rydz Driver app and go to "Edit Rate" menu, edit
              rates and click on "Save" button.
            </p>
            <br />
            <br />
            <br />
            <br />
          </Container>
        </div>
      </div>
    )
  }
}
export default Faq
