import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as router from "react-router-dom";
import { Container } from "reactstrap";

import {
    AppAside,
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppBreadcrumb2 as AppBreadcrumb,
    AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";
import { withRouter } from "react-router-dom";
// sidebar nav config
import navigation from "../../_nav";

// routes config
import routes from "../../routes";

// const DefaultAside = React.lazy(() => import("./DefaultAside"));
const DefaultFooter = React.lazy(() => import("./DefaultFooter"));
const DefaultHeader = React.lazy(() => import("./DefaultHeader"));

class DefaultLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    loading = () => (
        <div className="animated fadeIn pt-1 text-center">Loading...</div>
    );

    signOut(e) {
        e.preventDefault();
        this.props.logout();
        this.props.history.push("/home");
    }

    render() {
        return (
            <div className="app">
                <AppHeader fixed>
                    <Suspense fallback={this.loading()}>
                        <DefaultHeader onLogout={(e) => this.signOut(e)} />
                    </Suspense>
                </AppHeader>
                <div className="app-body">
                    <AppSidebar fixed display="lg">
                        <AppSidebarHeader />
                        <AppSidebarForm />
                        <Suspense>
                            <AppSidebarNav
                                navConfig={navigation}
                                {...this.props}
                                router={router}
                            />
                        </Suspense>
                        <AppSidebarFooter />
                        <AppSidebarMinimizer />
                    </AppSidebar>
                    <main className="main">
                        <AppBreadcrumb appRoutes={routes} router={router} />
                        <Container fluid>
                            <Suspense fallback={this.loading()}>
                                <Switch>
                                    {routes.map((route, idx) => {
                                        return route.component ? (
                                            <Route
                                                key={idx}
                                                path={route.path}
                                                exact={route.exact}
                                                name={route.name}
                                                render={(props) =>
                                                    localStorage.getItem("userdata") !== null ? (
                                                        <route.component {...props} />
                                                    ) : (
                                                        <Redirect from="/" to="/home" />
                                                    )
                                                }
                                            />
                                        ) : null;
                                    })}

                                    {/* {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => <route.component {...props} />}
                      />
                    ) : null;
                  })} */}
                                    <Redirect from="/" to="/dashboard" />
                                </Switch>
                            </Suspense>
                        </Container>
                    </main>
                    <AppAside fixed>
                        <Suspense fallback={this.loading()}>
                            {/* <DefaultAside /> */}
                        </Suspense>
                    </AppAside>
                </div>
                <AppFooter style={{
                    backgroundColor: "#0d334a",
                    color: "white",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    // position: 'sticky'
                }}>
                    <Suspense fallback={this.loading()}>
                        <DefaultFooter />
                    </Suspense>
                </AppFooter>
            </div>
        );
    }
}

export default withRouter(DefaultLayout);
