import React from 'react'
import { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Navbar2 from '../../components/Navbar/index.js'
import './Terms.css'

class TermsCondition extends Component {


  render() {
    return (
      <div>
        <Navbar2></Navbar2>
        <div className="termsContainer">
          <Container>
            <div>
              <div className="termsMain">Terms and Conditions</div>
              <br />
              <br />
              <p>Last Modified: June 12, 2019</p>
              <p></p>
              <br />
              <p>
                These Terms & Conditions constitute a legal agreement
                <b className="subQuotes">(“Agreement”)</b> between _____________{' '}
                <b className="subQuotes">(“Rydz”)</b> and the User in connection
                with your visit to the Website and/or Mobile Application
                <b className="subQuotes">(“Platform”)</b>
                and your use of the services (as defined below).
              </p>
              <p></p>
              <br />
              <div className="termsHead">
                <b className="termsSubHead">1. THIS AGREEMENT APPLIES TO:</b>
                <p></p>
                <p>
                  <b className="subHead">1.1.</b> Individual{' '}
                  <b className="subQuotes">(“Driver”, “you” or “User”)</b>{' '}
                  registered through and having an Account on the Platform to
                  provide the transportation services independently to the
                  Riders and owns the taxi, car, auto rickshaw
                  <b className="subQuotes">(“Vehicle”)</b> and has the driving
                  License all the necessary and applicable transport vehicle
                  permits and licenses to provide transportation services;
                  and/or
                </p>
                <p></p>
                <p>
                  <b className="subHead">1.2.</b> Individual{' '}
                  <b className="subQuotes">
                    (“Passenger”, “Rider”, “Consumer”, “Customer”, “you” or
                    “User”)
                  </b>{' '}
                  registered through and having an Account on the Platform to
                  make transportation service request to the Driver; and/or
                </p>
                <p></p>
                <p>
                  <b className="subHead">1.3.</b> Otherwise a user of the
                  Website or Mobile App{' '}
                  <b className="subQuotes">(“you” or “User”)</b>.
                </p>
                <p></p>
                <br />
                <b className="termsSubHead">2. DEFINITIONS:</b>
                <p></p>
                <p>
                  <b className="subHead">2.1.</b> In this Agreement, unless the
                  context is inconsistent therewith, the following terms shall
                  have meanings assigned to them herein:
                </p>
                <p></p>
                <p>
                  <b className="subQuotes">“Account”</b> refers to the account
                  created by the User at its sole discretion on the Platform.
                </p>
                <p></p>
                <p>
                  <b className="subQuotes">“Driver”</b> shall mean an
                  individual, registered through and having an Account on the
                  Platform to provide the transportation services independently
                  to the Riders.
                </p>
                <p></p>
                <p>
                  <b className="subQuotes">“Driving License”</b> means the
                  license issued by the Licensing Authority for authorizing the
                  person specified therein to drive motor vehicle.
                </p>
                <p></p>
                <p>
                  <b className="subQuotes">“Fare”</b> shall mean the amount
                  displayed on the Meter regulated by the regulating authority
                  payable by the Rider to the Driver as at actuals.
                </p>
                <p></p>
                <p>
                  <b className="subQuotes">“Meter”</b> shall mean fare
                  calculating device on the Vehicle regulated by the regulating
                  authority.
                </p>
                <p></p>
                <p>
                  <b className="subQuotes">“Platform”</b> shall mean Rydz
                  Website and/or Mobile Application.
                </p>
                <p></p>
                <p>
                  <b className="subQuotes">“Rider”</b> shall mean an individual
                  registered through and having an Account on the Platform to
                  make transportation service request to the Driver.
                </p>
                <p></p>
                <p>
                  <b className="subQuotes">“Rydz”</b> shall mean _______
                </p>
                <p></p>
                <p>
                  <b className="subQuotes">“Rydz Service”</b> shall mean an 'ai'
                  based API driven technology Platform that enables the Rider to
                  request a transportation service with the Driver and enables
                  the Driver to accept such request and provide its
                  transportation service to the Rider, thereby connecting the
                  Rider and the Driver, morefully detailed in Clause 3 of this
                  Agreement.
                </p>
                <p></p>
                <p>
                  <b className="subQuotes">“Subscription”</b> shall mean
                  ________
                </p>
                <p></p>
                <p>
                  <b className="subQuotes">“Transportation Service”</b> shall
                  mean the conveyance requested by the Rider and provided by the
                  Driver.
                </p>
                <p></p>
                <p>
                  <b className="subQuotes">“User”</b> shall mean user of the
                  Website or Mobile App including Driver and Rider.
                </p>
                <p></p>
                <p>
                  <b className="subHead">2.2.</b> Any term not defined here
                  shall have the meaning assigned to it in this Agreement
                </p>
                <br />
                <b className="termsSubHead"> 3. RYDZ SERVICE:</b>
                <p></p>
                <p>
                  <b className="subHead">3.1.</b> The services provided by Rydz
                  solely constitute an 'ai' based API driven technology Platform
                  that enables the Rider to request a transportation service
                  with the Driver and enables the Driver to accept such request
                  and provide its transportation service to the Rider, thereby
                  connecting the Rider and the Driver.
                </p>
                <p></p>
                <p>
                  <b className="subHead">3.2.</b> Rydz is only an intermediary
                  providing online marketplace in the Platform where the Rider
                  may request a transportation service and the Driver may offer
                  its transportation service to such Rider.
                </p>
                <p></p>
                <p>
                  <b className="subHead">3.3.</b> The Service Provider and
                  Service Recipient relationship for availing the transportation
                  service shall be a contract solely between the Driver and the
                  Rider so connected. At no time shall Rydz have any obligations
                  or liabilities or be construed to be a party in respect of
                  such contract.
                </p>
                <p></p>
                <p>
                  <b className="subHead">3.4.</b> You acknowledge that Rydz does
                  not provide transportation services or function as a
                  transportation carrier and that all such transportation
                  services are provided by independent Driver who are not
                  employed by Rydz.
                </p>
                <p></p>
                <br />
                <b className="termsSubHead"> 4. RYDZ CONSIDERATION:</b>
                <p></p>
                <p>
                  <b className="subHead">4.1.</b> Rydz Service to Riders shall
                  be free of charge.
                </p>
                <p></p>
                <p>
                  <b className="subHead">4.2.</b> Rydz Service to the Driver
                  shall be on Subscription based model opted by the Driver.
                </p>
                <p></p>
                <br />
                <b className="termsSubHead">
                  {' '}
                  5. USER REPRESENTATION OR CONDITIONS OF USE:
                </b>
                <p></p>
                <p>
                  <b className="subHead">5.1.</b> By registering, visiting and
                  using the Platform or accepting this Agreement, you represent
                  and warrant to Rydz that you are 18 years of age or older, or
                  the age of legal majority in your jurisdiction (if different
                  than 18), and that you have the right, authority and capacity
                  to use the Platform and the services provided by Rydz and/or
                  services available through the Platform, and to agree and
                  abide by this Agreement.
                </p>
                <p></p>
                <p>
                  <b className="subHead">5.2.</b> The Driver should have and is
                  presumed to have a valid legal driving license and to have
                  ownership of the Vehicle and/or authorization from the owner
                  of such Vehicle to use the Vehicle and should not have
                  restrictions with respect to the use of the Vehicle the
                  Platform. The Vehicle should have and is presumed to have a
                  valid commercial license and all legal and regulatory rules
                  compliant insurances and permits as may be required under
                  Applicable Laws.
                </p>
                <p></p>
                <p>
                  <b className="subHead">5.3.</b> You acknowledge that Rydz does
                  not verify or interfere in the authenticity of driver’s
                  license of the permissions required for driving the vehicle on
                  a public road. The Vehicle, which has entered the Platform,
                  will be presumed to have all the licenses and requirements put
                  forth by the regulatory authority for the Vehicle.
                </p>
                <p></p>
                <br />
                <b className="termsSubHead"> 6. HIRE CHARGE:</b>
                <p></p>
                <p>
                  <b className="subHead">6.1.</b> The Driver will charge Rider
                  such Fare as per the amount displayed on the Meter regulated
                  by the regulating authority. Rydz shall not be responsible for
                  any additional fare charged or wrongly charged by the Driver.
                </p>
                <p></p>
                <br />
                <b className="termsSubHead">
                  {' '}
                  7. REVIEWS AND FEEDBACK DISPLAY RIGHTS:
                </b>
                <p></p>
                <p>
                  <b className="subHead">7.1.</b> Rydz reserves the right to
                  collect reviews and feedback from the Riders and at its
                  discretion to display the same on the Platform. Rydz shall
                  have no obligation to pre-screen, review, flag, filter,
                  modify, refuse or remove any or all reviews and feedback.
                </p>
                <p></p>
                <p>
                  <b className="subHead">7.2.</b> You understand that by using
                  the services you may be exposed to reviews and feedback or
                  other content that you may find offensive or objectionable.
                  Rydz shall not be liable for any effect on Driver’s business
                  due to reviews and feedback of a negative nature.
                </p>
                <p></p>
                <br />
                <b className="termsSubHead"> 8. NO LIABILITY:</b>
                <p></p>
                <p>
                  <b className="subHead">8.1.</b> In no event, shall Rydz, be
                  liable for any direct, indirect, special, incidental,
                  consequential, exemplary or punitive damages arising from, or
                  directly or indirectly related to, the use of, or the
                  inability to use, the Platform or the content and functions
                  related thereto, the Services, User’s provision of information
                  via the Platform, lost business or lost customers.
                </p>
                <p></p>
                <p>
                  <b className="subHead">8.2.</b> In no event shall the Rydz be
                  liable for:
                </p>
                <p>
                  {' '}
                  (i) provision of or failure to provide all or any service by
                  Drivers to Riders contacted or managed through the Platform;
                </p>
                <p>
                  (ii) any content posted, transmitted, exchanged or received
                  by or on behalf of any User or other person on or through
                  the Platform; or
                </p>
                <p>
                  (iii) loss of belongings, damage or damages, safety or
                  security in connection with service by Drivers to Riders
                </p>

                <p>
                  <b className="subHead">8.3.</b> In no event shall the total
                  aggregate liability of the Rydz to a User for all damages,
                  losses, and causes of action (whether in contract or tort)
                  arising from this Agreement or a User’s use of the Platform
                  exceed in the aggregate Rs. 1000/- (Rupees One Thousand Only).
                </p>
                <p></p>
                <br />
                <b className="termsSubHead"> 9. DISCLAIMER:</b>
                <p></p>
                <p>
                  You expressly acknowledge and agree that use of the Platform
                  and the service is entirely at your own risk and that the
                  Platform and the service are provided on an "as is" or "as
                  available" basis, without any warranties of any kind. All
                  express and implied warranties, including, without limitation,
                  the warranties of fitness of the Vehicle, credibility of the
                  Driver are expressly disclaimed to the fullest extent
                  permitted by law. To the fullest extent permitted by law Rydz,
                  its officers, directors, employees, and agents disclaim all
                  warranties, express or implied, in connection with the
                  Platform and your use thereof. Rydz makes no warranties or
                  representations about the accuracy of the Meter and assumes no
                  liability or responsibility for any (i) errors, mistakes, or
                  inaccuracies, (ii) personal injury or property damage, of any
                  nature whatsoever, resulting from your access to and use of
                  the platform and service, (iii) any unauthorized access to or
                  use of our servers and/or any and all personal information
                  and/or financial information stored therein, if any (iv) any
                  interruption or cessation of transmission to or from the
                  Platform, (iv) any bugs, viruses, trojan horses, or the like
                  which may be transmitted to or through the Platform by any
                  third party, and/or (v) any errors or omissions in any content
                  or for any loss or damage of any kind incurred as a result of
                  the use of any content posted, emailed, communicated,
                  transmitted, or otherwise made available via the platform or
                  the service. Rydz does not warrant, endorse, guarantee, or
                  assume responsibility for any service offered by the Driver
                  through the Platform, and Rydz will not be a party to or in
                  any way be responsible for monitoring any transaction between
                  you and/or other users. As with availing service through any
                  medium or in any environment, you should use your best
                  judgment and exercise caution where necessary.
                </p>
                <p></p>
                <br />
                <b className="termsSubHead"> 10. INDEMNITY:</b>
                <p></p>
                <p className="subStyle">
                  You agree to defend, indemnify and hold harmless Rydz, its
                  officers, subsidiaries, affiliates, successors, assigns,
                  directors, officers, agents and employees, from and against
                  any and all claims, damages, obligations, losses, liabilities,
                  costs or debt, and expenses (including but not limited to
                  attorneys' fees) arising from: (i) your use of and access to
                  the Platform and/or the Service; (ii) your violation of any
                  term of these Terms; (iii) your violation of any third party
                  right; or (iv) any claim that your Service or lack thereof
                  caused damage to a third party. This defense and
                  indemnification obligation will survive termination,
                  modification or expiration of these Agreement and your use of
                  the service and the Platform.
                </p>
                <p></p>
                <br />
                <b className="termsSubHead"> 11. NO PARTNERSHIP:</b>
                <p></p>
                <p>
                  Nothing contained in this Agreement shall constitute or be
                  deemed to constitute a partnership between Rydz and You. You
                  shall hold yourself out as an agent for Rydz, except with the
                  express prior written consent of Rydz. Your rights, duties,
                  obligations and liabilities shall be individual and not joint
                  or collective.
                </p>
                <p></p>
                <br></br>
                <b className="termsSubHead">12. TERMINATION:</b>
                <p></p>
                <p>
                  <b className="subHead">12.1.</b> Rydz reserves the right to
                  suspend or terminate a User’s access to the Website and the
                  Services with or without notice and to exercise any other
                  remedy available under law, in cases where,
                </p>
                <p className="subStyle">
                  {' '}
                  (i) Such User breaches any terms and conditions of the
                  Agreement;
                </p>
                <p className="subStyle">
                  {' '}
                  (ii) A third party reports violation of any of its right as
                  a result of your use of the Services;
                </p>
                <p className="subStyle">
                  {' '}
                  (iii) Rydz is unable to verify or authenticate any
                  information provide to Rydz by a User;
                </p>
                <p className="subStyle">
                  {' '}
                  (iv) Rydz has reasonable grounds for suspecting any illegal,
                  fraudulent or abusive activity on part of such User; or
                </p>
                <p className="subStyle">
                  {' '}
                  (v) Rydz believes in its sole discretion that User’s actions
                  may cause legal liability for such User, other Users or for
                  Rydz or are contrary to the interests of the Website.
                </p>

                <p></p>
                <br />
                <b className="termsSubHead">
                  13. GOVERNING LAW, ARBITRATION AND DISPUTE RESOLUTION:
                </b>
                <p></p>
                <p>
                  <b className="subHead">13.1.</b>{' '}
                  <u style={{ fontWeight: 600 }}> Governing Law:</u> This
                  Agreement and all acts and transactions pursuant hereto and
                  the rights and obligations of Parties shall be governed,
                  construed and interpreted in accordance with the laws of
                  India.
                </p>
                <p></p>
                <p>
                  <b className="subHead">13.2.</b>{' '}
                  <u style={{ fontWeight: 600 }}>Dispute Resolution:</u> If at
                  any time any issues or uncertainties arise, the Parties shall
                  negotiate in good faith and agree to appropriate solutions so
                  as to preserve the economic value / benefit hereunder of each
                  Party. If the Parties are unable to reach a solution on any
                  dispute or difference arising between the Parties hereto as to
                  the effect, interpretation or application of this Agreement or
                  as to their rights, duties or liabilities thereunder, or as to
                  any act, matter or thing arising out of, or consequent to, or
                  in connection with this Agreement, the dispute or difference
                  as the case maybe shall be referred to and finally resolved by
                  arbitration by a sole arbitrator to be appointed by Rydz. The
                  arbitration proceedings shall be in accordance with the
                  provisions of the Arbitration and Conciliation Act, 2013, or
                  any subsequent enactment or amendment thereof. Such
                  arbitration proceedings shall be held in Mumbai, India, and
                  shall be conducted in the English Language.
                </p>
                <p></p>
                <p>
                  <b className="subHead">13.3.</b> Subject to clause 13.2
                  (Dispute Resolution), any dispute or controversy arising out
                  of this Agreement shall, be subject to exclusive jurisdiction
                  of the Courts of Mumbai, India.{' '}
                </p>
                <p></p>
                <br />
                <b className="termsSubHead">14. FORCE MAJEURE:</b>
                <p></p>
                <p>
                  No Party shall not be liable for failure to perform or delay
                  in performing any obligation under this Agreement if such
                  failure or delay is due to fire, flood, earthquake, strike,
                  labor trouble or other industrial disturbance, war, embargo,
                  blockage, legal prohibition, governmental action, riot,
                  insurrection, or any other cause beyond the control of such
                  defaulting Party preventing or delaying the performance (each
                  a<b className="forceText"> “Force Majeure Event”</b>).{' '}
                </p>
                <p></p>
                <br />
                <b className="termsSubHead">15. SEVERABILITY:</b>
                <p></p>
                <p>
                  If any provision of this Agreement is invalid, unenforceable
                  or prohibited by law, or become ineffective because of
                  applicable provisions of law or regulations of governmental
                  authorities, this Agreement shall be considered divisible as
                  to such provision and such provision shall be inoperative and
                  the remainder of this Agreement shall be valid, binding and of
                  like effect as though such provision was not included herein.{' '}
                </p>
                <p></p>
                <br />
                <p className="subHead">
                  THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND
                  DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES. BY
                  CLICKING ON THE “I ACCEPT” BUTTON ON THIS ELECTRONIC CONTRACT,
                  YOU ARE CONSENTING TO BE BOUND BY THIS AGREEMENT.
                </p>
                <p></p>
                <br></br>
              </div>
            </div>
          </Container>
        </div>
      </div>
    )
  }
}
export default TermsCondition
