import React, { Component } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

import Navbar2 from "../../components/Navbar";
import Footer from "../../components/Footer";

import "./style.css";
import { styles } from "./style.js";

const validEmailRegex = RegExp(
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};
const validphoneRegex = RegExp(/^\d{7}$/);
// const validateForms = errors => {
//     let valid = true
//     Object.values(errors).forEach(val => val.length > 0 && (valid = false))
//     return valid
// }

class SendEnquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // showError: false,
      // eslint-disable-next-line react/no-unused-state
      value: {
        name: "",
        mobileNo: "",
        email: "",
        cmpnyName: "",
        message: "",
        cntry: "",
      },
      // eslint-disable-next-line react/no-unused-state
      errors: {
        name: "",
        mobileNo: "",
        email: "",
        message: "",
        cmpnyName: "",
        cntry: "",
      },
    };
    this.onChangeAbout = this.onChangeAbout.bind(this);
    this.submit = this.submit.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  submit(event) {
    event.preventDefault();
    // console.log("====ttttt=====", this.state.value, this.props);
    if (
      validateForm(this.state.errors) &&
      this.state.value.name !== "" &&
      this.state.value.mobileNo !== "" &&
      this.state.value.email !== "" &&
      this.state.value.message !== "" &&
      this.state.value.cmpnyName !== "" &&
      this.state.value.cntry !== ""
    ) {
      const data = this.state.value;
      this.props.sendRequest(data);
      this.setState({
        value: {
          name: "",
          mobileNo: "",
          email: "",
          message: "",
          cmpnyName: "",
          cntry: "",
        },
      });
      // this.setState({ name: undefined })
      // this.setState({ mobileNo: undefined })
      // this.setState({ email: undefined })
      // this.setState({ message: undefined })
    } else {
      toast.error("Please enter valid datas", {
        autoClose: 2000,
      });
    }
  }

  onChangeAbout(values) {
    const { value, name } = values.target;
    this.setState({
      value: { ...this.state.value, [name]: value },
    });
    let errors = this.state.errors;

    switch (name) {
      case "name":
        errors.name = value.length < 3 ? "Name must be 3 characters long" : "";
        break;
      case "mobileNo":
        errors.mobileNo = validphoneRegex.test(value)
          ? ""
          : "Mobile number is not valid";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid";
        break;
      case "message":
        errors.message = value.length < 3 ? "Message is required" : "";
        break;
      case "cntry":
        errors.cntry = value.length < 3 ? "Country is required!" : "";
        break;
      case "cmpnyName":
        errors.cntry = value.length < 3 ? "Company Name is required!" : "";
        break;
      default:
        break;
    }
  }

  render() {
    const { errors } = this.state;
    return (
      // <div className="aboutpagefullres" style={styles.abutpag}>
      //     <Navbar2 />
      //     <div class="container" >
      //         <div class="row">
      //             <div class="col-xl-4 col-lg-4 col-sm-1 colmn"></div>
      //             <div class="col-xl-4 col-lg-4 col-sm-10 colmn"  >
      //                 <form>

      //                     <Form.Group>
      //                         <Form.Label className="contactenquiry"></Form.Label>
      //                         <Form.Control
      //                             type="text"
      //                             placeholder="Name"
      //                             name="name"
      //                             value={this.state.value.name}
      //                             onChange={this.onChangeAbout}
      //                             autoComplete="off"
      //                         />
      //                     </Form.Group>
      //                     {errors.name.length > 0 && (
      //                         <span
      //                             style={{ color: 'red', fontSize: '12px' }}
      //                             className="error">
      //                             {errors.name}
      //                         </span>
      //                     )}

      //                     <Form.Group>
      //                         <Form.Label className="contactenquiry"></Form.Label>
      //                         <Form.Control
      //                             type="text"
      //                             placeholder="Email"
      //                             name="email"
      //                             value={this.state.value.email}
      //                             onChange={this.onChangeAbout}
      //                             autoComplete="off"
      //                         />
      //                     </Form.Group>
      //                     {errors.email.length > 0 && (
      //                         <span
      //                             style={{ color: 'red', fontSize: '12px' }}
      //                             className="error">
      //                             {errors.email}
      //                         </span>
      //                     )}

      //                     <Form.Group>
      //                         <Form.Label className="contactenquiry"></Form.Label>
      //                         <Form.Control
      //                             type="text"
      //                             placeholder="Country"
      //                             name="cntry"
      //                             value={this.state.value.cntry}
      //                             onChange={this.onChangeAbout}
      //                             autoComplete="off"
      //                         />
      //                     </Form.Group>
      //                     {errors.cntry.length > 0 && (
      //                         <span
      //                             style={{ color: 'red', fontSize: '12px' }}
      //                             className="error">
      //                             {errors.cntry}
      //                         </span>
      //                     )}
      //                     <Form.Group>
      //                         <Form.Label className="contactenquiry"></Form.Label>
      //                         <Form.Control
      //                             type="number"
      //                             placeholder="Mobile Number"
      //                             name="mobileNo"
      //                             value={this.state.value.mobileNo}
      //                             onChange={this.onChangeAbout}
      //                             autoComplete="off"
      //                         />
      //                     </Form.Group>
      //                     {errors.mobileNo.length > 0 && (
      //                         <span
      //                             style={{ color: 'red', fontSize: '12px' }}
      //                             className="error">
      //                             {errors.mobileNo}
      //                         </span>
      //                     )}

      //                     <Form.Group>
      //                         <Form.Label className="contactenquiry"></Form.Label>
      //                         <Form.Control
      //                             type="text"
      //                             placeholder="Company Name"
      //                             name="cmpnyName"
      //                             value={this.state.value.cmpnyName}
      //                             onChange={this.onChangeAbout}
      //                             autoComplete="off"
      //                         />
      //                     </Form.Group>
      //                     {errors.cmpnyName.length > 0 && (
      //                         <span
      //                             style={{ color: 'red', fontSize: '12px' }}
      //                             className="error">
      //                             {errors.cmpnyName}
      //                         </span>
      //                     )}

      //                     <Form.Group>
      //                         <Form.Label className="contactenquiry"></Form.Label>

      //                         <textarea
      //                             className="form-control"
      //                             rows="2"
      //                             id="comment"
      //                             name="message"
      //                             placeholder="Type a message"
      //                             value={this.state.value.message}
      //                             onChange={this.onChangeAbout}
      //                             autoComplete="off"></textarea>
      //                     </Form.Group>
      //                     {errors.message.length > 0 && (
      //                         <span
      //                             style={{ color: 'red', fontSize: '12px' }}
      //                             className="error">
      //                             {errors.message}
      //                         </span>
      //                     )}

      //                 </form>
      //                 <div>

      //                     <Button
      //                         className="contactpagebtn"
      //                         size="lg"
      //                         onClick={this.submit}>
      //                         Send
      //                     </Button>
      //                 </div>
      //             </div>
      //             <div class="col-xl-4 col-lg-4 col-sm-1 colmn"></div>
      //         </div>
      //     </div>
      //     <div className="footer">
      //         <Footer />
      //     </div>
      // </div>

      <div className="enqurypagefullres">
        <Navbar2 />
        <div className="aboutmain" style={styles.abutpag}>
          <div className="submain">
            <Container>
              <div className="subdiv">
                <Row>
                  <Col xs={12} sm={12} md={3} lg={3}></Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Row>
                      <Col xs={12} sm={12} md={2} lg={2}></Col>

                      <Col
                        xs={12}
                        sm={9}
                        md={12}
                        lg={8}
                        className="contactusform"
                        style={styles.nav}
                      >
                        <div>
                          <h1 className="contactusformhead">Send Enquiry</h1>
                          <div className="contactusformlabel">
                            <form>
                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  name="name"
                                  value={this.state.value.name}
                                  onChange={this.onChangeAbout}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.name.length > 0 && (
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                  className="error"
                                >
                                  {errors.name}
                                </span>
                              )}

                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Email"
                                  name="email"
                                  value={this.state.value.email}
                                  onChange={this.onChangeAbout}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.email.length > 0 && (
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                  className="error"
                                >
                                  {errors.email}
                                </span>
                              )}

                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Country"
                                  name="cntry"
                                  value={this.state.value.cntry}
                                  onChange={this.onChangeAbout}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.cntry.length > 0 && (
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                  className="error"
                                >
                                  {errors.cntry}
                                </span>
                              )}
                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="Mobile Number"
                                  name="mobileNo"
                                  value={this.state.value.mobileNo}
                                  onChange={this.onChangeAbout}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.mobileNo.length > 0 && (
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                  className="error"
                                >
                                  {errors.mobileNo}
                                </span>
                              )}

                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Company Name"
                                  name="cmpnyName"
                                  value={this.state.value.cmpnyName}
                                  onChange={this.onChangeAbout}
                                  autoComplete="off"
                                />
                              </Form.Group>
                              {errors.cmpnyName.length > 0 && (
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                  className="error"
                                >
                                  {errors.cmpnyName}
                                </span>
                              )}

                              <Form.Group>
                                <Form.Label className="contactenquiry"></Form.Label>

                                <textarea
                                  className="form-control"
                                  rows="2"
                                  id="comment"
                                  name="message"
                                  placeholder="Type a message"
                                  value={this.state.value.message}
                                  onChange={this.onChangeAbout}
                                  autoComplete="off"
                                ></textarea>
                              </Form.Group>
                              {errors.message.length > 0 && (
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                  className="error"
                                >
                                  {errors.message}
                                </span>
                              )}
                            </form>
                            <div>
                              <Button
                                //  className="contactpagebtn"
                                size="lg"
                                style={{
                                  width: '100%',
                                  marginTop: 10,
                                  backgroundColor: 'rgb(69, 177, 55)',
                                  borderColor: 'rgb(69, 177, 55)',
                                  height: 40
                                }}
                                onClick={this.submit}
                              >
                                Send Enquiry
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col xs={12} sm={12} md={3} lg={3}></Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>

        <div className="footer">
          <Footer />
        </div>
      </div>
    );
  }
}
export default SendEnquiry;
