import {
    USER_CHECK_INIT_ACTION,
    USER_CHECK_SUCCESS_ACTION,
    USER_CHECK_FAIL_ACTION,
    BOOKING_LOGIN_ACTION,
    BOOKING_LOGIN_FAIL_ACTION,
    BOOKING_LOGIN_SUCCESS_ACTION,
    CHANGE_SCREEN_ACTION,
    UPDATE_PROFILE_SUCCESS_ACTION,
    FETCH_SPECIAL_PACKAGE_SUCCESS_ACTION,
    FETCH_HOURLY_PACKAGE_SUCCESS_ACTION,
    PACKAGE_NEARBY_DRIVER_SUCCESS_ACTION,
    PACKAGE_NEARBY_DRIVER_ACTION,
    BOOK_LOGOUT_ACTION
} from './action'


const initialState = {
    processing: false,
    screen: 'signup'
}

export default function (state: any = initialState, action: Function) {
    switch (action.type) {
        case '':
            return {
                ...state,
                processing: true,
            }
        case USER_CHECK_INIT_ACTION:
            return {
                ...state,
                processing: true,
                userdetail: null
            }
        case USER_CHECK_SUCCESS_ACTION:
            return {
                ...state,
                processing: false,
                error: false,
                userExist: action.payload,
                screen: 'otp'
            }
        case USER_CHECK_FAIL_ACTION:
            return {
                ...state,
                processing: false,
                error: true
            }
        case BOOKING_LOGIN_ACTION: {
            return {
                ...state,
                processing: true,
                error: false
            }
        }
        case BOOKING_LOGIN_FAIL_ACTION: {
            return {
                ...state,
                processing: false,
                error: true
            }
        }
        case BOOKING_LOGIN_SUCCESS_ACTION: {
            return {
                ...state,
                processing: false,
                error: false,
                screen: action.payload.user.ftLogin ? 'profile' : 'selectVtype',
                // screen: "selectVtype",
                cred: action.payload
            }
        }
        case CHANGE_SCREEN_ACTION: {
            return {
                ...state,
                screen: action.payload
            }
        }
        case UPDATE_PROFILE_SUCCESS_ACTION: {
            return {
                ...state,
                screen: 'selectVtype'
            }
        }
        case FETCH_SPECIAL_PACKAGE_SUCCESS_ACTION:
            return {
                ...state,
                specailPkgList: action.payload
            }
        case FETCH_HOURLY_PACKAGE_SUCCESS_ACTION:
            return {
                ...state,
                hourlyPkgList: action.payload
            }
        case PACKAGE_NEARBY_DRIVER_SUCCESS_ACTION:
            return {
                ...state,
                pkgDriverList: action.payload
            }
        case PACKAGE_NEARBY_DRIVER_ACTION: {

            return {
                ...state,
                pckgPickup: action.payload
            }
        }
        case BOOK_LOGOUT_ACTION: {
            return {
                ...state,
                cred: null,
                screen: 'signup'
            }
        }
        // case FETCH_DASHBOARD_SUCCESS_ACTION:
        //     return {
        //         ...state,
        //         processing: false,
        //         error: false,
        //         dashboardList: action.payload,
        //     }

        // case FETCH_DASHBOARD_FAIL_ACTION:
        //     return {
        //         ...state,
        //         processing: false,
        //         error: true,

        //     }
        default:
            return {
                ...state
            }
    }
}