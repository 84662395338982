import React, { Component } from 'react'
import { Container, Image, Nav, Navbar, Button } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'

import './Navbar.css'
import { styles } from './style.js'

class Navbar2 extends Component {
  render() {
    return (
      <div className="homenavbarfirst">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="navbar "
          style={styles.nav}>
          <Container>
            <div className="logoNav">
              <Link to="/home" onClick={() => window.scrollTo(0, 0)}>
                <Image
                  className="homeimage"
                  src={require('../../assets/img/rydz logo new one.png')}
                />
              </Link>
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto "></Nav>

              <Nav>
                <NavLink
                  exact
                  activeClassName="navbar__link--active"
                  className=" mainpagenavbar"
                  to="/home"
                  onClick={() => window.scrollTo(0, 0)}>
                  Home
                </NavLink>
                <li className="homenavbar">
                  <a className="homepagenavbar" href="home#howitworks">
                    {' '}
                    How it works
                  </a>
                </li>
                <li className="homenavbar">
                  <a className="homepagenavbar" href="home#driver">
                    {' '}
                    For drivers
                  </a>
                </li>
                {/* ******************************************************************** */}

                <NavLink
                  activeClassName="navbar__link--active"
                  className="mainpagenavbar"
                  to="/partnerservice"
                  onClick={() => window.scrollTo(0, 0)}>
                  Partner with us
                </NavLink>

                <NavLink
                  activeClassName="navbar__link--active"
                  className="mainpagenavbar"
                  to="/contact"
                  onClick={() => window.scrollTo(0, 0)}>
                  Contact us
                </NavLink>
                <NavLink to="/login" onClick={() => window.scrollTo(0, 0)}>
                  {' '}
                  <Button
                    type="submit"
                    className="navbtn"
                    style={styles.navpgBtn}>
                    Login
                  </Button>
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    )
  }
}

export default Navbar2
